import { zodResolver } from "@hookform/resolvers/zod"
import { useForm } from "react-hook-form"
import * as z from "zod"
import { Button } from "../../components/button"
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../../components/form"
import { Input } from "../../components/input"
import FormTitle from "../../Core/components/form-title";
import {DialogClose, DialogFooter} from "../../components/dialog";
import React, {useEffect, useRef, useState} from "react";
import {
    EmployeeCreationProps,
    employeesUrl,
    EmploymentType,
    federalState,
    Role
} from "../Controllers/employee-controller";
import {DatePicker} from "../../components/date-picker";
import {Switch} from "../../components/switch";
import formatDate from "../../Core/functions/format-date";
import DetailViewCard from "../../Core/components/detail-view-card";
import {TableCell, TableRow} from "../../components/table";
import {MagicWand} from "@phosphor-icons/react";
import {useAxiosInstance} from "../../Core/utilities/AxiosInstance";
import {toast} from "../../components/use-toast";
import {TimePicker} from "../../components/date-time-picker/time-picker";
import {TimeValue} from "react-aria";
import {nullOrUndefined} from "../../Core/constants/variables";
import {FormattedMessage, useIntl} from "react-intl";
import {ServerErrorsType, useServerErrors} from "../../Core/functions/use-server-errors";
import ErrorMessageList from "../../Core/components/ErrorMessageList";
import SearchInput from "../../Core/components/search-input";
import moment from "moment";
import {useWorkingPlan} from "../../Core/WorkingPlan/use-working-plan";
import {WorkingPlanBody} from "../../Core/WorkingPlan/components";
import {TWorkingPlanBreak} from "../../Core/WorkingPlan/interfaces";
import {employeeManagementUrl} from "../index";


function formatTimeValue (time?: TimeValue | null) {
    if (time === undefined || time === null) return;

    const updatedHour = (time.hour.toString().length === 1) ? `0${time.hour}` : time.hour;
    const updatedMinute = (time.minute.toString().length === 1) ? `0${time.minute}` : time.minute;
    return `${updatedHour}:${updatedMinute}`
}

const formSchema = z.object({
    firstName: z.any(),
    lastName: z.any(),
    email: z.any(),
    phoneNumber: z.any(),
    roleID: z.any(),
    employmentTypeID: z.any(),
    entranceDate: z.any(),
    stateCode: z.any(),
    vacationDays: z.any(),
    hasDifferentExpiryDate: z.any(),
    expiryDate: z.any(),
    hasCarryover: z.any(),
    manualCarryoverDays: z.any(),
    nfcUUID: z.any(),
    isActive: z.any(),
    password: z.any(),
    confirmPassword: z.any(),
    isTestUser: z.any(),
    workingTimePlanData: z.any(),
    personalNumber: z.any(),
    agendaData: z.any(),
})

type FormEmployeeCreateProps = {
    reFetch?: any
    defaultValues?: {
        firstName?: string
        lastName?: string
        entranceDate?: string | Date
        personalNumber?: string
        agendaData?: string
        phoneNumber?: string
        email?: string
        state?: string
        stateCode?: string
    }
    sideFunctions?: (() => void)[]
}

type WorkingPlanSchemaType = {
    pk?: string
    day: number
    dayName: string
    startTime: TimeValue | null
    endTime: TimeValue | null
    checked: boolean
    breaktimes: TWorkingPlanBreak[]
    breaktimeHelper: TWorkingPlanBreak
}

type WorkingPlanDurationSchemaType = {
    day: number
    duration: moment.Duration
    formattedDuration: string
}

const WorkingPlanSchema: WorkingPlanSchemaType[] = [
    {
        day: 1,
        dayName: "Monday",
        startTime: null,
        endTime: null,
        checked: false,
        breaktimes: [],
        breaktimeHelper: {}
    },
    {
        day: 2,
        dayName: "Tuesday",
        startTime: null,
        endTime: null,
        checked: false,
        breaktimes: [],
        breaktimeHelper: {}
    },
    {
        day: 3,
        dayName: "Wednesday",
        startTime: null,
        endTime: null,
        checked: false,
        breaktimes: [],
        breaktimeHelper: {}
    },
    {
        day: 4,
        dayName: "Thursday",
        startTime: null,
        endTime: null,
        checked: false,
        breaktimes: [],
        breaktimeHelper: {}
    },
    {
        day: 5,
        dayName: "Friday",
        startTime: null,
        endTime: null,
        checked: false,
        breaktimes: [],
        breaktimeHelper: {}
    },
    {
        day: 6,
        dayName: "Saturday",
        startTime: null,
        endTime: null,
        checked: false,
        breaktimes: [],
        breaktimeHelper: {}
    },
    {
        day: 7,
        dayName: "Sunday",
        startTime: null,
        endTime: null,
        checked: false,
        breaktimes: [],
        breaktimeHelper: {}
    }
]

const WorkingPlanDurations: WorkingPlanDurationSchemaType[] = [
    {
        day: 1,
        duration: moment.duration(0),
        formattedDuration: "0"
    },
    {
        day: 2,
        duration: moment.duration(0),
        formattedDuration: "0"
    },
    {
        day: 3,
        duration: moment.duration(0),
        formattedDuration: "0"
    },
    {
        day: 4,
        duration: moment.duration(0),
        formattedDuration: "0"
    },
    {
        day: 5,
        duration: moment.duration(0),
        formattedDuration: "0"
    },
    {
        day: 6,
        duration: moment.duration(0),
        formattedDuration: "0"
    },
    {
        day: 7,
        duration: moment.duration(0),
        formattedDuration: "0"
    }
]

export const FormEmployeeCreate: React.FC<FormEmployeeCreateProps>  = ({
    reFetch,
    defaultValues,
    sideFunctions
}) => {
    const intl = useIntl();
    const axiosInstance = useAxiosInstance();
    const form = useForm<z.infer<typeof formSchema>>({
        resolver: zodResolver(formSchema),
        defaultValues: {
            isActive: true,
            isTestUser: false,
            workingTimePlanData: (() => {
                return WorkingPlanSchema.map((day) => {
                    return {
                        pk: null,
                        day: day.day,
                        startTime: null,
                        endTime: null,
                        checked: false
                    }
                })
            })(),
            hasDifferentExpiryDate: false,
            hasCarryover: false,
            ...defaultValues
        },
    });
    const [isValidated, setValidation] = useState(false);
    const [startingDate, setStartingDate] = useState<Date | undefined>()
    const [workingPlan, setWorkingPlan] = useState<WorkingPlanSchemaType[]>(WorkingPlanSchema)
    const [workingPlanDurations, setWorkingPlanDurations] = useState<WorkingPlanDurationSchemaType[]>(WorkingPlanDurations);
    const [totalDurations, setTotalDurations] = useState<moment.Duration | null>(moment.duration(0));
    const closeModal = useRef<HTMLElement>();
    const [apiErrors, setApiErrors] = useState<ServerErrorsType>({});
    const errors = useServerErrors(apiErrors, form);
    const {createWithAI, handleSwitchChange} = useWorkingPlan(setWorkingPlan);
    const [hasCarryover,setHasCarryover] = useState(false)
    const [hasDifferentExpiryDate,setHasDifferentExpiryDate] = useState(false)
    const [roles, setRoles] = useState<Role[]>([]);
    const [employmentTypes, setEmploymentTypes] = useState<EmploymentType[]>([]);
    const [federalStates, setFederalStates] = useState<federalState[]>([]);

    const fetchRoles = () => {
        axiosInstance.get(employeeManagementUrl + 'roles/')
            .then((res) => {
                setRoles(res.data)
            })
            .catch((err) => console.log(err))
    }

    const fetchEmployeeTypes = () => {
        axiosInstance.get(employeeManagementUrl + 'employment-types/')
            .then((res) => {
                setEmploymentTypes(res.data)
            })
            .catch((err) => console.log(err))
    }

    const fetchFederalStates = () => {
        axiosInstance.get(employeeManagementUrl + 'states/')
            .then((res) => {
                setFederalStates(res.data)
            })
            .catch((err) => console.log(err))
    }

    const validateEmployeeData = (employee: EmployeeCreationProps) => {
        return new Promise((resolve, reject) => {
            axiosInstance.post(employeesUrl + 'validate-only/', employee)
                .then((res) => {
                    resolve({
                        isValid: true
                    });
                })
                .catch((err) => {
                    reject({
                        isValid: false,
                        errors: err.response.data
                    });
                });
        });
    }

    async function handleValidation(values: z.infer<typeof formSchema>) {
        const data = {
            ...values,
            expiryDate: values.hasDifferentExpiryDate ? moment(values.expiryDate).format("YYYY-MM-DD") : null,
            manualCarryoverDays: values.hasCarryover ? values.manualCarryoverDays : null,
            nfcUUID: (values.nfcUUID) ? values.nfcUUID : null,
            entranceDate: formatDate(values?.entranceDate as Date),
        }

        if (data.roleID === "no-value") {
            return form.setError("roleID", {message: "Role is requierd"});
        } else {
            form.clearErrors("roleID");
        }

        if (data.employmentTypeID === "no-value") {
            return form.setError("employmentTypeID", {message: "Employment type is required"});
        } else {
            form.clearErrors("employmentTypeID");
        }

        validateEmployeeData(data as any)
            .then((res: any) => {
                setValidation(res.isValid);
                setApiErrors({});
                form.clearErrors();
                setStartingDate(values.entranceDate);
            })
            .catch((err: any) => {
                form.clearErrors();
                setApiErrors(err.errors);
                setValidation(err.isValid);
            })
    }

    const createEmployee = (employee: EmployeeCreationProps) => {
        axiosInstance.post(employeesUrl, employee)
            .then((res) => {
                setApiErrors({})
                closeModal.current?.click();
                sideFunctions?.forEach((func) => func());
                reFetch(true);
                toast({
                    title: intl.formatMessage({id: "toast.success", defaultMessage: "Great!"}),
                    description: intl.formatMessage({id: "toast.success.actionCompleted", defaultMessage: "Your action was completed successfully."})
                });
            })
            .catch((err) => {
                setApiErrors(err.response?.data);
            })
    }

    const onSubmit = (values: z.infer<typeof formSchema>) => {
        const data = {
            ...values,
            expiryDate: values.hasDifferentExpiryDate ? moment(values.expiryDate).format("YYYY-MM-DD") : null,
            manualCarryoverDays: values.hasCarryover ? values.manualCarryoverDays : null,
            nfcUUID: (values.nfcUUID) ? values.nfcUUID : null,
            entranceDate: formatDate(values?.entranceDate as Date),
            workingTimePlanData: (() => {
                const filteredRecords = workingPlan?.filter((record: any) => record.checked)
                return {
                    ...filteredRecords?.reduce((acc: any, day: any) => {
                        let dayName = day.dayName?.toLowerCase();
                        acc[dayName + "TimeFrom"] = formatTimeValue(day?.startTime as TimeValue);
                        acc[dayName + "TimeTo"] = formatTimeValue(day?.endTime as TimeValue);
                        return acc;
                    }, {}),
                    startingDate: moment(startingDate).local().format("YYYY-MM-DD"),
                }
            })()
        }
        createEmployee(data as any)
    };

    useEffect(() => {
        setWorkingPlanDurations((prevState) => {
            return prevState.map((elem) => {
                let currentDay = workingPlan.find(day => elem.day === day.day)

                if (!currentDay) return elem;

                let startTime = currentDay ? moment().set({
                    hours: currentDay.startTime?.hour,
                    minutes: currentDay.startTime?.minute,
                }) : null;
                let endTime = currentDay ? moment().set({
                    hours: currentDay.endTime?.hour,
                    minutes: currentDay.endTime?.minute,
                }) : null;
                let hours = "00h";
                let minutes = "00m";
                let formattedDuration = `${hours} ${minutes}`;
                let duration: moment.Duration = moment.duration(0);

                let isReady = !!(
                    String(currentDay.startTime?.hour ?? "").length &&
                    String(currentDay.startTime?.minute ?? "").length &&
                    String(currentDay.endTime?.hour ?? "").length &&
                    String(currentDay.endTime?.minute ?? "").length
                );

                if (
                    isReady &&
                    startTime &&
                    endTime
                ) {

                    if (startTime.isAfter(endTime)) {
                        endTime.add(1, "d")
                    }

                    duration = endTime ? moment.duration(endTime.diff(startTime)) : moment.duration(0);
                    if (duration) {
                        hours = Math.floor(duration.asHours()).toString();
                        minutes = duration.minutes().toString();

                        formattedDuration = (() => {
                            let formattedHours = (hours.length > 1 ? hours : `0${hours}`) + 'h';
                            let formattedMinutes = (minutes.length > 1 ? minutes : `0${minutes}`) + "min";

                            return `${formattedHours} ${formattedMinutes}`
                        })();
                    }
                }

                return {...elem, formattedDuration, duration};
            })
        })
    }, [workingPlan]);

    useEffect(() => {
        const totalDurationArray = workingPlanDurations.map(elem => elem.duration)
        const totalDurationsCalculated = totalDurationArray.reduce((accumulator, currentValue) => {
            return accumulator?.add(currentValue)
        })
        setTotalDurations(totalDurationsCalculated)
    }, [workingPlanDurations]);

    useEffect(() => {
        const subscription = form.watch((value, { name, type }) => {
            if (name === "hasCarryover") {
                setHasCarryover(value.hasCarryover)
            } else if (name === "hasDifferentExpiryDate") {
                setHasDifferentExpiryDate(value.hasDifferentExpiryDate)
            }
        })
        return () => subscription.unsubscribe()
    }, [])

    useEffect(() => {
        fetchRoles();
        fetchFederalStates();
        fetchEmployeeTypes();
    }, []);

    useEffect(() => {
        console.log(defaultValues)
    }, []);

    return (
        <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)} className="flex flex-col gap-4">
            {!isValidated ? (
            <>
                <FormTitle
                    title={intl.formatMessage({
                        id: "employees.forms.createEmployee.employeeData",
                        defaultMessage: "Employee Data"
                    })}
                />
                <FormField
                    control={form.control}
                    name="firstName"
                    render={({ field }) => (
                        <FormItem>
                            <FormLabel>
                                <FormattedMessage id={"employees.forms.createEmployee.employeeData.firstName"} defaultMessage={"First Name"}/>
                            </FormLabel>
                            <FormControl>
                                <Input
                                    placeholder={intl.formatMessage({
                                        id: "employees.forms.createEmployee.employeeData.firstName",
                                        defaultMessage: "first name"
                                    })}
                                    {...field}
                                />
                            </FormControl>
                            <FormMessage />
                        </FormItem>
                    )}
                />
                <FormField
                    control={form.control}
                    name="lastName"
                    render={({ field }) => (
                        <FormItem>
                            <FormLabel>
                                <FormattedMessage id={"employees.forms.createEmployee.employeeData.lastName"} defaultMessage={"Last Name"}/>
                            </FormLabel>
                            <FormControl>
                                <Input
                                    placeholder={intl.formatMessage({
                                        id: "employees.forms.createEmployee.employeeData.lastName",
                                        defaultMessage: "last name"
                                    })}
                                    {...field}
                                />
                            </FormControl>
                            <FormMessage />
                        </FormItem>
                    )}
                />
                <FormField
                    control={form.control}
                    name="email"
                    render={({ field }) => (
                        <FormItem>
                            <FormLabel>
                                <FormattedMessage id={"employees.forms.createEmployee.employeeData.email"} defaultMessage={"Email"}/>
                            </FormLabel>
                            <FormControl>
                                <Input
                                    placeholder={intl.formatMessage({
                                        id: "employees.forms.createEmployee.employeeData.email",
                                        defaultMessage: "email address"
                                    })}
                                    {...field}
                                />
                            </FormControl>
                            <FormMessage />
                        </FormItem>
                    )}
                />
                <FormField
                    control={form.control}
                    name="phoneNumber"
                    render={({ field }) => (
                        <FormItem>
                            <FormLabel>
                                <FormattedMessage id={"employees.forms.createEmployee.employeeData.phoneNumber"} defaultMessage={"Phone Number"}/>
                            </FormLabel>
                            <FormControl>
                                <Input
                                    placeholder={intl.formatMessage({
                                        id: "employees.forms.createEmployee.employeeData.phoneNumber",
                                        defaultMessage: "phone number"
                                    })}
                                    {...field}
                                />
                            </FormControl>
                            <FormMessage />
                        </FormItem>
                    )}
                />
                <FormTitle title={
                    intl.formatMessage({
                        id: "employees.forms.createEmployee.professionalData",
                        defaultMessage: "Professional Data"
                    })
                }/>
                <FormField
                    name={"personalNumber"}
                    render={({field}) => (
                        <FormItem>
                            <FormLabel>
                                <FormattedMessage id={"employees.forms.createEmployee.employeeData.personalNumber"} defaultMessage={"Personal Number"}/> <span className="text-xs text-slate-500">(optional)</span>
                            </FormLabel>
                            <FormControl>
                                <Input
                                    placeholder={intl.formatMessage({
                                        id: "employees.forms.createEmployee.employeeData.personalNumber",
                                        defaultMessage: "Personal number"
                                    })}
                                    {...field}
                                />
                            </FormControl>
                            <FormMessage />
                        </FormItem>
                    )}
                />
                <FormField
                    control={form.control}
                    name="roleID"
                    render={({ field }) => (
                        <FormItem>
                            <FormLabel>
                                <FormattedMessage id={"employees.forms.createEmployee.professionalData.role"} defaultMessage={"Role"}/>
                            </FormLabel>
                            <SearchInput
                                title={intl.formatMessage({
                                    id: "employees.forms.createEmployee.professionalData.role",
                                    defaultMessage: "Role"
                                })}
                                values={roles?.map((role, index) => {
                                    return {
                                        key: role.name,
                                        value: String(role.pk)
                                    }
                                })}
                                value={field.value}
                                onChange={field.onChange}
                            />
                            <FormMessage />
                        </FormItem>
                    )}
                />
                <FormField
                    control={form.control}
                    name="employmentTypeID"
                    render={({ field }) => (
                        <FormItem>
                            <FormLabel>
                                <FormattedMessage id={"employees.forms.createEmployee.professionalData.employmentType"} defaultMessage={"Employment Type"}/>
                            </FormLabel>
                            <SearchInput
                                title={intl.formatMessage({
                                    id: "employees.forms.createEmployee.professionalData.employmentType",
                                    defaultMessage: "Employment Type"
                                })}
                                values={employmentTypes?.map((employmentType, index) => {
                                    return {
                                        key: employmentType.type,
                                        value: String(employmentType.pk)
                                    }
                                })}
                                value={field.value}
                                onChange={field.onChange}
                            />
                            <FormMessage/>
                        </FormItem>
                    )}
                />
                <FormField
                    control={form.control}
                    name="entranceDate"
                    render={({ field }) => (
                        <FormItem className="flex flex-col">
                            <FormLabel>
                                <FormattedMessage id={"employees.forms.createEmployee.professionalData.entranceDate"} defaultMessage={"Entrance Date"}/>
                            </FormLabel>
                            <FormControl>
                                <DatePicker date={field.value} setDate={field.onChange}/>
                            </FormControl>
                            <FormMessage />
                        </FormItem>
                    )}
                />
                <FormField
                    control={form.control}
                    name="stateCode"
                    render={({ field }) => (
                        <FormItem>
                            <FormLabel>
                                <FormattedMessage id={"employees.forms.createEmployee.professionalData.federalState"} defaultMessage={"Federal State"}/>
                            </FormLabel>
                            <SearchInput
                                title={intl.formatMessage({
                                    id: "employees.forms.createEmployee.professionalData.federalState",
                                    defaultMessage: "Federal State"
                                })}
                                values={federalStates.map((federalState, index) => {
                                    return {
                                        key: federalState.stateName,
                                        value: federalState.stateCode
                                    }
                                }) ?? []}
                                value={field.value}
                                onChange={field.onChange}
                            />
                            <FormMessage />
                        </FormItem>
                    )}
                />

                <FormTitle
                    title={intl.formatMessage({
                        id: "employees.forms.createEmployee.vacationData",
                        defaultMessage: "Vacation Data"
                    })}
                />
                <FormField
                    control={form.control}
                    name="vacationDays"
                    render={({ field }) => (
                        <FormItem>
                            <FormLabel>
                                <FormattedMessage id={"employees.forms.createEmployee.professionalData.vacation"} defaultMessage={"Vaction Days Per Year"}/>
                            </FormLabel>
                            <FormControl>
                                <Input placeholder="0" type="number" min="0" max="365" {...field}/>
                            </FormControl>
                            <FormMessage />
                        </FormItem>
                    )}
                />
                <FormField
                    control={form.control}
                    name="hasCarryover"
                    render={({ field }) => (
                        <FormItem className="flex flex-col space-y-4">
                            <span className="text-sm">
                                <FormattedMessage
                                    id={"employees.forms.createEmployee.carryoverSentence"}
                                    defaultMessage={"Carry over vacation days from the previous year to the current year?"}
                                />
                            </span>
                            <FormControl>
                                <div className="flex flex-row items-center gap-2 text-xs text-gray-500 capitalize">
                                    <Switch
                                        className="data-[state=checked]:bg-green-500"
                                        checked={field.value}
                                        onCheckedChange={field.onChange}
                                    />
                                    {field.value ? <FormattedMessage id={"yes"} defaultMessage={"Yes"}/> : <FormattedMessage id={"no"} defaultMessage={"No"}/>}
                                </div>
                            </FormControl>
                            <FormMessage />
                        </FormItem>
                    )}
                />
                {hasCarryover ? (
                    <FormField
                        control={form.control}
                        name="manualCarryoverDays"
                        render={({ field }) => (
                            <FormItem>
                                <FormLabel>
                                    <FormattedMessage id={"employees.forms.createEmployee.manualCarryoverDays"} defaultMessage={"Carry Over Days"}/>
                                </FormLabel>
                                <FormControl>
                                    <Input placeholder="0" type="number" {...field}/>
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        )}
                    />
                ) : null}

                <FormField
                    control={form.control}
                    name="hasDifferentExpiryDate"
                    render={({ field }) => (
                        <FormItem className="flex flex-col space-y-4">
                            <span className="text-sm">
                                <FormattedMessage
                                    id={"employees.forms.createEmployee.expiryDateSentence"}
                                    defaultMessage={"Should vacation carryovers expire at a different time?"}
                                />
                            </span>
                            <FormControl>
                                <div className="flex flex-row items-center gap-2 text-xs text-gray-500 capitalize">
                                    <Switch
                                        className="data-[state=checked]:bg-green-500"
                                        checked={field.value}
                                        onCheckedChange={field.onChange}
                                    />
                                    {field.value ? <FormattedMessage id={"yes"} defaultMessage={"Yes"}/> : <FormattedMessage id={"no"} defaultMessage={"No"}/>}
                                </div>
                            </FormControl>
                            <FormMessage />
                        </FormItem>
                    )}
                />
                {hasDifferentExpiryDate ? (
                    <FormField
                        control={form.control}
                        name="expiryDate"
                        render={({ field }) => (
                            <FormItem className="flex flex-col space-y-4">
                                <FormLabel>
                                    <FormattedMessage
                                        id={"employees.forms.createEmployee.expiryDate"}
                                        defaultMessage={"Expiry Date"}
                                    />
                                </FormLabel>
                                <FormControl>
                                    <DatePicker date={field.value} setDate={field.onChange}/>
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        )}
                    />
                ) : null}
                <FormTitle title={
                    intl.formatMessage({
                        id: "employees.forms.createEmployee.account",
                        defaultMessage: "Account"
                    })
                }/>
                <FormField
                    control={form.control}
                    name="nfcUUID"
                    render={({ field }) => (
                        <FormItem>
                            <FormLabel>nfcUUID <span className="text-xs text-slate-500">(optional)</span></FormLabel>
                            <FormControl>
                                <Input placeholder="nfcUUID" {...field}/>
                            </FormControl>
                            <FormMessage />
                        </FormItem>
                    )}
                />
                <div className="flex flex-row">
                <FormField
                    control={form.control}
                    name="isActive"
                    render={({field}) => (
                        <FormItem className="w-1/2">
                            <FormControl>
                                <div className="flex flex-row items-center gap-2">
                                    <Switch
                                        className="data-[state=checked]:bg-green-500"
                                        checked={field.value}
                                        onCheckedChange={field.onChange}
                                    />
                                    <FormLabel className="text-xs text-slate-500">{field.value ? (
                                        <FormattedMessage id={"employees.forms.createEmployee.account.activated"} defaultMessage={"Activated"}/>
                                    ) : (
                                        <FormattedMessage id={"employees.forms.createEmployee.account.deactivated"} defaultMessage={"Deactivated"}/>
                                    )}</FormLabel>
                                </div>
                            </FormControl>
                            <FormMessage />
                        </FormItem>
                    )}
                />
                <FormField
                    control={form.control}
                    name="isTestUser"
                    render={({field}) => (
                        <FormItem className="w-1/2">
                            <FormControl>
                                <div className="flex flex-row items-center gap-2">
                                    <Switch
                                        className="data-[state=checked]:bg-green-500"
                                        checked={field.value}
                                        onCheckedChange={field.onChange}
                                    />
                                    <FormLabel className="text-xs text-slate-500">
                                        <FormattedMessage id={"employees.forms.createEmployee.account.isTestUser"} defaultMessage={"Test User"}/>
                                    </FormLabel>
                                </div>
                            </FormControl>
                            <FormMessage />
                        </FormItem>
                    )}
                />
                </div>
                <FormTitle title={
                    intl.formatMessage({
                        id: "employees.forms.createEmployee.password",
                        defaultMessage: "Password"
                    })
                }/>
                <FormField
                    control={form.control}
                    name="password"
                    render={({ field }) => (
                        <FormItem>
                            <FormLabel>
                                <FormattedMessage id={"employees.forms.createEmployee.password"} defaultMessage={"Password"}/>
                            </FormLabel>
                            <FormControl>
                                <Input
                                    placeholder={
                                        intl.formatMessage({
                                            id: "employees.forms.createEmployee.password",
                                            defaultMessage: "Password"
                                        })
                                    }
                                    type="password"
                                    {...field}
                                />
                            </FormControl>
                            <FormMessage />
                        </FormItem>
                    )}
                />
                <FormField
                    control={form.control}
                    name="confirmPassword"
                    render={({ field }) => (
                        <FormItem>
                            <FormLabel>
                                <FormattedMessage id={"employees.forms.createEmployee.confirmPassword"} defaultMessage={"Confirm Password"}/>
                            </FormLabel>
                            <FormControl>
                                <Input
                                    placeholder={
                                        intl.formatMessage({
                                            id: "employees.forms.createEmployee.confirmPassword",
                                            defaultMessage: "Confirm Password"
                                        })
                                    }
                                    type="password"
                                    {...field}
                                />
                            </FormControl>
                            <FormMessage />
                        </FormItem>
                    )}
                />

                <ErrorMessageList errors={errors.nonFieldErrors}/>
                <ErrorMessageList errors={errors.detailErrors}/>

                <DialogFooter className="justify-between space-x-2 px-0 pb-0">
                    <DialogClose asChild>
                        <Button variant="outline">
                            <FormattedMessage id={"button.cancel"} defaultMessage={"Cancel"}/>
                        </Button>
                    </DialogClose>
                    <Button
                        variant="taimDefault"
                        type="button"
                        onClick={() => handleValidation(form.getValues())}
                    ><FormattedMessage id={"button.next"} defaultMessage={"Next"}/></Button>
                </DialogFooter>
            </>
        ) : (
            <div className="flex flex-col gap-4 overflow-auto no-scrollbar">
                <DetailViewCard rows={[
                    {
                        name: intl.formatMessage({
                            id: "employees.forms.createEmployee.workingPlan.typeOfEmployment",
                            defaultMessage: "Type Of Employment"
                        }),
                        value: employmentTypes.find((employmentType) => {
                            return employmentType.pk.toString() === form.getValues("employmentTypeID")
                        })?.type ?? form.getValues("employmentTypeID")
                    },
                    {
                        name: intl.formatMessage({
                            id: "employees.forms.createEmployee.workingPlan.entranceDate",
                            defaultMessage: "Entrance Date"
                        }),
                        value: form.getValues("entranceDate") ? moment(form.getValues("entranceDate")).format("DD MMM YYYY") : nullOrUndefined
                    },
                ]}/>
                <div className="flex flex-row justify-between items-center">
                    <div>
                        <FormTitle title={intl.formatMessage({
                            id: "employees.forms.createEmployee.workingPlan.workingPlan",
                            defaultMessage: "Working Plan"
                        })}/>
                        <span className="text-slate-500 text-xs">
                            <FormattedMessage
                                id={"employees.forms.createEmployee.workingPlan.workingPlan.sentence"}
                                defaultMessage={"Configure at first the Time Plots for the working days"}
                            />
                        </span>
                    </div>
                    <Button
                        type="button"
                        variant={"taimDefault2"}
                        className="flex flex-row gap-2"
                        onClick={createWithAI}
                    >
                        <span>
                            <FormattedMessage
                                id={"employees.forms.createEmployee.workingPlan.workingPlan.createWithAI"}
                                defaultMessage={"Create with AI"}
                            />
                        </span>
                        <MagicWand size={16} color="#233B99"/>
                    </Button>
                </div>
                <WorkingPlanBody>
                    {workingPlan ? (
                        workingPlan.map((day, index) => (
                            <TableRow className="border-none">
                                <TableCell>
                                    <FormattedMessage
                                        id={day.dayName?.toLowerCase()}
                                        defaultMessage={day.dayName}
                                    />
                                </TableCell>
                                <TableCell>
                                    <FormField
                                        control={form.control}
                                        name={`workingTimePlanData.${index}.startTime`}
                                        render={({ field }) => (
                                            <FormItem>
                                                <FormControl>
                                                    <TimePicker
                                                        label={field.name}
                                                        isDisabled={!day?.checked}
                                                        value={day.startTime}
                                                        onChange={(value) => {
                                                            setWorkingPlan(prevState => (
                                                                prevState.map(elem => {
                                                                    if (elem.day === day.day) {
                                                                        return {
                                                                            ...elem,
                                                                            startTime: value
                                                                        }
                                                                    }
                                                                    return  elem;
                                                                })
                                                            ))
                                                        }}
                                                        hideTimeZone
                                                    />
                                                </FormControl>
                                                <FormMessage/>
                                            </FormItem>
                                        )}
                                    />
                                </TableCell>
                                <TableCell>
                                    <FormField
                                        control={form.control}
                                        name={`workingTimePlanData.${index}.endTime`}
                                        render={({ field }) => (
                                            <FormItem>
                                                <FormControl>
                                                    <TimePicker
                                                        label={field.name}
                                                        isDisabled={!day?.checked}
                                                        value={day.endTime}
                                                        onChange={(value) => {
                                                            setWorkingPlan(prevState => (
                                                                prevState.map(elem => {
                                                                    if (elem.day === day.day) {
                                                                        return {
                                                                            ...elem,
                                                                            endTime: value
                                                                        }
                                                                    }
                                                                    return  elem;
                                                                })
                                                            ))
                                                        }}
                                                    />
                                                </FormControl>
                                                <FormMessage/>
                                            </FormItem>
                                        )}
                                    />
                                </TableCell>
                                <TableCell>
                                    {workingPlanDurations.find(elem => elem.day === day.day)?.formattedDuration}
                                </TableCell>
                                <TableCell>
                                    <Switch
                                        checked={day.checked}
                                        className="data-[state=checked]:bg-blue-900"
                                        onClick={() => {
                                            handleSwitchChange(index)
                                            setWorkingPlan(prevState => (
                                                prevState.map(elem => {
                                                    if (elem.day === day.day) {
                                                        return {
                                                            ...elem,
                                                            startTime: null,
                                                            endTime: null,
                                                            checked: !day.checked
                                                        }
                                                    }
                                                    return elem;
                                                })
                                            ))
                                        }}
                                    />
                                </TableCell>
                            </TableRow>
                        ))
                    ) : null}
                    <TableRow>
                        <TableCell colSpan={3} className={"text-right"}>
                            <span className="">Total</span>
                        </TableCell>
                        <TableCell colSpan={2}>{(() => {
                            let hours = "00h";
                            let minutes = "00m";
                            let formattedDuration = `${hours} ${minutes}`;

                            if (totalDurations) {
                                hours = Math.floor(totalDurations.asHours()).toString();
                                minutes = totalDurations.minutes().toString();

                                formattedDuration = (() => {
                                    let formattedHours = (hours.length > 1 ? hours : `0${hours}`) + 'h';
                                    let formattedMinutes = (minutes.length > 1 ? minutes : `0${minutes}`) + "min";

                                    return `${formattedHours} ${formattedMinutes}`
                                })();
                            }
                            return <span>{formattedDuration}</span>
                        })()}</TableCell>
                    </TableRow>
                </WorkingPlanBody>
                <DetailViewCard rows={[
                    {jsxElement:
                        <div className="flex flex-col gap-3">
                            <div className="text-slate-700 text-base">
                                <FormattedMessage
                                    id={"employees.forms.createEmployee.workingPlan.startingDate"}
                                    defaultMessage={"Starting Date"}
                                />
                            </div>
                            <DatePicker
                                date={startingDate}
                                setDate={setStartingDate}
                                disabled={form.getValues('entranceDate')} daysToDisable={"old"}
                            />
                            <span className="text-slate-500 text-sm">
                                <FormattedMessage
                                    id={"employees.forms.createEmployee.workingPlan.startingDate.sentence"}
                                    defaultMessage={`This plan will be active starting from {startingDate} until deleted or replaced`}
                                    values={{
                                        startingDate: moment(startingDate).format("ddd, DD MMM YYYY") ?? nullOrUndefined,
                                    }}
                                />
                            </span>
                        </div>
                    }
                ]}/>

                <ErrorMessageList errors={errors.nonFieldErrors}/>
                <ErrorMessageList errors={errors.detailErrors}/>
                {errors.fieldErrors.hasOwnProperty('entranceDate')
                    ? <div className="text-sm text-red-500">* {errors.fieldErrors['entranceDate']}</div>
                    : null
                }

                <DialogFooter className="justify-between space-x-2 px-0 pb-0">
                    <DialogClose ref={closeModal as any} asChild>
                        <Button className="hidden">close</Button>
                    </DialogClose>
                    <Button
                        variant="outline"
                        onClick={() => setValidation(false)}
                    ><FormattedMessage id={"button.back"} defaultMessage={"Back"}/></Button>
                    <Button
                        variant="taimDefault"
                        type="submit"
                    ><FormattedMessage id={"button.submit"} defaultMessage={"Submit"}/></Button>
                </DialogFooter>
            </div>
        )}
        </form>
    </Form>
    )
}
