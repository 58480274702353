import * as React from "react"
import { Calendar as CalendarIcon } from "lucide-react"
import { cn } from "../lib/utils"
import { Button } from "./button"
import { Calendar } from "./calendar"
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "./popover"
import {FormattedMessage} from "react-intl";
import {useContext, useEffect} from "react";
import {de, enUS} from "date-fns/locale";
import {Context} from "../Core/utilities/Wrapper";
import moment from "moment";
import {format} from "date-fns";
import {toZonedTime} from "date-fns-tz";
import {FROM_YEAR, TO_YEAR} from "../Core/constants/variables";


type DatePickerProps = {
    disabled?: boolean | Date
    date?: Date
    setDate: React.Dispatch<Date | undefined>
    daysToDisable?: "coming" | "old"
}

const setOtherZone = (date?: Date, timezone?: string) : Date => {
    const dateWithoutZone = moment(date).format("YYYY-MM-DDTHH:mm:ss.SSS")
    const otherZone = moment.tz(date, timezone ?? "Europe/Berlin").format("Z")
    const dateWithOtherZone = [dateWithoutZone, otherZone].join("")

    // return new Date(moment(date).tz("Europe/Berlin").format("YYYY-MM-DDTHH:mm:ss.SSS"))
    return new Date(dateWithOtherZone)
}


export const DatePicker: React.FC<DatePickerProps> = ({
    date,
    setDate,
    disabled,
    daysToDisable
}) => {
    const context = useContext(Context);
    const locale = context.language?.includes( "de") ? de : enUS;

    useEffect(() => {
        const utcDate = toZonedTime(new Date(), "Europe/Berlin")
    }, []);

    return (
    <Popover>
      <PopoverTrigger asChild>
        <Button
          variant={"outline"}
          className={cn(
            "w-auto justify-start text-left font-normal",
            !date && "text-muted-foreground"
          )}
          disabled={typeof disabled === "boolean" && disabled}
        >
          <CalendarIcon className="mr-2 h-4 w-4" />
          {date ? format(date, "LLL dd, y", { locale: locale }) : <span>
              <FormattedMessage
                  id={"datePicker.sentence"}
                  defaultMessage={"Pick a date"}
              />
          </span>}
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-auto p-0">
        <Calendar
          mode="single"
          selected={date}
          onSelect={(value) => {
              let newValue = value ? setOtherZone(value, "Europe/Berlin") : undefined;
              setDate(newValue);
          }}
          initialFocus
          disabled={(disabled) ? (currDate) => {
              if (typeof disabled === "boolean") return disabled
              if (daysToDisable) {
                  switch (daysToDisable) {
                      case "coming": {
                          return currDate > setOtherZone(new Date()) || currDate < new Date("1900-01-01")
                      }
                      case "old": {
                          return currDate < disabled
                      }
                      default: {
                          return currDate > setOtherZone(new Date()) || currDate < new Date("1900-01-01")
                      }
                  }
              }
              return currDate > setOtherZone(new Date()) || currDate < new Date("1900-01-01")
          } : undefined}
          locale={locale}
          defaultMonth={date}
          captionLayout={"dropdown-buttons"}
          fromYear={FROM_YEAR}
          toYear={TO_YEAR}
          fixedWeeks
          weekStartsOn={1}
        />
      </PopoverContent>
    </Popover>
    )
}