import {Button} from "../../components/button";
import {Label} from "../../components/label";
import {useAxiosInstance} from "../../Core/utilities/AxiosInstance";
import {toast} from "../../components/use-toast";
import React, {useContext, useEffect, useRef, useState} from "react";
import {DialogClose, DialogFooter} from "../../components/dialog";
import {Tabs, TabsContent, TabsList, TabsTrigger} from "../../components/tab";
import {Input} from "../../components/input";
import {Employee, employeesUrl, federalState, Role} from "../Controllers/employee-controller";
import {useForm} from "react-hook-form";
import * as z from "zod";
import {zodResolver} from "@hookform/resolvers/zod";
import {Form, FormControl, FormField, FormItem, FormLabel, FormMessage} from "../../components/form";
import {FormattedMessage, useIntl} from "react-intl";
import {ServerErrorsType, useServerErrors} from "../../Core/functions/use-server-errors";
import ErrorMessageList from "../../Core/components/ErrorMessageList";
import SearchInput from "../../Core/components/search-input";
import {Select, SelectContent, SelectItem, SelectTrigger, SelectValue} from "../../components/select";
import {Switch} from "../../components/switch";
import {PermissionContext} from "../../Core/utilities/PermissionProvider";

const formSchema = z.object({
    firstName: z.any(),
    lastName: z.any(),
    email: z.any(),
    phoneNumber: z.any(),
    roleID: z.any(),
    stateCode: z.any(),
    nfcUUID: z.any(),
    password: z.any(),
    confirmPassword: z.any(),
    entranceDate: z.date().optional(),
    vacationDays: z.any(),
    isActive: z.boolean().optional(),
    isTestUser: z.boolean().optional(),
    accountRole: z.any(),
    personalNumber: z.any()
})

type FormEmployeeEditProps = {
    employeeUUID: string
    roles: Role[]
    federalStates: federalState[]
    rePopulateRecord: (uuid: string) => void
}

const FormEmployeeEdit: React.FC<FormEmployeeEditProps> = ({
    employeeUUID,
    roles,
    federalStates,
    rePopulateRecord
}) => {
    const intl = useIntl();
    const axiosInstance = useAxiosInstance();
    const personalDataName = intl.formatMessage({id: "employees.forms.addEmployee.personnalData", defaultMessage: "Personnal Data"});
    const professionalDataName = intl.formatMessage({id: "employees.forms.addEmployee.professionalData", defaultMessage: "Professional Data"});
    const accountName = intl.formatMessage({id: "employees.forms.addEmployee.account", defaultMessage: "Account"});
    const passwordName = intl.formatMessage({id: "employees.forms.addEmployee.password", defaultMessage: "Password"});
    const form = useForm<z.infer<typeof formSchema>>({
        resolver: zodResolver(formSchema)
    });
    const [employee, setEmployee] = useState<Employee | null>(null)
    const [apiErrors, setApiErrors] = useState<ServerErrorsType>({});
    const errors = useServerErrors(apiErrors, form);
    const closeModal = useRef<HTMLElement>();
    const permissionContext = useContext(PermissionContext);
    const [tab, setTab] = useState(personalDataName);
    const errorSections = {
        personalData: [
            "firstName",
            "lastName",
            "email",
            "phoneNumber",
        ],
        professionalData: [
            "roleID",
            "stateCode",
        ],
        account: [
            "nfcUUID",
            "accountRole",
            "isActive",
            "isTestUser"
        ],
        password: [
            "password",
            "confirmPassword"
        ]
    }

    const onTabChange = (value: string) => {
        setTab(value);
    }

    async function onSubmit(values: z.infer<typeof formSchema>) {
        const data = {
            ...values,
            roleID: values.roleID !== "no-value" ? values.roleID : null,
            stateCode: values.stateCode !== "no-value" ? values.stateCode : null,
        }

        axiosInstance.put(employeesUrl + `${employeeUUID}/`, data)
            .then((res) => {
                setApiErrors({});
                closeModal.current?.click();
                toast({
                    title: intl.formatMessage({id: "toast.success", defaultMessage: "Great!"}),
                    description: intl.formatMessage({id: "toast.success.updatedSuccessfully", defaultMessage: "The update was completed successfully."})
                });
                rePopulateRecord(employeeUUID);
            })
            .catch((err) => {
                setApiErrors(err.response.data);
            })
    }

    const fetchEmployeeData = (id: string) => {
        axiosInstance.get(employeesUrl + `${id}/`)
            .then((res) => {
                setEmployee(res.data);
            })
            .catch((err) => console.log(err))
    }

    useEffect(() => {
        if (!employee) {
            fetchEmployeeData(employeeUUID);
        }
    }, [employeeUUID]);

    useEffect(() => {
        if (employee && roles) {
            form.reset({
                firstName: employee?.firstName,
                lastName: employee?.lastName,
                email: employee?.email,
                roleID: roles.find(role => role.name === employee?.role)?.pk.toString(),
                stateCode: employee?.stateCode,
                entranceDate: new Date(employee?.entranceDate),
                isTestUser: employee?.isTestUser,
                isActive: employee?.isActive,
                accountRole: employee?.accountRole,
                ...(employee?.nfcUUID && { nfcUUID: employee.nfcUUID }),
                ...(employee?.phoneNumber && { phoneNumber: employee.phoneNumber }),
                ...(employee?.personalNumber && { personalNumber: employee.personalNumber }),
            });
        }
    }, [employee]);

    useEffect(() => {
        if (apiErrors) {
            let error = Object.keys(apiErrors).filter((_,index) => index === 0)[0]

            if (errorSections.personalData.includes(error)) {
                setTab(personalDataName)
            } else if (errorSections.professionalData.includes(error)) {
                setTab(professionalDataName)
            } else if (errorSections.account.includes(error)) {
                setTab(accountName)
            } else if (errorSections.password.includes(error)) {
                setTab(passwordName)
            }
        }
    }, [apiErrors]);

    return (
        <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)} className="flex flex-col gap-4">
                <Tabs value={tab} onValueChange={onTabChange}>
                    <TabsList className="mb-4">
                        <TabsTrigger value={personalDataName}>{personalDataName}</TabsTrigger>
                        <TabsTrigger value={professionalDataName}>{professionalDataName}</TabsTrigger>
                        <TabsTrigger value={accountName}>{accountName}</TabsTrigger>
                        <TabsTrigger value={passwordName}>{passwordName}</TabsTrigger>
                    </TabsList>
                    <TabsContent value={personalDataName}>
                        <div className="flex flex-col gap-4">
                            <div className="flex flex-col gap-2">
                                <FormField
                                    control={form.control}
                                    name="firstName"
                                    render={({ field }) => (
                                        <FormItem>
                                            <FormLabel>
                                                <FormattedMessage id={"employees.forms.addEmployee.personnalData.firstName"} defaultMessage={"First Name"}/>
                                            </FormLabel>
                                            <FormControl>
                                                <Input
                                                    placeholder={intl.formatMessage({
                                                        id: "employees.forms.addEmployee.personnalData.firstName",
                                                        defaultMessage: "first name"
                                                    }).toLowerCase()}
                                                    {...field}
                                                />
                                            </FormControl>
                                            <FormMessage />
                                        </FormItem>
                                    )}
                                />
                            </div>
                            <div className="flex flex-col gap-2">
                                <FormField
                                    control={form.control}
                                    name="lastName"
                                    render={({ field }) => (
                                        <FormItem>
                                            <FormLabel>
                                                <FormattedMessage id={"employees.forms.addEmployee.personnalData.lastName"} defaultMessage={"Last Name"}/>
                                            </FormLabel>
                                            <FormControl>
                                                <Input
                                                    placeholder={intl.formatMessage({
                                                        id: "employees.forms.addEmployee.personnalData.lastName",
                                                        defaultMessage: "last name"
                                                    }).toLowerCase()}
                                                    {...field}
                                                />
                                            </FormControl>
                                            <FormMessage />
                                        </FormItem>
                                    )}
                                />
                            </div>
                            <div className="flex flex-col gap-2">
                                <Label htmlFor="username">
                                    <FormattedMessage
                                        id={"employees.forms.addEmployee.personnalData.userName"}
                                        defaultMessage={"Username"}
                                    />
                                </Label>
                                <Input
                                    id="username"
                                    disabled={true}
                                    value={employee?.username}
                                />
                            </div>
                            <div className="flex flex-col gap-2">
                                <FormField
                                    control={form.control}
                                    name="email"
                                    render={({ field }) => (
                                        <FormItem>
                                            <FormLabel>
                                                <FormattedMessage id={"employees.forms.addEmployee.personnalData.email"} defaultMessage={"Email"}/>
                                            </FormLabel>
                                            <FormControl>
                                                <Input
                                                    placeholder={intl.formatMessage({
                                                        id: "employees.forms.addEmployee.personnalData.email",
                                                        defaultMessage: "email adress"
                                                    }).toLowerCase()}
                                                    {...field}
                                                />
                                            </FormControl>
                                            <FormMessage />
                                        </FormItem>
                                    )}
                                />
                            </div>
                            <div className="flex flex-col gap-2">
                                <FormField
                                    control={form.control}
                                    name="phoneNumber"
                                    render={({ field }) => (
                                        <FormItem>
                                            <FormLabel>
                                                <FormattedMessage id={"employees.forms.addEmployee.personnalData.phoneNumber"} defaultMessage={"Phone Number"}/>
                                            </FormLabel>
                                            <FormControl>
                                                <Input
                                                    placeholder={intl.formatMessage({
                                                        id: "employees.forms.addEmployee.personnalData.phoneNumber",
                                                        defaultMessage: "phone number"
                                                    }).toLowerCase()}
                                                    value={field.value ?? undefined}
                                                    onChange={field.onChange}
                                                />
                                            </FormControl>
                                            <FormMessage />
                                        </FormItem>
                                    )}
                                />
                            </div>
                        </div>
                    </TabsContent>
                    <TabsContent value={professionalDataName}>
                        <div className="flex flex-col gap-4">
                            <FormField
                                name={"personalNumber"}
                                render={({field}) => (
                                    <FormItem>
                                        <FormLabel>
                                            <FormattedMessage id={"employees.forms.createEmployee.employeeData.personalNumber"} defaultMessage={"Personal Number"}/> <span className="text-xs text-slate-500">(optional)</span>
                                        </FormLabel>
                                        <FormControl>
                                            <Input
                                                placeholder={intl.formatMessage({
                                                    id: "employees.forms.addEmployee.employeeData.personalNumber",
                                                    defaultMessage: "Personal number"
                                                })}
                                                {...field}
                                            />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />
                            <div className="flex flex-col gap-2">
                                <FormField
                                    control={form.control}
                                    name="roleID"
                                    render={({ field }) => (
                                        <FormItem>
                                            <FormLabel>
                                                <FormattedMessage id={"employees.forms.addEmployee.professionalData.role"} defaultMessage={"Role"}/>
                                            </FormLabel>
                                            <SearchInput
                                                title={intl.formatMessage({
                                                    id: "employees.forms.createEmployee.professionalData.pickRole",
                                                    defaultMessage: "Pick a role"
                                                })}
                                                values={roles?.map((role, index) => {
                                                    return {
                                                        key: role.name,
                                                        value: String(role.pk)
                                                    }
                                                }) ?? []}
                                                value={field.value}
                                                onChange={field.onChange}
                                            />
                                            <FormMessage />
                                        </FormItem>
                                    )}
                                />
                            </div>
                            <div className="flex flex-col gap-2">
                                <FormField
                                    control={form.control}
                                    name="stateCode"
                                    render={({ field }) => (
                                        <FormItem>
                                            <FormLabel>
                                                <FormattedMessage id={"employees.forms.addEmployee.professionalData.federalState"} defaultMessage={"Federal State"}/>
                                            </FormLabel>
                                            <SearchInput
                                                title={intl.formatMessage({
                                                    id: "employees.forms.addEmployee.professionalData.pickFederalState",
                                                    defaultMessage: "Pick a federal state"
                                                })}
                                                values={federalStates?.map((federalState, index) => {
                                                    return {
                                                        key: federalState.stateName,
                                                        value: String(federalState.stateCode)
                                                    }
                                                }) ?? []}
                                                value={field.value}
                                                onChange={field.onChange}
                                            />
                                            <FormMessage />
                                        </FormItem>
                                    )}
                                />
                            </div>
                        </div>
                    </TabsContent>
                    <TabsContent value={accountName}>
                        <div className="flex flex-col gap-4">
                            <div className="flex flex-col gap-2">
                                <FormField
                                    control={form.control}
                                    name="nfcUUID"
                                    render={({ field }) => (
                                        <FormItem>
                                            <FormLabel>nfcUUID <span className="text-xs text-slate-500">(optional)</span></FormLabel>
                                            <FormControl>
                                                <Input placeholder="nfcUUID" {...field}/>
                                            </FormControl>
                                            <FormMessage />
                                        </FormItem>
                                    )}
                                />
                            </div>
                            {permissionContext.role === "superadmin" ? (
                                <div className="flex flex-col gap-2">
                                <FormField
                                    control={form.control}
                                    name="accountRole"
                                    render={({ field }) => (
                                        <FormItem>
                                            <FormLabel>Roles <span className="text-xs text-slate-500">(optional)</span></FormLabel>
                                            <FormControl>
                                                <Select defaultValue={field.value} onValueChange={field.onChange}>
                                                    <SelectTrigger>
                                                        <SelectValue placeholder={"Pick a Role"}></SelectValue>
                                                    </SelectTrigger>
                                                    <SelectContent>
                                                            <SelectItem value={"user"}>User</SelectItem>
                                                            <SelectItem value={"admin"}>Admin</SelectItem>
                                                            <SelectItem value={"superadmin"}>Superadmin</SelectItem>
                                                    </SelectContent>
                                                </Select>
                                            </FormControl>
                                            <FormMessage />
                                        </FormItem>
                                    )}
                                />
                            </div>
                            ) : null}
                            <div className="flex flex-row">
                            <FormField
                                control={form.control}
                                name="isActive"
                                render={({field}) => (
                                    <FormItem className="w-1/2">
                                        <FormControl>
                                            <div className="flex flex-row items-center gap-2">
                                                <Switch
                                                    className="data-[state=checked]:bg-green-500"
                                                    checked={field.value}
                                                    onCheckedChange={field.onChange}
                                                />
                                                <FormLabel className="text-xs text-slate-500">{field.value ? (
                                                    <FormattedMessage id={"employees.forms.createEmployee.account.activated"} defaultMessage={"Activated"}/>
                                                ) : (
                                                    <FormattedMessage id={"employees.forms.createEmployee.account.deactivated"} defaultMessage={"Deactivated"}/>
                                                )}</FormLabel>
                                            </div>
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />
                            <FormField
                                control={form.control}
                                name="isTestUser"
                                render={({field}) => (
                                    <FormItem className="w-1/2">
                                        <FormControl>
                                            <div className="flex flex-row items-center gap-2">
                                                <Switch
                                                    className="data-[state=checked]:bg-green-500"
                                                    checked={field.value}
                                                    onCheckedChange={field.onChange}
                                                />
                                                <FormLabel className="text-xs text-slate-500">
                                                    <FormattedMessage id={"employees.forms.createEmployee.account.isTestUser"} defaultMessage={"Test User"}/>
                                                </FormLabel>
                                            </div>
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />
                            </div>
                        </div>
                    </TabsContent>
                    <TabsContent value={passwordName}>
                        <div className="flex flex-col gap-4">
                            <FormField
                                control={form.control}
                                name="password"
                                render={({ field }) => (
                                    <FormItem>
                                        <FormLabel>
                                            <FormattedMessage id={"employees.forms.addEmployee.password"} defaultMessage={"Password"}/>
                                        </FormLabel>
                                        <FormControl>
                                            <Input
                                                placeholder={
                                                    intl.formatMessage({
                                                        id: "employees.forms.addEmployee.password",
                                                        defaultMessage: "Password"
                                                    })
                                                }
                                                type="password"
                                                {...field}
                                            />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />
                            <FormField
                                control={form.control}
                                name="confirmPassword"
                                render={({ field }) => (
                                    <FormItem>
                                        <FormLabel>
                                            <FormattedMessage id={"employees.forms.addEmployee.confirmPassword"} defaultMessage={"Confirm Password"}/>
                                        </FormLabel>
                                        <FormControl>
                                            <Input
                                                placeholder={
                                                    intl.formatMessage({
                                                        id: "employees.forms.addEmployee.confirmPassword",
                                                        defaultMessage: "Confirm Password"
                                                    })
                                                }
                                                type="password"
                                                {...field}
                                            />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />
                        </div>
                    </TabsContent>
                </Tabs>

                <ErrorMessageList errors={errors.nonFieldErrors}/>
                <ErrorMessageList errors={errors.detailErrors}/>

                <DialogFooter className="justify-between space-x-2 px-0 pb-0">
                    <DialogClose ref={closeModal as any} asChild>
                        <Button variant={"outline"}>
                            <FormattedMessage
                                id={"button.cancel"}
                                defaultMessage={"Cancel"}
                            />
                        </Button>
                    </DialogClose>
                    <Button
                        variant="taimDefault"
                        type="submit"
                    ><FormattedMessage id={"button.submit"} defaultMessage={"Submit"}/></Button>
                </DialogFooter>
            </form>
        </Form>
    );
}

export default FormEmployeeEdit;