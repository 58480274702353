import {useContext, useEffect, useState} from "react";
import {useAxiosInstance} from "../../Core/utilities/AxiosInstance";
import {status} from "../../Core/functions/status-variants";
import {PermissionContext} from "../../Core/utilities/PermissionProvider";

export const timesheetManagementUrl: string = 'timesheet-management/timesheets/';

export type Timesheet = {
    month: number,
    sicknessDays: number,
    vacationDays: number,
    plannedTotalTime: number,
    spentTotalTime: string,
    percentage: number,
    employeesOvertime: string,
    status: "Complete" | "Incomplete" | "Pending"
}

export interface TimesheetEmployee {
    userUUID: string
    fullName: string
    plannedTime: string
    spentTime: string
    overtime: string
    prevOvertime: string
    attendanceTotalHours: string
    vacationTotalHours: string
    sickNoteTotalHours: string
    vacationTotalDays: string
    sickNoteTotalDays: string
    status: status
}

export interface TimesheetEmployeeDetail {
    userUUID: string
    fullName: string
    date: string
    startTime: string
    endTime: string
    duration: string
    type: "attendance" | "sickLeave" | "breakTime" | "vacation"
}

export type TimesheetEmployeesRequest = {
    recordsTotal: number
    recordsFiltered: number
    next: string
    previous: string
    data: TimesheetEmployee[]
}


export type TimesheetEmployeeDetailsRequest = {
    recordsTotal: number
    recordsFiltered: number
    next: string
    previous: string
    data: TimesheetEmployeeDetail[]
}

export type TimesheetEmployeesPayload = {
    start: number
    length: number
    search?: string
    ordering?: string
}


export type TimesheetDailyTrack = {
    userUUID: string
    fullName: string
    plannedTime: string
    spentTime: string
    overtime: string
    vacationTotalDays: string
    sickNoteTotalDays: string
    attendanceTotalHours: string
    vacationTotalHours: string
    sickNoteTotalHours: string
    status: string
}

export type TimesheetDailyTrackDetail = {
    userUUID: string
    fullName: string
    date: string
    startTime: string
    endTime: string
    duration: string
    type: "attendance" | "sickLeave" | "breakTime" | "vacation"
}


export type TimesheetDailyTracksRequest = {
    recordsTotal: number
    recordsFiltered: number
    next: string
    previous: string
    data: TimesheetDailyTrack[]
}


export type TimesheetDailyTrackDetailsRequest = {
    recordsTotal: number
    recordsFiltered: number
    next: string
    previous: string
    data: TimesheetDailyTrackDetail[]
}

export type TimesheetDailyTracksPayload = {
    start: number
    length: number
    search?: string
    ordering?: string
}


export const useFetchTimesheetEmployeesDataTable = (payload: TimesheetEmployeesPayload, month: number, year: number) => {
    const [shouldReFetch, reFetchTimesheetEmployees] = useState(false);
    const [timesheetEmployeesRequest, setData] = useState<TimesheetEmployeesRequest>({
        recordsTotal: 0,
        recordsFiltered: 0,
        next: "",
        previous: "",
        data: []
    });
    const [timesheetEmployeesLoading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const axiosInstance = useAxiosInstance();
    const permissionContext = useContext(PermissionContext);

    const fetchEmployeesData = (signal: AbortSignal) => {
        setLoading(true);
        axiosInstance.get(timesheetManagementUrl + `${year}/${month}/employees/`,{
            params: payload,
            signal: signal
        })
            .then((res) => {
                setData(res.data);
            })
            .catch((err) => {
                setError(err);
            })
            .finally(() => {
                setLoading(false);
                reFetchTimesheetEmployees(false);
            })
    }

    useEffect( () => {
        const controller = new AbortController();
        const signal = controller.signal;

        fetchEmployeesData(signal);

        return () => {
            controller.abort()
        }
    }, [payload, month, year, shouldReFetch, permissionContext.isAdmin])

    return { timesheetEmployeesRequest, timesheetEmployeesLoading, error, reFetchTimesheetEmployees };
}


export const useFetchTimesheetEmployeeDetailsDataTable = (payload: TimesheetEmployeesPayload, month: number, year: number) => {
    const [shouldReFetch, reFetchTimesheetEmployeeDetails] = useState(false);
    const [timesheetEmployeeDetailsRequest, setData] = useState<TimesheetEmployeeDetailsRequest>({
        recordsTotal: 0,
        recordsFiltered: 0,
        next: "",
        previous: "",
        data: []
    });
    const [timesheetEmployeeDetailsLoading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const axiosInstance = useAxiosInstance();
    const permissionContext = useContext(PermissionContext);

    const fetchEmployeeDetailsData = (signal: AbortSignal) => {
        setLoading(true);
        axiosInstance.get(timesheetManagementUrl + `${year}/${month}/employees/details/`,{
            params: payload,
            signal: signal
        })
            .then((res) => {
                setData(res.data);
            })
            .catch((err) => {
                setError(err);
            })
            .finally(() => {
                setLoading(false);
                reFetchTimesheetEmployeeDetails(false);
            })
    }

    useEffect( () => {
        const controller = new AbortController();
        const signal = controller.signal;

        fetchEmployeeDetailsData(signal);

        return () => {
            controller.abort()
        }
    }, [payload, month, year, shouldReFetch, permissionContext.isAdmin])

    return { timesheetEmployeeDetailsRequest, timesheetEmployeeDetailsLoading, error, reFetchTimesheetEmployeeDetails };
}



export const useFetchTimesheetDailyTracksDataTable = (payload: TimesheetDailyTracksPayload, month: number, year: number, day: number | null) => {
    const [shouldReFetch, reFetchTimesheetDailyTracks] = useState(false)
    const [timesheetDailyTracksRequest, setData] = useState<TimesheetDailyTracksRequest>({
        recordsTotal: 0,
        recordsFiltered: 0,
        next: "",
        previous: "",
        data: []
    });
    const [timesheetDailyTracksLoading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const axiosInstance = useAxiosInstance();
    const permissionContext = useContext(PermissionContext);

    const fetchDailyTracksData = (signal: AbortSignal) => {
        setLoading(true);
        axiosInstance.get(timesheetManagementUrl + `${year}/${month}/${day}/daily-tracks/`,{
            params: payload,
            signal: signal
        })
            .then((res) => {
                setData(res.data);
            })
            .catch((err) => {
                setError(err);
            })
            .finally(() => {
                setLoading(false);
                reFetchTimesheetDailyTracks(false);
            })
    }

    useEffect( () => {
        const controller = new AbortController();
        const signal = controller.signal;
        const isValidDay = day && day <= new Date(year, month, 0).getDate();

        if (isValidDay) {
            fetchDailyTracksData(signal);
        }

        return () => {
            controller.abort();
        }
    }, [payload, month, year, day, shouldReFetch, permissionContext.isAdmin])

    return { timesheetDailyTracksRequest, timesheetDailyTracksLoading, error, reFetchTimesheetDailyTracks };
}


// daily track details


export const useFetchTimesheetDailyTrackDetailsDataTable = (payload: TimesheetDailyTracksPayload, month: number, year: number, day: number | null) => {
    const [shouldReFetch, reFetchTimesheetDailyTrackDetails] = useState(false)
    const [timesheetDailyTrackDetailsRequest, setData] = useState<TimesheetDailyTrackDetailsRequest>({
        recordsTotal: 0,
        recordsFiltered: 0,
        next: "",
        previous: "",
        data: []
    });
    const [timesheetDailyTrackDetailsLoading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const axiosInstance = useAxiosInstance();
    const permissionContext = useContext(PermissionContext);

    const fetchDailyTrackDetailsData = (signal: AbortSignal) => {
        setLoading(true);
        axiosInstance.get(timesheetManagementUrl + `${year}/${month}/${day}/daily-tracks/details/`,{
            params: payload,
            signal: signal
        })
            .then((res) => {
                setData(res.data);
            })
            .catch((err) => {
                setError(err);
            })
            .finally(() => {
                setLoading(false);
                reFetchTimesheetDailyTrackDetails(false);
            })
    }

    useEffect( () => {
        const controller = new AbortController();
        const signal = controller.signal;
        const isValidDay = day && day <= new Date(year, month, 0).getDate();

        if (isValidDay) {
            fetchDailyTrackDetailsData(signal);
        }

        return () => {
            controller.abort();
        }
    }, [payload, month, year, day, shouldReFetch, permissionContext.isAdmin])

    return { timesheetDailyTrackDetailsRequest, timesheetDailyTrackDetailsLoading, error, reFetchTimesheetDailyTrackDetails };
}