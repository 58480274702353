import {
    TDayRecord,
    TFirstStepData, TPortionType,
    TSecondStepData,
    TStep,
    TVacationsFormSchema,
    TVacationType,
    TValidationStep
} from "./interfaces";
import {attendanceManagementUrl} from "../../index";
import {toast} from "../../../components/use-toast";
import {useAxiosInstance} from "../../../Core/utilities/AxiosInstance";
import {ServerErrorsType} from "../../../Core/functions/use-server-errors";
import {validationSteps} from "./constants";
import {useIntl} from "react-intl";
import moment from "moment";
import {UseFormReturn} from "react-hook-form";
import {vacation, vacationSubstitute} from "../../Controllers/vactions-controller";
import React, {Dispatch, SetStateAction, useState} from "react";



type useCreateVacationsProps = {
    setApiErrors: React.Dispatch<React.SetStateAction<ServerErrorsType>>
    tab: TValidationStep
    setTab: React.Dispatch<React.SetStateAction<TValidationStep>>
    steps: TStep<TFirstStepData | TSecondStepData | {}>[]
    setSteps: React.Dispatch<React.SetStateAction<TStep<{} | TFirstStepData | TSecondStepData>[]>>
    reFetch: React.Dispatch<React.SetStateAction<boolean>>
    closeModal: React.MutableRefObject<HTMLElement | undefined>
    form: UseFormReturn<TVacationsFormSchema, any, undefined>
    substitutes: vacationSubstitute[],
    setModalWidth: React.Dispatch<React.SetStateAction<string>>
}

export const useCreateVacations = ({
    setApiErrors,
    tab,
    setTab,
    steps,
    setSteps,
    reFetch,
    closeModal,
    form,
    substitutes,
    setModalWidth
} : useCreateVacationsProps) => {
    const intl = useIntl()
    const axiosInstance = useAxiosInstance()

    const validateStep = (url: string, data: TFirstStepData | TSecondStepData) => {
        setApiErrors({})

        let currentStepIndex = validationSteps.findIndex(elem => elem === tab);
        let currentStep = validationSteps[currentStepIndex];
        let nextStep = validationSteps[currentStepIndex + 1];
        let nextStepIndex = currentStepIndex + 1;

        axiosInstance.post(attendanceManagementUrl + url, data)
            .then((res) => {
                if (currentStep === "preview") {
                    setApiErrors({})
                    reFetch(true);
                    closeModal.current?.click();
                    toast({
                        title: intl.formatMessage({id: "toast.success", defaultMessage: "Great!"}),
                        description: intl.formatMessage({id: "toast.success.actionCompleted", defaultMessage: "Your action was completed successfully."})
                    });
                } else {
                    setTab(nextStep);
                    setSteps(prevState => prevState.map(step => {
                        if (step.tab === nextStep) {
                            return {
                                ...step,
                                state: true
                            }
                        }
                        if (step.tab === currentStep) {
                            return {
                                ...step,
                                data: res.data
                            }
                        }
                        return step
                    }))
                }
            })
            .catch((err) => {
                let errors = err.response?.data
                if (errors.hasOwnProperty('records')) {
                    errors.records.forEach((recError: {type: string}, index: number) => {
                        if (recError.type) {
                            form.setError(`records.${index}.type`, {message: "Missing"})
                        }
                    })
                } else {
                    setApiErrors(err.response?.data)
                }
                setSteps(prevState => prevState.map((step, index) => {
                    if (index >= nextStepIndex) {
                        return {
                            ...step,
                            state: false
                        }
                    }
                    return step
                }))
            })
    }

    const handleFirstStep = () => {
        const data = form.getValues()

        if (!data.userUUID) {
            form.setError('userUUID', {message: "Select an employee"})
        } else {
            const firstStepParams: TFirstStepData = {
                ...(data.dateFrom && {dateFrom: moment(data.dateFrom).format('YYYY-MM-DD')}),
                ...(data.dateTo && {dateTo: moment(data.dateTo).format('YYYY-MM-DD')}),
                ...(data.start && {startTime: moment(data.start).format('HH:mm')}),
                ...(data.end && {endTime: moment(data.end).format('HH:mm')}),
                ignoreWeekends: data.ignoreWeekends,
            }
            validateStep(`employees/${data.userUUID}/vacations/step-one/`, firstStepParams)
        }
    }

    const handleSecondStep = () => {
        const data = form.getValues()
        const firstStepData = steps.find(elem => elem.tab === 'step_1')?.data as TSecondStepData
        const secondStepParams: TSecondStepData = {
            ...firstStepData,
            records: firstStepData.records.filter(record => !record.placeholder).map((record, index) => {
                let substitute = substitutes.find(elem => elem.userUUID === data.substituteUUID)
                let noSubsitute = !record.substitute?.userUUID
                let recordFromForm = data.records?.find((nestedIndex) => index === nestedIndex)

                return {
                    ...record,
                    ...(noSubsitute && substitute && {
                        substitute: {
                            userUUID: substitute.userUUID,
                            fullName: substitute.fullName
                        }
                    }),
                    type: record.type ? record.type : data.requestType ? data.requestType as TVacationType : null,
                    ...(recordFromForm?.startTime && {startTime: recordFromForm.startTime}),
                    ...(recordFromForm?.endTime && {endTime: recordFromForm.endTime}),
                }
            })
        }
        validateStep(`employees/${data.userUUID}/vacations/step-two/`, secondStepParams)
    }

    const toggleRecord = (records: TDayRecord[], recordIndex: number) => {
        setSteps(prevState => {
            return prevState.map((step) => {
                if (step.tab === "step_1") {
                    return {
                        ...step,
                        data: {
                            ...step.data,
                            records: records?.map((record,index) => {
                                if (index === recordIndex) {
                                    return {
                                        ...record,
                                        placeholder: !record?.placeholder
                                    }
                                }
                                return record
                            })
                        }
                    }
                }
                return step
            })
        })
    }

    const changeSubstitute = (records: TDayRecord[], recordIndex: number, substituteUUID: string) => {
        setSteps(prevState => {
            return prevState.map((step) => {
                if (step.tab === "step_1") {
                    return {
                        ...step,
                        data: {
                            ...step.data,
                            records: records?.map((record,index) => {
                                if (index === recordIndex) {
                                    return {
                                        ...record,
                                        substitute: {
                                            userUUID: substituteUUID,
                                            fullName: substitutes.find(elem => elem.userUUID === substituteUUID)?.fullName
                                        }
                                    }
                                }
                                return record
                            })
                        }
                    }
                }
                return step
            })
        })
    }

    const changeType = (records: TDayRecord[], recordIndex: number, type: TVacationType | undefined) => {
        setSteps(prevState => {
            return prevState.map((step) => {
                if (step.tab === "step_1") {
                    return {
                        ...step,
                        data: {
                            ...step.data,
                            records: records?.map((record,index) => {
                                if (index === recordIndex) {
                                    return {...record, type}
                                }
                                return record
                            })
                        }
                    }
                }
                return step
            })
        })
    }

    const changePortion = (records: TDayRecord[], recordIndex: number, portion: TPortionType | undefined) => {
        setSteps(prevState => {
            return prevState.map((step) => {
                if (step.tab === "step_1") {
                    return {
                        ...step,
                        data: {
                            ...step.data,
                            records: records?.map((record,index) => {
                                if (index === recordIndex) {
                                    return {...record, portion}
                                }
                                return record
                            })
                        }
                    }
                }
                return step
            })
        })
    }

    const changeTime = (records: TDayRecord[], recordIndex: number, field: string, value: string | undefined) => {
        setSteps(prevState => {
            return prevState.map((step) => {
                if (step.tab === "step_1") {
                    return {
                        ...step,
                        data: {
                            ...step.data,
                            records: records?.map((record,index) => {
                                if (index === recordIndex) {
                                    return {
                                        ...record,
                                        [field]: value
                                    }
                                }
                                return record
                            })
                        }
                    }
                }
                return step
            })
        })
    }

    return {validateStep, handleFirstStep, handleSecondStep, toggleRecord, changeSubstitute, changeType, changePortion, changeTime}
}



type useEditVacationsProps = {
    setApiErrors: React.Dispatch<React.SetStateAction<ServerErrorsType>>
    reFetch: React.Dispatch<React.SetStateAction<boolean>>
    closeModal: React.MutableRefObject<HTMLElement | undefined>
    form: UseFormReturn<TVacationsFormSchema, any, undefined>
    substitutes: vacationSubstitute[]
    vacation: vacation | undefined,
    setVacation: Dispatch<SetStateAction<vacation | undefined>>
}

export const useEditVacations = ({
    setApiErrors,
    reFetch,
    closeModal,
    substitutes,
    vacation,
    setVacation,
    form
} : useEditVacationsProps) => {
    const intl = useIntl()
    const axiosInstance = useAxiosInstance()


    const updateVacations = () => {
        let formData = form.getValues();

        let params = {
            ...vacation,
            ...(formData.sickNoteSubmitted && {sickNoteSubmitted: formData.sickNoteSubmitted === 'yes'}),
            records: vacation?.records.filter((record) => !record.placeholder).map((record) => {
                let updatedRecord = record
                delete updatedRecord.placeholder

                return updatedRecord
            })
        }

        axiosInstance.put(attendanceManagementUrl + `employees/${vacation?.user.userUUID}/vacations/${vacation?.pk}/`, params)
            .then((res) => {
                setApiErrors({})
                reFetch(true);
                closeModal.current?.click();
                toast({
                    title: intl.formatMessage({id: "toast.success", defaultMessage: "Great!"}),
                    description: intl.formatMessage({id: "toast.success.updatedSuccessfully", defaultMessage: "The update was completed successfully."})
                })
            })
            .catch((err) => {
                let errors = err.response?.data
                if (errors.hasOwnProperty('records')) {
                    errors.records.forEach((recError: {type: string}, index: number) => {
                        if (recError.type) {
                            form.setError(`records.${index}.type`, {message: "Missing"})
                        }
                    })
                } else {
                    setApiErrors(err.response?.data)
                }
            })

    }


    const toggleRecord = (recordIndex: number) => {
        setVacation((prevState) => {
            if (prevState) {
                return {
                    ...prevState,
                    records: prevState.records.map((record, index) => {
                        if (index === recordIndex) {
                            return {
                                ...record,
                                placeholder: !record?.placeholder
                            }
                        }
                        return record
                    })
                }
            }
        })
    }

    const changeSubstitute = (recordIndex: number, substituteUUID: string) => {
        setVacation((prevState) => {
            if (prevState) {
                return {
                    ...prevState,
                    records: prevState.records.map((record, index) => {
                        if (index === recordIndex) {
                            return {
                                ...record,
                                substitute: {
                                    userUUID: substituteUUID,
                                    fullName: substitutes.find(elem => elem.userUUID === substituteUUID)?.fullName ?? ""
                                }
                            }
                        }
                        return record
                    })
                }
            }
        })
    }


    const changeType = (recordIndex: number, type: TVacationType | undefined) => {
        setVacation(prevState => {
            if (prevState)
            return  {
                ...prevState,
                records: prevState.records.map((record,index) => {
                    if (index === recordIndex) {
                        return {...record, type}
                    }
                    return record
                })
            }
        })
    }

    const changePortion = (recordIndex: number, portion: TPortionType | undefined) => {
        setVacation(prevState => {
            if (prevState)
            return  {
                ...prevState,
                records: prevState.records.map((record,index) => {
                    if (index === recordIndex) {
                        return {...record, portion}
                    }
                    return record
                })
            }
        })
    }

    const changeTime = (recordIndex: number, field: string, value: string | undefined) => {
        setVacation(prevState => {
            if (prevState)
            return  {
                ...prevState,
                records: prevState.records.map((record,index) => {
                    if (index === recordIndex) {
                        return {
                            ...record,
                            [field]: value
                        }
                    }
                    return record
                })
            }
        })
    }

    return {updateVacations, toggleRecord, changeSubstitute, changeType, changePortion, changeTime}
}