import {FormControl, FormField, FormItem, FormLabel, FormMessage} from "../../../components/form";
import {FormattedMessage, useIntl} from "react-intl";
import SearchInput from "../../../Core/components/search-input";
import {DatePicker} from "../../../components/date-picker";
import {Input} from "../../../components/input";
import {cn} from "../../../lib/utils";
import moment from "moment";
import {Select, SelectContent, SelectItem, SelectTrigger, SelectValue} from "../../../components/select";
import {Trash} from "@phosphor-icons/react";
import React, {useEffect} from "react";
import {UseFormReturn} from "react-hook-form";
import {
    TDayRecord,
    TFirstStepData,
    TPortionType,
    TSecondStepData,
    TStep,
    TVacationsFormSchema,
    TVacationType
} from "./interfaces";
import {Employee, vacation, vacationSubstitute} from "../../Controllers/vactions-controller";
import "./index.css"
import {nullOrUndefined} from "../../../Core/constants/variables";
import {Table, TableBody, TableCell, TableHead, TableHeader, TableRow} from "../../../components/table";
import DetailViewCard from "../../../Core/components/detail-view-card";
import {portionTypes, vacationTypes} from "./constants";
import {Checkbox} from "../../../components/checkbox";
import {TimePicker} from "../../../components/date-time-picker/time-picker";
import {TimeValue} from "react-aria";

type FirstStepProps = {
    form: UseFormReturn<TVacationsFormSchema>
    employees: Employee[]
    substitutes: vacationSubstitute[]
    duration: string
    dayDuration: {duration: moment.Duration, durationString: string}
    employeeName?: string
}

export const VacationsFirstStep: React.FC<FirstStepProps> = ({
    form,
    employees,
    substitutes,
    duration,
    dayDuration,
    employeeName
}) => {
    const intl = useIntl()

    return (
        <div className="flex flex-col gap-4 w-[500px] max-h-[54vh] no-scrollbar overflow-y-scroll">
            <FormField
                control={form.control}
                name="userUUID"
                render={({field}) => (
                    <FormItem>
                        <FormLabel>
                            <FormattedMessage
                                id={"attendance.vacations.employee"}
                                defaultMessage={"Employee"}
                            />
                        </FormLabel>
                        <SearchInput
                            title={intl.formatMessage({
                                id: "attendance.vacations.employeePlaceholder",
                                defaultMessage: "Select an employee"
                            })}
                            value={field.value}
                            values={employees?.map((employee) => {
                                return {
                                    key: employee.fullName,
                                    value: employee.userUUID
                                }
                            })}
                            onChange={field.onChange}
                        />
                        <FormMessage/>
                    </FormItem>
                )}
            />
            <div className="flex flex-row gap-4">
                <FormField
                    control={form.control}
                    name="dateFrom"
                    render={({field}) => (
                        <FormItem className="grow flex flex-col">
                            <FormLabel>
                                <FormattedMessage
                                    id={"attendance.vacations.dateFrom"}
                                    defaultMessage={"Date From"}
                                />
                            </FormLabel>
                            <DatePicker date={field.value} setDate={field.onChange}/>
                            <FormMessage/>
                        </FormItem>
                    )}
                />
                <FormField
                    control={form.control}
                    name="dateTo"
                    render={({field}) => (
                        <FormItem className="grow flex flex-col">
                            <FormLabel>
                                <FormattedMessage
                                    id={"attendance.vacations.dateTo"}
                                    defaultMessage={"Date To"}
                                />
                            </FormLabel>
                            <DatePicker date={field.value} setDate={field.onChange}/>
                            <FormMessage/>
                        </FormItem>
                    )}
                />
            </div>

            <FormField
                control={form.control}
                name="ignoreWeekends"
                render={({field}) => (
                    <FormItem>
                        <div className={"flex space-x-2 items-center"}>
                            <FormControl>
                                <Checkbox checked={field.value} onCheckedChange={field.onChange}/>
                            </FormControl>
                            <FormLabel>
                                <FormattedMessage
                                    id={"attendance.vacations.without_weekends"}
                                    defaultMessage={"Without weekends"}
                                />
                            </FormLabel>
                        </div>
                        <FormMessage/>
                    </FormItem>
                )}
            />

            <div className="flex flex-col gap-2">
                <FormLabel>
                    <FormattedMessage
                        id={"attendance.vacations.duration"}
                        defaultMessage={"Duration"}
                    />
                </FormLabel>
                <Input value={`${duration} days`} disabled={true}/>
            </div>


            <div className="flex flex-row gap-4">
                <FormField
                    control={form.control}
                    name="start"
                    render={({field}) => (
                        <FormItem className="w-1/2 flex flex-col p-1">
                            <FormLabel>
                                <FormattedMessage
                                    id={"attendance.vacations.start"}
                                    defaultMessage={"Starts at"}
                                />
                            </FormLabel>
                            <FormControl>
                                <TimePicker value={field.value} onChange={field.onChange}/>
                            </FormControl>
                            <FormMessage/>
                        </FormItem>
                    )}
                />
                <FormField
                    control={form.control}
                    name="end"
                    render={({field}) => (
                        <FormItem className="w-1/2 flex flex-col p-1">
                            <FormLabel>
                                <FormattedMessage
                                    id={"attendance.vacations.end"}
                                    defaultMessage={"Ends at"}
                                />
                            </FormLabel>
                            <FormControl>
                                <TimePicker value={field.value} onChange={field.onChange}/>
                            </FormControl>
                            <FormMessage/>
                        </FormItem>
                    )}
                />
            </div>
            <div className="flex flex-col gap-2">
                <FormLabel>
                    <FormattedMessage
                        id={"attendance.vacations.time_duration"}
                        defaultMessage={"Time Duration"}
                    />
                </FormLabel>
                <Input value={dayDuration.durationString} disabled={true}/>
            </div>

            <div className="flex flex-row gap-4">

                <FormField
                    control={form.control}
                    name="substituteUUID"
                    render={({field}) => (
                        <FormItem className="w-1/2">
                            <FormLabel>
                                <FormattedMessage
                                    id={"attendance.vacations.substitute"}
                                    defaultMessage={"Substitute"}
                                />
                            </FormLabel>
                            <SearchInput
                                title={intl.formatMessage({
                                    id: "attendance.vacations.substitutePlaceholder",
                                    defaultMessage: "Select a substitute"
                                })}
                                value={field.value}
                                values={substitutes?.map((user) => {
                                    return {
                                        key: user.fullName,
                                        value: user.userUUID
                                    }
                                })}
                                onChange={field.onChange}
                                isDisabled={!employeeName?.length}
                                fallbackSentence={"Pick an employee first."}
                            />
                            <FormMessage/>
                        </FormItem>
                    )}
                />

                <FormField
                    control={form.control}
                    name="requestType"
                    render={({field}) => (
                        <FormItem className="w-1/2">
                            <FormLabel>
                                <FormattedMessage
                                    id={"attendance.vacations.type"}
                                    defaultMessage={"Type"}
                                />
                            </FormLabel>
                            <SearchInput
                                title={intl.formatMessage({
                                    id: "attendance.vacations.typePlaceholder",
                                    defaultMessage: "Select a type"
                                })}
                                value={field.value}
                                values={vacationTypes?.map(({key, value}) => {
                                    return {key: intl.formatMessage({id: `attendance.vacations.${value}`, defaultMessage: key}), value}
                                })}
                                onChange={field.onChange}
                            />
                            <FormMessage/>
                        </FormItem>
                    )}
                />
            </div>
        </div>
    )
}

type SecondStepProps = {
    form: UseFormReturn<TVacationsFormSchema, any, undefined>
    data?: {dateFrom: string, dateTo: string, records: TDayRecord[]}
    substitutes: vacationSubstitute[]
    changeSubstitute: (recordIndex: number, substituteUUID: string) => void
    changeType: (recordIndex: number, type: TVacationType | undefined) => void
    changePortion: (recordIndex: number, portion: TPortionType | undefined) => void
    toggleRecord: (recordIndex: number) => void
    changeTime: (recordIndex: number, field: string, value: string | undefined) => void
    employeeName?: string
}

export const VacationsSecondStep: React.FC<SecondStepProps> = (
    {
        form,
        data,
        changeType,
        substitutes,
        changeSubstitute,
        changePortion,
        toggleRecord,
        employeeName,
        changeTime
    }
) => {
    const intl = useIntl()
    const substituteUUID = form.getValues('substituteUUID')
    const vacationType = form.getValues('requestType')

    return (
        <div className={"flex flex-col space-y-4"}>
            <DetailViewCard rows={[
                {
                    name: intl.formatMessage({id: "attendance.vacations.employee", defaultMessage: "Employee"}),
                    value: employeeName ?? nullOrUndefined
                },
                {
                    name: intl.formatMessage({id: "attendance.vacations.dateFrom", defaultMessage: "Date From"}),
                    value: form.getValues('dateFrom') ? moment(form.getValues('dateFrom')).format('ddd DD MMM YYYY') : nullOrUndefined
                },
                {
                    name: intl.formatMessage({id: "attendance.vacations.dateTo", defaultMessage: "Date To"}),
                    value: form.getValues('dateTo') ? moment(form.getValues('dateTo')).format('ddd DD MMM YYYY') : nullOrUndefined
                }
            ]}/>

            {data?.records ? (
                <div className="border rounded-md max-h-[32vh] overflow-scroll">
                    <Table className="border-none">
                        <TableHeader>
                            <TableRow>
                                <TableHead>
                                    <FormattedMessage
                                        id={"attendance.vacations.day"}
                                        defaultMessage={"Day"}
                                    />
                                </TableHead>
                                <TableHead>
                                    <FormattedMessage
                                        id={"attendance.vacations.substitute"}
                                        defaultMessage={"Substitute"}
                                    />
                                </TableHead>
                                <TableHead>
                                    <FormattedMessage
                                        id={"attendance.vacations.type"}
                                        defaultMessage={"Type"}
                                    />
                                </TableHead>
                                <TableHead>
                                    <FormattedMessage
                                        id={"attendance.vacations.portion"}
                                        defaultMessage={"Portion"}
                                    />
                                </TableHead>
                                <TableHead>
                                    <FormattedMessage
                                        id={"attendance.vacations.start"}
                                        defaultMessage={"Start"}
                                    />
                                </TableHead>
                                <TableHead>
                                    <FormattedMessage
                                        id={"attendance.vacations.end"}
                                        defaultMessage={"end"}
                                    />
                                </TableHead>
                                <TableHead>
                                    <FormattedMessage
                                        id={"attendance.vacations.action"}
                                        defaultMessage={"Action"}
                                    />
                                </TableHead>
                            </TableRow>
                        </TableHeader>
                        <TableBody>
                            {data.records.length ? (
                                data.records?.map((record,index) => {
                                    return <TableRow className={cn(
                                        record?.placeholder && "strikeout"
                                    )}>
                                        <TableCell className="bg-white min-w-[10rem] vacation-row-child">
                                            {moment(record.date).format("ddd DD MMM YYYY")}
                                        </TableCell>
                                        <TableCell className="bg-white vacation-row-child">
                                            <Select defaultValue={record.substitute?.userUUID ? record.substitute?.userUUID : substituteUUID ? substituteUUID : undefined} onValueChange={(value) => {
                                                changeSubstitute(index, value)
                                            }}>
                                                <SelectTrigger>
                                                    <SelectValue placeholder={
                                                            intl.formatMessage({
                                                                id: "attendance.vacations.substitutePlaceholder",
                                                                defaultMessage: "Select a substitute"
                                                            })
                                                        }
                                                    />
                                                </SelectTrigger>
                                                <SelectContent>
                                                    {substitutes.map((substitute) => (
                                                        <SelectItem value={substitute.userUUID}>{substitute.fullName}</SelectItem>
                                                    ))}
                                                </SelectContent>
                                            </Select>
                                        </TableCell>
                                        <TableCell className="bg-white vacation-row-child">
                                            <FormField control={form.control} name={`records.${index}.type`} render={({ field, fieldState, formState, }) => (
                                                <FormItem>
                                                    <Select defaultValue={record?.type ? record.type : vacationType ? vacationType : undefined} onValueChange={(value: TVacationType) => {
                                                        changeType(index, value)
                                                    }}>
                                                        <SelectTrigger className={cn(fieldState.error?.message ? "ring-1 ring-offset-2 ring-red-500" : "")}>
                                                            <SelectValue placeholder={
                                                                    intl.formatMessage({
                                                                        id: "attendance.vacations.typePlaceholder",
                                                                        defaultMessage: "Select a type"
                                                                    })
                                                                }
                                                            />
                                                        </SelectTrigger>
                                                        <SelectContent>
                                                            {vacationTypes.map(({key, value}) => (
                                                                <SelectItem value={value}>{intl.formatMessage({id: `attendance.vacations.${value}`, defaultMessage: key})}</SelectItem>
                                                            ))}
                                                        </SelectContent>
                                                    </Select>
                                                </FormItem>
                                            )}/>
                                        </TableCell>
                                        <TableCell className="bg-white vacation-row-child">
                                            <Select defaultValue={record?.portion} onValueChange={(value: TPortionType) => {
                                                changePortion(index, value)
                                            }}>
                                                <SelectTrigger>
                                                    <SelectValue placeholder={
                                                            intl.formatMessage({
                                                                id: "attendance.vacations.portionPlaceholder",
                                                                defaultMessage: "Select a portion"
                                                            })
                                                        }
                                                    />
                                                </SelectTrigger>
                                                <SelectContent>
                                                    <SelectItem value={"wholeDay"}>
                                                        <FormattedMessage id={"attendance.vacations.wholeDay"} defaultMessage={"Whole Day"}/>
                                                    </SelectItem>
                                                    <SelectItem value={"firstHalf"}>
                                                        <FormattedMessage id={"attendance.vacations.firstHalf"} defaultMessage={"First Half"}/>
                                                    </SelectItem>
                                                    <SelectItem value={"secondHalf"}>
                                                        <FormattedMessage id={"attendance.vacations.secondHalf"} defaultMessage={"Second Half"}/>
                                                    </SelectItem>
                                                </SelectContent>
                                            </Select>
                                        </TableCell>
                                        <TableCell className="bg-white vacation-row-child">
                                            <FormField
                                                control={form.control}
                                                name={`records.${index}.startTime`}
                                                render={({ field }) => (
                                                    <FormItem>
                                                        <FormControl>
                                                            <TimePicker
                                                                label={field.name}
                                                                value={(() => {
                                                                    let value = field.value ? moment(field.value, 'HH:mm:ss') : record.startTime ? moment(record.startTime, 'HH:mm:ss') : undefined
                                                                    return value ? {
                                                                        hour: value.get('hour'),
                                                                        minute: value.get('minute')
                                                                    } as TimeValue : undefined
                                                                })()}
                                                                onChange={(value) => {
                                                                    changeTime(index, 'startTime', value ? moment(value).format('HH:mm') : undefined)
                                                                    field.onChange(value)
                                                                }}
                                                                hideTimeZone
                                                            />
                                                        </FormControl>
                                                        <FormMessage/>
                                                    </FormItem>
                                                )}
                                            />
                                        </TableCell>
                                        <TableCell className="bg-white vacation-row-child">
                                            <FormField
                                                control={form.control}
                                                name={`records.${index}.endTime`}
                                                render={({ field }) => (
                                                    <FormItem>
                                                        <FormControl>
                                                            <TimePicker
                                                                label={field.name}
                                                                value={(() => {
                                                                    let value = field.value ? moment(field.value, 'HH:mm:ss') : record.endTime ? moment(record.endTime, 'HH:mm:ss') : undefined
                                                                    return value ? {
                                                                        hour: value.get('hour'),
                                                                        minute: value.get('minute')
                                                                    } as TimeValue : undefined
                                                                })()}
                                                                onChange={(value) => {
                                                                    changeTime(index, 'endTime', value ? moment(value).format('HH:mm') : undefined)
                                                                    field.onChange(value)
                                                                }}
                                                                hideTimeZone
                                                            />
                                                        </FormControl>
                                                        <FormMessage/>
                                                    </FormItem>
                                                )}
                                            />
                                        </TableCell>
                                        <TableCell className="bg-white vacation-row-child">
                                            <Trash
                                                color={cn(record?.placeholder ? "gray" : "red")}
                                                size={20}
                                                className="cursor-pointer"
                                                onClick={() => {
                                                    toggleRecord(index)
                                                }}
                                            />
                                        </TableCell>
                                    </TableRow>
                                })
                            ) : (
                                <TableRow>
                                    <TableCell colSpan={5}>
                                        <FormattedMessage id={"no_results"} defaultMessage={"No results found."}/>
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </div>
            ) : null}

            <div className="w-full text-right text-xs text-gray-500/50">
                <span>
                    {intl.formatMessage({id: "attendance.vacations.workingDays", defaultMessage: "Working Days"})}
                    {" "}
                    ( {data?.records.filter(elem => !elem.placeholder).length} / {data?.records.length} )
                </span>
            </div>

        </div>
    )
}

type PreviewProps = {
    form: UseFormReturn<TVacationsFormSchema, any, undefined>
    data?: {dateFrom: string, dateTo: string, records: TDayRecord[]}
    employeeName?: string
}

export const VacationsPreview: React.FC<PreviewProps> = (
    {
        form,
        data,
        employeeName
    }
) => {
    const intl = useIntl()

    return (
        <>
            <DetailViewCard rows={[
                {
                    name: intl.formatMessage({id: "attendance.vacations.employee", defaultMessage: "Employee"}),
                    value: employeeName ?? nullOrUndefined
                },
                {
                    name: intl.formatMessage({id: "attendance.vacations.dateFrom", defaultMessage: "Date From"}),
                    value: form.getValues('dateFrom') ? moment(form.getValues('dateFrom')).format('ddd DD MMM YYYY') : nullOrUndefined
                },
                {
                    name: intl.formatMessage({id: "attendance.vacations.dateTo", defaultMessage: "Date To"}),
                    value: form.getValues('dateTo') ? moment(form.getValues('dateTo')).format('ddd DD MMM YYYY') : nullOrUndefined
                }
            ]}/>

            {data ? (
                <div className="border rounded-md max-h-[32vh] overflow-y-scroll">
                    <Table className="border-none">
                        <TableHeader>
                            <TableRow>
                                <TableHead>
                                    <FormattedMessage
                                        id={"attendance.vacations.day"}
                                        defaultMessage={"Day"}
                                    />
                                </TableHead>
                                <TableHead>
                                    <FormattedMessage
                                        id={"attendance.vacations.substitute"}
                                        defaultMessage={"Substitute"}
                                    />
                                </TableHead>
                                <TableHead>
                                    <FormattedMessage
                                        id={"attendance.vacations.type"}
                                        defaultMessage={"Type"}
                                    />
                                </TableHead>
                                <TableHead>
                                    <FormattedMessage
                                        id={"attendance.vacations.portion"}
                                        defaultMessage={"Portion"}
                                    />
                                </TableHead>
                                <TableHead>
                                    <FormattedMessage
                                        id={"attendance.vacations.start"}
                                        defaultMessage={"Start"}
                                    />
                                </TableHead>
                                <TableHead>
                                    <FormattedMessage
                                        id={"attendance.vacations.end"}
                                        defaultMessage={"End"}
                                    />
                                </TableHead>
                            </TableRow>
                        </TableHeader>
                        <TableBody>
                            {data.records.length ? (
                                data.records?.map((record,index) => {
                                    return <TableRow>
                                        <TableCell className={"min-w-fit"}>{moment(record.date).format("ddd DD MMM YYYY")}</TableCell>
                                        <TableCell className={"min-w-fit"}>{record.substitute?.fullName ?? nullOrUndefined}</TableCell>
                                        <TableCell className={"min-w-fit"}>{(() => {
                                            let vacationType = vacationTypes.find(type => type.value === record?.type)

                                            if (!vacationType) return nullOrUndefined

                                            return intl.formatMessage({id: `attendance.vacations.${vacationType?.value}`, defaultMessage: vacationType?.key})
                                        })()}</TableCell>
                                        <TableCell className={"min-w-fit"}>{(() => {
                                            let portionType = portionTypes.find(type => type.value === record?.portion)

                                            if (!portionType) return nullOrUndefined

                                            return intl.formatMessage({id: `attendance.vacations.${portionType?.value}`, defaultMessage: portionType?.key})
                                        })()}</TableCell>
                                        <TableCell className={"min-w-fit"}>{record?.startTime ? moment(record.startTime, 'HH:mm:ss').format('HH:mm') : nullOrUndefined}</TableCell>
                                        <TableCell className={"min-w-fit"}>{record?.endTime ? moment(record.endTime, 'HH:mm:ss').format('HH:mm') : nullOrUndefined}</TableCell>
                                    </TableRow>
                                })
                            ) : (
                                <TableRow>
                                    <TableCell colSpan={4}>
                                        <FormattedMessage id={"no_results"} defaultMessage={"No results found."}/>
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>

                </div>
            ) : null}
        </>
    )
}


type VacationsEditWorkingDaysProps = {
    form: UseFormReturn<TVacationsFormSchema, any, undefined>
    substitutes: vacationSubstitute[]
    changeSubstitute: (recordIndex: number, substituteUUID: string) => void
    changeType: (recordIndex: number, type: TVacationType) => void
    changePortion: (recordIndex: number, portion: TPortionType | undefined) => void
    changeTime: (recordIndex: number, field: string, value: string | undefined) => void
    toggleRecord?: (recordIndex: number) => void
    vacation?: vacation
}


export const VacationsEditWorkingDays: React.FC<VacationsEditWorkingDaysProps> = (
    {
        form,
        substitutes,
        changeType,
        changeSubstitute,
        changePortion,
        toggleRecord,
        vacation,
        changeTime
    }
) => {
    const intl = useIntl()

    return (
        <>
            <DetailViewCard rows={[
                {
                    name: intl.formatMessage({id: "attendance.vacations.employeeName", defaultMessage: "Employee Name"}),
                    value: vacation?.userFullName ?? nullOrUndefined
                },
                {
                    name: intl.formatMessage({id: "attendance.vacations.dateFrom", defaultMessage: "Date From"}),
                    value: vacation?.dateFrom ? moment(vacation.dateFrom).format('ddd Do MMM YYYY') : nullOrUndefined
                },
                {
                    name: intl.formatMessage({id: "attendance.vacations.dateTo", defaultMessage: "Date To"}),
                    value: vacation?.dateTo ? moment(vacation.dateTo).format('ddd Do MMM YYYY') : nullOrUndefined
                }
            ]}/>

            {vacation?.records ? (
                <div className="border rounded-md max-h-[37vh] overflow-y-scroll">
                    <Table className="border-none">
                        <TableHeader>
                            <TableRow>
                                <TableHead>
                                    <FormattedMessage
                                        id={"attendance.vacations.day"}
                                        defaultMessage={"Day"}
                                    />
                                </TableHead>
                                <TableHead>
                                    <FormattedMessage
                                        id={"attendance.vacations.substitute"}
                                        defaultMessage={"Substitute"}
                                    />
                                </TableHead>
                                <TableHead>
                                    <FormattedMessage
                                        id={"attendance.vacations.type"}
                                        defaultMessage={"Type"}
                                    />
                                </TableHead>
                                <TableHead>
                                    <FormattedMessage
                                        id={"attendance.vacations.portion"}
                                        defaultMessage={"Portion"}
                                    />
                                </TableHead>
                                <TableHead>
                                    <FormattedMessage
                                        id={"attendance.vacations.start"}
                                        defaultMessage={"Start"}
                                    />
                                </TableHead>
                                <TableHead>
                                    <FormattedMessage
                                        id={"attendance.vacations.end"}
                                        defaultMessage={"end"}
                                    />
                                </TableHead>
                                <TableHead>
                                    <FormattedMessage
                                        id={"attendance.vacations.action"}
                                        defaultMessage={"Action"}
                                    />
                                </TableHead>
                            </TableRow>
                        </TableHeader>
                        <TableBody className="max-h-[20vh] overflow-y-scroll">
                            {vacation.records.length ? (
                                vacation.records?.map((record,index) => {
                                    return <TableRow className={cn(
                                        record?.placeholder && "strikeout"
                                    )}>
                                        <TableCell className="bg-white min-w-[10rem] vacation-row-child">
                                            <span>{moment(record.date).format("ddd Do MMM YYYY")}</span>
                                        </TableCell>
                                        <TableCell className="bg-white vacation-row-child">
                                            <Select defaultValue={record?.substitute?.userUUID} onValueChange={(value) => {
                                                if (changeSubstitute) changeSubstitute(index, value)
                                            }}>
                                                <SelectTrigger>
                                                    <SelectValue placeholder={
                                                            intl.formatMessage({
                                                                id: "attendance.vacations.substitutePlaceholder",
                                                                defaultMessage: "Select a substitute"
                                                            })
                                                        }
                                                    />
                                                </SelectTrigger>
                                                <SelectContent>
                                                    {substitutes.map((substitute) => (
                                                        <SelectItem value={substitute.userUUID}>{substitute.fullName}</SelectItem>
                                                    ))}
                                                </SelectContent>
                                            </Select>
                                        </TableCell>
                                        <TableCell className="bg-white vacation-row-child">
                                            <FormField control={form.control} name={`records.${index}.type`} render={({ field, fieldState, formState, }) => (
                                                <FormItem>
                                                    <Select defaultValue={record?.type ?? undefined} onValueChange={(value: TVacationType) => {
                                                        changeType(index, value)
                                                    }}>
                                                        <SelectTrigger className={cn(fieldState.error?.message ? "ring-1 ring-offset-2 ring-red-500" : "")}>
                                                            <SelectValue placeholder={
                                                                    intl.formatMessage({
                                                                        id: "attendance.vacations.typePlaceholder",
                                                                        defaultMessage: "Select a type"
                                                                    })
                                                                }
                                                            />
                                                        </SelectTrigger>
                                                        <SelectContent>
                                                            {vacationTypes.map(({key, value}) => (
                                                                <SelectItem value={value}>{intl.formatMessage({id: `attendance.vacations.${value}`, defaultMessage: key})}</SelectItem>
                                                            ))}
                                                        </SelectContent>
                                                    </Select>
                                                </FormItem>
                                            )}/>
                                        </TableCell>
                                        <TableCell className="bg-white vacation-row-child">
                                            <Select defaultValue={record?.portion ?? undefined} onValueChange={(value: TPortionType) => {
                                                changePortion(index, value)
                                            }}>
                                                <SelectTrigger>
                                                    <SelectValue placeholder={
                                                            intl.formatMessage({
                                                                id: "attendance.vacations.portionPlaceholder",
                                                                defaultMessage: "Select a portion"
                                                            })
                                                        }
                                                    />
                                                </SelectTrigger>
                                                <SelectContent>
                                                    <SelectItem value={"wholeDay"}>
                                                        <FormattedMessage id={"attendance.vacations.wholeDay"} defaultMessage={"Whole Day"}/>
                                                    </SelectItem>
                                                    <SelectItem value={"firstHalf"}>
                                                        <FormattedMessage id={"attendance.vacations.firstHalf"} defaultMessage={"First Half"}/>
                                                    </SelectItem>
                                                    <SelectItem value={"secondHalf"}>
                                                        <FormattedMessage id={"attendance.vacations.secondHalf"} defaultMessage={"Second Half"}/>
                                                    </SelectItem>
                                                </SelectContent>
                                            </Select>
                                        </TableCell>
                                        <TableCell className="bg-white vacation-row-child">
                                            <FormField
                                                control={form.control}
                                                name={`records.${index}.startTime`}
                                                render={({ field }) => (
                                                    <FormItem>
                                                        <FormControl>
                                                            <TimePicker
                                                                label={field.name}
                                                                value={(() => {
                                                                    let value = field.value ? moment(field.value, 'HH:mm:ss') : record.startTime ? moment(record.startTime, 'HH:mm:ss') : undefined
                                                                    return value ? {
                                                                        hour: value.get('hour'),
                                                                        minute: value.get('minute')
                                                                    } as TimeValue : undefined
                                                                })()}
                                                                onChange={(value) => {
                                                                    changeTime(index, 'startTime', value ? moment(value).format('HH:mm') : undefined)
                                                                    field.onChange(value)
                                                                }}
                                                                hideTimeZone
                                                            />
                                                        </FormControl>
                                                        <FormMessage/>
                                                    </FormItem>
                                                )}
                                            />
                                        </TableCell>
                                        <TableCell className="bg-white vacation-row-child">
                                            <FormField
                                                control={form.control}
                                                name={`records.${index}.endTime`}
                                                render={({ field }) => (
                                                    <FormItem>
                                                        <FormControl>
                                                            <TimePicker
                                                                label={field.name}
                                                                value={(() => {
                                                                    let value = field.value ? moment(field.value, 'HH:mm:ss') : record.endTime ? moment(record.endTime, 'HH:mm:ss') : undefined
                                                                    return value ? {
                                                                        hour: value.get('hour'),
                                                                        minute: value.get('minute')
                                                                    } as TimeValue : undefined
                                                                })()}
                                                                onChange={(value) => {
                                                                    changeTime(index, 'endTime', value ? moment(value).format('HH:mm') : undefined)
                                                                    field.onChange(value)
                                                                }}
                                                                hideTimeZone
                                                            />
                                                        </FormControl>
                                                        <FormMessage/>
                                                    </FormItem>
                                                )}
                                            />
                                        </TableCell>
                                        <TableCell className="bg-white vacation-row-child">
                                            <Trash
                                                color={cn(record?.placeholder ? "gray" : "red")}
                                                size={20}
                                                className="cursor-pointer"
                                                onClick={() => {
                                                    if (toggleRecord) toggleRecord(index)
                                                }}
                                            />
                                        </TableCell>
                                    </TableRow>
                                })
                            ) : (
                                <TableRow>
                                    <TableCell colSpan={5}>
                                        <FormattedMessage id={"no_results"} defaultMessage={"No results found."}/>
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>

                </div>
            ) : null}
        </>
    )
}