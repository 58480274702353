import React, {useContext, useEffect, useState} from "react";
import {Button} from "../../components/button";
import {
    employmentTypesPayload,
    useFetchEmploymentTypesDataTable
} from "../Controllers/employment-type-controller";
import EmploymentTypesDataTable from "../DataTables/employment-types-data-table";
import {FormEmploymentTypeCreate} from "../Forms/form-employment-type-create";
import {FormattedMessage, useIntl} from "react-intl";
import {MainTab, PageActions, PageEntrySchema} from "../../Core/layout/page-shape";
import {PermissionContext} from "../../Core/utilities/PermissionProvider";
import CompanyGuidelinesDataTable from "../DataTables/company-guidelines-data-table";
import {useFetchCompanyGuidelines} from "../Controllers/company-guidelines-controller";
import {CreateVacationRule} from "../Forms/company-guideline-forms";

const initialPayload = {
    start: 0,
    length:10,
    search: "",
    ordering: "",
}

const CompanyGuidlines: React.FC<PageEntrySchema> = ({name, pagesState}) => {
    const intl = useIntl();
    const [payload, setPayload] = useState<employmentTypesPayload>(initialPayload);
    const {
        dataRequest,
        loading,
        reFetch
    } = useFetchCompanyGuidelines(payload);
    const permissionContext = useContext(PermissionContext);


    useEffect(() => {
        let currPage = pagesState?.find(page => page.pageName === name);
        if (currPage && currPage.reFetch) {
            reFetch(true);
        }
    }, [pagesState]);

    return (
        <MainTab
            type="mainOnly"
            name={name}
            main={{
                title: intl.formatMessage({
                    id: "table.list",
                    defaultMessage: "List"
                }),
                content: <CompanyGuidelinesDataTable
                    request={dataRequest}
                    payload={payload}
                    setPayload={setPayload}
                    loading={loading}
                    reFetch={reFetch}
                />
            }}
            actions={[
                {
                    type: "modal",
                    button: <Button variant="taimDefault">
                        <FormattedMessage id="companyGuidelines.create" defaultMessage="Create Vacation Rule"/>
                    </Button>,
                    header: intl.formatMessage({
                        id: "companyGuidelines.create",
                        defaultMessage: "Create Vacation Rule"
                    }),
                    children: <CreateVacationRule reFetch={reFetch}/>
                }
            ].filter((elem) => {
                if (permissionContext.isAdmin) return elem
            }) as PageActions[]}
        />)
}

export default CompanyGuidlines;