import {ReactNode, useContext, useEffect, useState} from "react";
import {useAxiosInstance} from "../../Core/utilities/AxiosInstance";
import {status} from "../../Core/functions/status-variants";
import {Action} from "../../Core/Table/data-table-actions";
import {PermissionContext} from "../../Core/utilities/PermissionProvider";

export const timeCorrectionsUrl: string = 'attendance-management/time-corrections/';

export type TimeCorrection = {
    pk: string,
    userFullName: string
    status: status,
    timeStamp: {
        pk: string,
        checkInDatetime: string,
        checkOutDatetime: string,
        checkInLocation: {
            name: string,
            uuid: string
        },
        checkOutLocation: {
            name: string,
            uuid: string
        }
    },
    correctionCheckInDate: string,
    correctionCheckInTime: string,
    correctionCheckOutDate: string,
    correctionCheckOutTime: string,
    duration: string,
    correctionCheckInLocation: {
        name: string,
        uuid: string
    },
    correctionCheckOutLocation: {
        name: string,
        uuid: string
    },
    beforeCorrectionCheckInDate: string,
    beforeCorrectionCheckInTime: string,
    beforeCorrectionCheckOutDate: string,
    beforeCorrectionCheckOutTime: string,
    beforeDuration: string,
    beforeCorrectionCheckInLocation: {
        name: string,
        uuid: string
    },
    beforeCorrectionCheckOutLocation: {
        name: string,
        uuid: string
    }
    userIsActive?: boolean
}

export type TimeCorrectionsPayload = {
    start: number
    length: number
    search?: string
    ordering?: string
    "filter.status"?: string
    "filter.startDate"?: string
    "filter.endDate"?: string
}

export type TimeCorrectionsRequest = {
    recordsTotal: number
    recordsFiltered: number
    next: string
    previous: string
    data: TimeCorrection[]
}

export const useFetchTimeCorrectionsDataTable = (payload: TimeCorrectionsPayload) => {
    const [shouldReFetch, reFetchTimeCorrections] = useState(false)
    const [timeCorrectionsRequest, setData] = useState<TimeCorrectionsRequest>({
        recordsTotal: 0,
        recordsFiltered: 0,
        next: "",
        previous: "",
        data: []
    });
    const [loadingTimeCorrections, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const axiosInstance = useAxiosInstance();
    const permissionContext = useContext(PermissionContext);

    const fetchTimeCorrections = (signal: AbortSignal) => {
        setLoading(true);
        axiosInstance.get(timeCorrectionsUrl,{
            params: payload,
            signal
        })
            .then((res) => {
                setData(res.data);
            })
            .catch((err) => {
                setError(err);
            })
            .finally(() => {
                setLoading(false);
                reFetchTimeCorrections(false);
            })
    }

    useEffect( () => {
        const abortController = new AbortController()
        const signal = abortController.signal

        if (
            payload.hasOwnProperty("filter.startDate") &&
            payload.hasOwnProperty("filter.endDate")
        ) fetchTimeCorrections(signal);

        return () => {
            abortController.abort()
        }
    }, [payload, shouldReFetch, permissionContext.isAdmin])

    return { timeCorrectionsRequest, loadingTimeCorrections, error, reFetchTimeCorrections };
}

export interface TimeCorrectionFormatted extends TimeCorrection {
    rowID: string
    actions: Action[]
    statusBadge: ReactNode
}