import TimesheetMaster from "./PageSections/timesheet-master";
import TimesheetDetail from "./PageSections/timesheet-detail";
import {Select, SelectContent, SelectItem, SelectTrigger, SelectValue} from "components/select";
import {Card} from "components/card";
import React, {useContext, useEffect, useState} from "react";
import {years} from "Core/constants/date";
import {useAxiosInstance} from "Core/utilities/AxiosInstance";
import {Timesheet, timesheetManagementUrl} from "TimesheetManagement/Controllers/timesheet-controller";
import {FormattedMessage} from "react-intl";
import {isCurrentMonth} from "Core/functions/is-current-month";
import {PermissionContext} from "../Core/utilities/PermissionProvider";
import moment from "moment";

const TimesheetPage = () => {
    const [currMonth, setMonth] = useState<number>(moment().month() + 1);
    const [currYear, setYear] = useState<number>(moment().year());
    const [day, setDay] = useState<number | null>(null)
    const [timesheetAllMonthsDetails, setTimesheetAllMonthsDetails] = useState<Map<number, Timesheet>>(new Map())
    const [timesheetFirstMonthLoading, setTimesheetFirstMonthLoading] = useState(false)
    const [timesheetAllMonthsLoading, setTimesheetAllMonthsLoading] = useState(false)
    const axiosInstance = useAxiosInstance();
    const permissionContext = useContext(PermissionContext);

    const fetchMonthTimesheet = async (year: number, month: number, signal?: AbortSignal): Promise<Timesheet> => {

        try {
            const response = await axiosInstance.get(timesheetManagementUrl + `${year}/${month}/`, {
                signal
            })
            let output = {
                ...response.data,
                month
            }

            setTimesheetAllMonthsDetails(prevState => {
                let newState = prevState
                newState.set(output.month, output)
                return newState
            });
            return output
        } catch (err) {
            throw err
        }
    }

    useEffect(() => {
        const controller = new AbortController()
        const signal = controller.signal

        setTimesheetAllMonthsLoading(true)
        setTimesheetAllMonthsDetails(new Map())

        async function fetchMonthTimesheets(currYear: number) {
            let startMonth = currYear === moment().year() ? moment().month() : 11;

            if (startMonth || String(startMonth) === String(0)) {
                setTimesheetFirstMonthLoading(true)
                await fetchMonthTimesheet(currYear, startMonth + 1, signal)
                    .finally(() => {
                        setTimesheetFirstMonthLoading(false)
                    })
            }

            let promises = Array.from({length: startMonth}).map((_, i) => {
                return fetchMonthTimesheet(currYear, i + 1, signal)
            })
            await Promise.all(promises)
                .then(() => null)
                .catch(() => console.log('error'))
        }

        fetchMonthTimesheets(currYear)
            .finally(() => setTimesheetAllMonthsLoading(false))

        return () => {
            controller.abort()
        }
    }, [currYear, permissionContext.isAdmin]);


    useEffect(() => {
        if (isCurrentMonth(currMonth, currYear)) {
            let today = new Date().getDate();
            setDay(today);
        } else {
            setDay(1);
        }
    }, [currMonth]);

    useEffect(() => {
        let todaysMonth = moment().month();
        let todaysYear = moment().year();

        setTimesheetAllMonthsDetails(new Map())

        if (currYear === todaysYear) {
            setMonth(todaysMonth + 1);
        } else {
            setMonth(12);
        }
    }, [currYear]);

    return (
        <div className="gap-10">
            <span className="text-[#1b2436] text-[2rem] font-semibold leading-9">
                <FormattedMessage id="timesheetManagement.header" defaultMessage="Time Sheet Management"/>
            </span>
            <div className="grid grid-cols-6 gap-4 min-h-[50rem] items-start">
                <div className="col-span-6 flex flex-row justify-between items-end">
                    <span className="text-xl font-bold">
                        <FormattedMessage
                            id={"timesheetManagement.detail.month"}
                            defaultMessage={"Month"}
                        />
                    </span>
                    <Select
                        onValueChange={(value) => {
                            setYear(Number(value))
                        }}
                        disabled={timesheetAllMonthsLoading}
                    >
                        <SelectTrigger className="w-[8rem]">
                            <SelectValue placeholder={currYear}></SelectValue>
                        </SelectTrigger>
                        <SelectContent>
                            {years && years.map((year, index) => (
                                <SelectItem key={index} value={year.toString()}>{year}</SelectItem>
                            ))}
                        </SelectContent>
                    </Select>
                </div>
                <TimesheetDetail
                    currMonth={currMonth}
                    monthsDetails={timesheetAllMonthsDetails}
                    year={currYear}
                    loading={timesheetAllMonthsLoading}
                    setCurrMonth={setMonth}
                />
                <Card className="col-span-6 p-6">
                    <TimesheetMaster
                        month={currMonth}
                        year={currYear}
                        day={day}
                        setDay={setDay}
                        monthsLoading={timesheetFirstMonthLoading}
                        fetchMonthTimesheet={fetchMonthTimesheet}
                        setTimesheetAllMonthsDetails={setTimesheetAllMonthsDetails}
                    />
                </Card>
            </div>
        </div>
    )
}

export default TimesheetPage