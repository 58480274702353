"use client"

import * as React from "react"
import { ChevronLeft, ChevronRight } from "lucide-react"
import {DayPicker, useDayPicker, useNavigation} from "react-day-picker"

import { cn } from "../lib/utils"
import { buttonVariants } from "./button"
import {Select, SelectContent, SelectItem, SelectTrigger} from "./select";
import moment from "moment";

export type CalendarProps = React.ComponentProps<typeof DayPicker>

const SelectDropdown: React.FC<{
    name: string,
    value?: string | number | undefined,
    selectItems: {label: string, value: string}[],
    currentMonth: Date,
    goToMonth: (month: Date) => void
}> = ({name, value, selectItems, currentMonth, goToMonth}) => {
    return (
        <Select
            onValueChange={(v) => {
                let newDate = new Date(currentMonth)
                if (name === "months") {
                    newDate.setMonth(parseInt(v))
                } else if (name === "years") {
                    newDate.setFullYear(parseInt(v))
                }
                goToMonth(newDate)
            }}
            value={value?.toString()}
        >
            <SelectTrigger>{moment(currentMonth).format(name === "months" ? 'MMM' : 'YYYY')}</SelectTrigger>
            <SelectContent>
                {selectItems.map((selectItem) => (
                    <SelectItem value={selectItem.value}>{selectItem.label}</SelectItem>
                ))}
            </SelectContent>
        </Select>
    )
}

function Calendar({
  className,
  classNames,
  showOutsideDays = true,
  ...props
}: CalendarProps) {
  return (
    <DayPicker
      showOutsideDays={showOutsideDays}
      className={cn("p-3", className)}
      classNames={{
        months: "flex flex-col sm:flex-row space-y-4 sm:space-x-4 sm:space-y-0",
        month: "space-y-4",
        caption: "flex justify-center pt-1 relative items-center",
        caption_label: cn(
            "text-sm font-medium",
            (props.captionLayout && props.fromYear && props.toYear) && "hidden"
        ),
        nav: "space-x-1 flex items-center",
        nav_button: cn(
          buttonVariants({ variant: "outline" }),
          "h-7 w-7 bg-transparent p-0 opacity-50 hover:opacity-100"
        ),
        nav_button_previous: "absolute left-1",
        nav_button_next: "absolute right-1",
        table: "w-full border-collapse space-y-1",
        head_row: "flex",
        head_cell:
          "text-muted-foreground rounded-md w-9 font-normal text-[0.8rem]",
        row: "flex w-full mt-2",
        cell: "text-center text-sm p-0 relative [&:has([aria-selected])]:bg-accent first:[&:has([aria-selected])]:rounded-l-md last:[&:has([aria-selected])]:rounded-r-md focus-within:relative focus-within:z-20",
        day: cn(
          buttonVariants({ variant: "ghost" }),
          "h-9 w-9 p-0 font-normal aria-selected:opacity-100"
        ),
        day_selected:
          "bg-primary text-primary-foreground hover:bg-primary hover:text-primary-foreground focus:bg-primary focus:text-primary-foreground",
        day_today: "bg-accent text-accent-foreground",
        day_outside: "text-muted-foreground opacity-50",
        day_disabled: "text-muted-foreground opacity-50",
        day_range_middle:
          "aria-selected:bg-accent aria-selected:text-accent-foreground",
        day_hidden: "invisible",
        caption_dropdowns: "flex gap-1",
        ...classNames,
      }}
      components={{
        IconLeft: ({ ...props }) => <ChevronLeft className="h-4 w-4" />,
        IconRight: ({ ...props }) => <ChevronRight className="h-4 w-4" />,
        Dropdown: (props) => {
            const {
                fromYear,
                toYear,
                fromMonth,
                toMonth,
                fromDate,
                toDate
            } = useDayPicker();
            const {currentMonth,goToMonth} = useNavigation();
            if (props.name === "months") {
                let selectItems: {label: string, value: string}[] = Array.from({length: 12}).map((_,index) => ({
                    label: moment().set({ month: index }).format('MMM'),
                    value: index.toString()
                }))
                return <SelectDropdown
                    name={props.name}
                    value={props.value}
                    selectItems={selectItems}
                    currentMonth={currentMonth}
                    goToMonth={goToMonth}
                />
            } else if (props.name === "years") {
                const earliestYear =
                    fromYear || fromMonth?.getFullYear() || fromDate?.getFullYear()
                const latestYear =
                    toYear || toMonth?.getFullYear() || toDate?.getFullYear()

                if (!earliestYear || !latestYear) return null

                let selectItems: {label: string, value: string}[] = Array.from({length: (latestYear-earliestYear) + 1}).map((_,index) => ({
                    label: (earliestYear + index).toString(),
                    value: (earliestYear + index).toString(),
                }))
                return <SelectDropdown
                    name={props.name}
                    value={props.value}
                    selectItems={selectItems}
                    currentMonth={currentMonth}
                    goToMonth={goToMonth}
                />
            }
            return null
        }
      }}
      {...props}
    />
  )
}
Calendar.displayName = "Calendar"

export { Calendar }
