import * as React from "react"
import { addDays, format, subDays } from "date-fns"
import { Calendar as CalendarIcon } from "lucide-react"
import { cn } from "../lib/utils"
import { Button } from "./button"
import { Calendar } from "./calendar"
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "./popover"
import {FormattedMessage} from "react-intl";
import {enUS, de} from "date-fns/locale";
import {useContext} from "react";
import {Context} from "../Core/utilities/Wrapper";
import moment from "moment/moment";

interface DatePickerWithRangeProps extends React.HTMLAttributes<HTMLDivElement> {
    disabled?: boolean | Date
    date: any
    setDate: any
}

const setOtherZone = (date?: Date, timezone?: string) : Date => {
    const dateWithoutZone = moment(date).format("YYYY-MM-DDTHH:mm:ss.SSS")
    const otherZone = moment.tz(date, timezone ?? "Europe/Berlin").format("Z")
    const dateWithOtherZone = [dateWithoutZone, otherZone].join("")

    return new Date(dateWithOtherZone)
}

export function DatePickerWithRange({
  className,
  date,
  setDate,
  disabled,
}: DatePickerWithRangeProps) {
    const currDate = new Date();
    const context = useContext(Context);
    const local = context.language?.includes( "de") ? de : enUS;

    return (
    <div className={cn("grid gap-2", className)}>
      <Popover>
        <PopoverTrigger asChild>
          <Button
            id="date"
            variant={"outline"}
            className={cn(
              "w-fit justify-start text-left font-normal",
              !date && "text-muted-foreground"
            )}
            onClick={() => {
                if (!date) {
                    setDate({
                        from: disabled ? subDays(currDate, 20) : currDate,
                        to: disabled ? currDate : addDays(currDate, 20),
                    })
                }
            }}
          >
            <CalendarIcon className="mr-2 h-4 w-4" />
            {date?.from ? (
              date?.to ? (
                <>
                  {format(date.from, "LLL dd, y", { locale: local })} -{" "}
                  {format(date.to, "LLL dd, y", { locale: local })}
                </>
              ) : (
                format(date.from, "LLL dd, y", { locale: local })
              )
            ) : (
              <FormattedMessage
                  id={"dateRangePicker.sentence"}
                  defaultMessage={"Pick a date range"}
              />
            )}
          </Button>
        </PopoverTrigger>
        <PopoverContent className="w-auto p-0" align="start">
          <Calendar
            initialFocus
            mode="range"
            defaultMonth={date?.from}
            selected={date}
            onSelect={(value) => {
                let formattedFrom = value?.from ? setOtherZone(value?.from,'Europe/Berlin' ) : undefined;
                let formattedTo = value?.to ? setOtherZone(value?.to, 'Europe/Berlin') : undefined;
                setDate({
                    from: formattedFrom,
                    to: formattedTo
                });
                setDate(value);
            }}
            numberOfMonths={2}
            disabled={(disabled) ? (date) => {
                if (typeof disabled === "object") {
                  console.log("should disable from this date : ", disabled.toString())
                }
                return date > setOtherZone(new Date()) || date < new Date("1900-01-01")
            } : undefined}
            locale={local}
          />
        </PopoverContent>
      </Popover>
    </div>
  )
}
