import DataTable, {Column} from "Core/Table/data-table";
import React, {Dispatch, SetStateAction, useEffect, useState} from "react";
import DataTableTools from "Core/Table/data-table-tools";
import DataTablePagination from "Core/Table/data-table-pagination";
import {Action} from "Core/Table/data-table-actions";
import {Badge} from "components/badge";
import statusVariants from "Core/functions/status-variants";
import {
    TimeCorrection,
    TimeCorrectionsPayload,
    TimeCorrectionsRequest, timeCorrectionsUrl
} from "AttendanceManagement/Controllers/time-corrections-controller";
import {TimeCorrectionFormatted} from "AttendanceManagement/Controllers/time-corrections-controller";
import {useIntl} from "react-intl";
import moment from "moment";
import {useAxiosInstance} from "Core/utilities/AxiosInstance";
import {toast} from "components/use-toast";
import {DataTablePayload} from "../../Core/Table/interfaces";

export const statuses = [
  {
    value: "Pending",
    label: "Pending",
  },
  {
    value: "Accepted",
    label: "Accepted",
  },
  {
    value: "Rejected",
    label: "Rejected",
  },
]


type DataTableProps = {
    timeCorrectionRequest: TimeCorrectionsRequest
    detailData: TimeCorrection | null
    setDetailData: React.Dispatch<TimeCorrectionFormatted | null>
    payload: TimeCorrectionsPayload
    setPayload: React.Dispatch<SetStateAction<DataTablePayload>>
    loading: boolean
    reFetch: Dispatch<SetStateAction<boolean>>
}

const TimeCorrectionsDataTable = ({
    timeCorrectionRequest,
    detailData,
    setDetailData,
    payload,
    setPayload,
    loading,
    reFetch
}: DataTableProps) => {
    const intl = useIntl();
    const axiosInstance = useAxiosInstance();
    const [formattedData, setFormattedData] = useState<TimeCorrectionFormatted[]>([])
    const timeCorrectionColumns: Column[] = [
        {
            backendKey: "userFullName",
            frontendKey: intl.formatMessage({id: "attendance.timeCorrections.table.employee", defaultMessage: "Employee"}),
            sortable: true,
            isSorted: "asc"
        },
        {
            backendKey: "correctionCheckInDate",
            frontendKey: intl.formatMessage({id: "attendance.timeCorrections.table.date", defaultMessage: "Date"}),
            sortable: true,
            isSorted: "desc"
        },
        {
            backendKey: "beforeCorrectionCheckInTime",
            frontendKey: intl.formatMessage({id: "attendance.timeCorrections.table.currentIn", defaultMessage: "Current In"}),
            sortable: true
        },
        {
            backendKey: "beforeCorrectionCheckOutTime",
            frontendKey: intl.formatMessage({id: "attendance.timeCorrections.table.currentOut", defaultMessage: "Current Out"}),
            sortable: true
        },
        {
            backendKey: "correctionCheckInTime",
            frontendKey: intl.formatMessage({id: "attendance.timeCorrections.table.newIn", defaultMessage: "New In"}),
            sortable: true
        },
        {
            backendKey: "correctionCheckOutTime",
            frontendKey: intl.formatMessage({id: "attendance.timeCorrections.table.newOut", defaultMessage: "New Out"}),
            sortable: true
        },
        {
            backendKey: "statusBadge",
            frontendKey: intl.formatMessage({id: "attendance.timeCorrections.table.status", defaultMessage: "Status"}),
            sortable: true
        },
        {backendKey: "actions", frontendKey: "actions"},
    ];

    const deleteTimeCorrection = (id: string) => {
        axiosInstance.delete(timeCorrectionsUrl + `${id}/`)
            .then((res) => {
                toast({
                    title: intl.formatMessage({id: "toast.success", defaultMessage: "Great!"}),
                    description: intl.formatMessage({id: "toast.success.deletedSuccessfully", defaultMessage: "The item has been deleted successfully."})
                });
                setDetailData(null);
                reFetch(true);
            })
            .catch((err) => {
                toast({
                    variant: "destructive",
                    title: intl.formatMessage({id: "toast.error", defaultMessage: "Error!"}),
                    description: err.response.data?.detail ?? intl.formatMessage({id: "toast.error.unableToDelete", defaultMessage: "There was an error deleting the item. Please try again."})
                });
            })
    }


    useEffect(() => {
        const formatData: TimeCorrectionFormatted[] = timeCorrectionRequest?.data.map((timeCorrection: TimeCorrection) => {
            return {
                ...timeCorrection,
                rowID: `${timeCorrection.pk}_${timeCorrection.userFullName}`,
                correctionCheckInDate: moment(timeCorrection.correctionCheckInDate).format("ddd DD MMM YYYY"),
                beforeCorrectionCheckInDate: moment(timeCorrection.beforeCorrectionCheckInDate).format("ddd DD MMM YYYY"),
                statusBadge: <Badge variant={statusVariants(timeCorrection?.status)}>{timeCorrection?.status}</Badge>,
                actions: [
                    {type: "delete", handleAction: () => deleteTimeCorrection(timeCorrection.pk)}
                ] as Action[]
            };
        })
        setFormattedData(formatData);

    }, [timeCorrectionRequest.data]);

    return(
        <div className="space-y-4 h-full flex flex-col justify-between">
            <div className="flex flex-col gap-4">
                <DataTableTools
                    payload={payload}
                    setPayload={setPayload}
                    filters={[
                        {
                            type: "simple",
                            title: intl.formatMessage({id: "data.table.filter.status", defaultMessage: "Status"}),
                            filterBy: 'filter.status',
                            options: statuses ?? [],
                        },
                        {
                            type: "dateFromTo",
                            from: "filter.startDate",
                            to: "filter.endDate",
                            disabled: true
                        }
                    ]}
                />
                <DataTable
                    data={formattedData}
                    loading={loading}
                    setDetailData={setDetailData}
                    detailData={detailData}
                    setPayload={setPayload}
                    columns={timeCorrectionColumns}
                    payload={payload}
                />
            </div>
            <DataTablePagination request={timeCorrectionRequest} payload={payload} setPayload={setPayload}/>
        </div>
    )
}

export default TimeCorrectionsDataTable;