import React, {Dispatch, SetStateAction, useEffect, useRef, useState} from "react";
import DetailViewCard from "../../Core/components/detail-view-card";
import {Progress} from "../../components/progress";
import {Badge} from "../../components/badge";
import {Timesheet} from "../Controllers/timesheet-controller";
import statusVariants from "../../Core/functions/status-variants";
import {Skeleton} from "../../components/skeleton";
import {nullOrUndefined} from "../../Core/constants/variables";
import {FormattedMessage, useIntl} from "react-intl";
import {months} from "../../Core/constants/date";
import {AirplaneTilt, ClockCounterClockwise, FirstAidKit} from "@phosphor-icons/react";
import {HoverCard, HoverCardContent, HoverCardTrigger} from "../../components/hover-card";
import {cn} from "../../lib/utils";
import moment from "moment";

type timesheetProgressComponentProps = {
    values: { planned:string | number; spent: string | number; }
    progress: { color?: string; value: number }
}

type TimesheetDetailProps = {
    monthsDetails: Map<number, Timesheet>
    year: number
    loading?: boolean
    setCurrMonth: Dispatch<SetStateAction<number>>
    currMonth: number
}

const TimesheetProgressComponent = ({values, progress}: timesheetProgressComponentProps) => (
    <div className="flex flex-col gap-1">
        <div className="flex justify-between">
            <span>{values.spent}</span>
            <span className="text-slate-600">{values.planned}</span>
        </div>
        <Progress value={progress.value} className={"bg-blue-800"}/>
    </div>
)

const TimesheetDetail: React.FC<TimesheetDetailProps> = (
    {
        loading,
        monthsDetails,
        year,
        setCurrMonth,
        currMonth
    }
) => {
    const intl = useIntl();
    const monthsOfYear = year === moment().year() ? moment().month() + 1 : 12
    const [monthsDetailsCopy, setMonthsDetailsCopy] = useState<Timesheet[]>([])

    const makeCopy = (input: Map<number, Timesheet>): Timesheet[] => {
        let output: Timesheet[] = []

        let keys = input.keys()

        for (let key of keys) {
            let valueToAdd = input.get(key)
            if (valueToAdd) {
                output.push(valueToAdd)
            }
        }

        return sortArray(output)
    }

    const setCopy = (input: Timesheet[]) => {
        setMonthsDetailsCopy(() => input)
    }

    const sortArray = (input: Timesheet[]): Timesheet[] => {
        let output: Timesheet[] = input

        output.sort((a,b) => {
            if (a.month > b.month) {
                return -1
            } else if (a.month < b.month) {
                return 1
            }
            return 0
        })

        return output
    }

    useEffect(() => {
        let newCopy = makeCopy(monthsDetails)
        setCopy(newCopy)
    }, [monthsDetails.size]);

    return (
        <div className="col-span-6 flex gap-4 w-full h-fit overflow-scroll pb-3">
            {monthsDetailsCopy ? (
                monthsDetailsCopy.map((monthDetail, index) => {
                    return (
                        <DetailViewCard
                            onClick={() => {
                                setCurrMonth(monthDetail.month);
                            }}
                            className={cn(
                                "hover:bg-slate-100 cursor-pointer gap-3 min-w-[300px] h-fit",
                                currMonth === monthDetail.month && "bg-slate-100"
                            )}
                            header={
                                <div className="flex flex-row justify-between items-center">
                                    <span className="text-lg font-semibold">
                                        <FormattedMessage
                                            id="timesheetManagement.detail.currMonth"
                                            defaultMessage="{currMonth} {currYear}"
                                            values={{
                                                currMonth: intl.formatMessage({
                                                    id: months[monthDetail.month - 1] ?? "month",
                                                    defaultMessage: "month"
                                                }),
                                                currYear: year
                                            }}
                                        />
                                    </span>
                                    {monthDetail && (
                                        <Badge variant={statusVariants(monthDetail.status, 2)}>{monthDetail?.status}</Badge>
                                    )}
                                </div>
                            }
                            rows={[
                                {jsxElement:(
                                    <TimesheetProgressComponent
                                        values={{
                                            planned: monthDetail?.plannedTotalTime ?? nullOrUndefined,
                                            spent: monthDetail?.spentTotalTime ?? nullOrUndefined
                                        }}
                                        progress={{value: Number(monthDetail?.percentage) || 0}}
                                    />
                                )},
                                {jsxElement: (
                                    <div className="flex flex-row justify-start items-center gap-6">
                                        <HoverCard>
                                            <HoverCardTrigger className="flex flex-row gap-1 items-center">
                                                <FirstAidKit size={20} color={"gray"}/>
                                                <span>{monthDetail?.sicknessDays ?? nullOrUndefined}</span>
                                            </HoverCardTrigger>
                                            <HoverCardContent className={"w-fit h-fit text-xs p-2"}>
                                                <FormattedMessage
                                                    id="timesheetManagement.detail.sickLeave"
                                                    defaultMessage="Sick Leave"
                                                />
                                            </HoverCardContent>
                                        </HoverCard>
                                        <HoverCard>
                                            <HoverCardTrigger className="flex flex-row gap-1 items-center">
                                                <AirplaneTilt size={20} color={"gray"}/>
                                                <span>{monthDetail?.vacationDays ?? nullOrUndefined}</span>
                                            </HoverCardTrigger>
                                            <HoverCardContent className={"w-fit h-fit text-xs p-2"}>
                                                <FormattedMessage
                                                    id="timesheetManagement.detail.vacation"
                                                    defaultMessage="Vacation"
                                                />
                                            </HoverCardContent>
                                        </HoverCard>
                                        <HoverCard>
                                            <HoverCardTrigger className="flex flex-row gap-1 items-center">
                                                <ClockCounterClockwise size={20} color={"gray"}/>
                                                <span>{monthDetail?.employeesOvertime ?? nullOrUndefined}</span>
                                            </HoverCardTrigger>
                                            <HoverCardContent className={"w-fit h-fit text-xs p-2"}>
                                                <FormattedMessage
                                                    id="timesheetManagement.detail.overtime"
                                                    defaultMessage="Employee Overtime"
                                                />
                                            </HoverCardContent>
                                        </HoverCard>
                                    </div>
                                )}
                            ]}
                        />
                    )
                })
            ) : null}

            {Array.from({length: monthsOfYear - monthsDetailsCopy.length}).map(() => (
                <div className="border rounded-lg flex flex-col gap-6 justify-between p-3 min-w-[300px] h-[190px]">
                    <div  className="flex justify-between">
                        <div><Skeleton className="w-[55px] h-[15px]"/></div>
                        <div><Skeleton className="w-[70px] h-[15px]"/></div>
                    </div>
                    <div className="flex flex-col gap-2">
                        <div className="flex justify-between">
                            <div><Skeleton className="w-[40px] h-[15px] "/></div>
                            <div><Skeleton className="w-[90px] h-[15px] "/></div>
                        </div>
                        <Skeleton className="w-full h-[15px] "/>
                    </div>
                    <div className="flex flex-row gap-3">
                        <div><Skeleton className="w-[35px] h-[15px]"/></div>
                        <div><Skeleton className="w-[25px] h-[15px]"/></div>
                        <div><Skeleton className="w-[70px] h-[15px]"/></div>
                    </div>
                </div>
            ))}
        </div>
    )
}


export default TimesheetDetail;