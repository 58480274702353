import React, {Dispatch, SetStateAction, useEffect, useState} from "react";
import {Skeleton} from "../../../components/skeleton";
import {FormattedMessage, useIntl} from "react-intl";
import DataTable, {Column} from "../../../Core/Table/data-table";
import {useAxiosInstance} from "../../../Core/utilities/AxiosInstance";
import DataTablePagination from "../../../Core/Table/data-table-pagination";
import {DataTablePayload, DataTableRequest} from "../../../Core/Table/interfaces";

type TVacationCredit = {
    year: string
    credit: string
    annualVacationDays: string
    remainingVacationDays: string
}

type VacationCreditProps = {
    userUUID: string
    reloadVacationPlanTab: boolean
    setReloadVacationPlanTab: Dispatch<SetStateAction<boolean>>
}

export const VacationCredits: React.FC<VacationCreditProps> = (
    {
        userUUID,
        reloadVacationPlanTab,
        setReloadVacationPlanTab
    }
) => {
    const intl = useIntl();
    const axiosInstance = useAxiosInstance();
    const [vacationCredits,setVacationCredits] = useState<TVacationCredit[]>([])
    const [payload, setPayload] = useState<DataTablePayload>({
        length: 5,
        start: 0
    })
    const [request, setRaquest] = useState<DataTableRequest>({
        recordsTotal: 0,
        recordsFiltered: 0,
        next: "",
        previous: "",
        data: []
    })
    const [loading, setLoading] = useState(false)

    const vacationCreditColumns: Column[] = [
        {
            frontendKey: intl.formatMessage({
                id: "employees.detail.vacationCredits.year",
                defaultMessage: "Year"
            }),
            backendKey: "year"
        },
        {
            frontendKey: intl.formatMessage({
                id: "employees.detail.vacationCredits.credit",
                defaultMessage: "Balance"
            }),
            backendKey: "credit"
        },
        {
            frontendKey: intl.formatMessage({
                id: "employees.detail.vacationCredits.annualVacationDays",
                defaultMessage: "Entitlement"
            }),
            backendKey: "annualVacationDays"
        },
        {
            frontendKey: intl.formatMessage({
                id: "employees.detail.vacationCredits.remainingVacationDays",
                defaultMessage: "Remaining Vacation Days"
            }),
            backendKey: "remainingVacationDays"
        }
    ]

    const fetchCredits = () => {
        setLoading(true)

        axiosInstance.get(`employee-management/${userUUID}/vacation-credits`)
            .then(res => {
                let data: TVacationCredit[]= res.data
                let recordsTotal = data.length
                let recordsFiltered = payload.length
                let next = data.length > payload.length ? "next" : ""
                let previous = ""

                setRaquest({
                    recordsTotal,
                    recordsFiltered,
                    next,
                    previous,
                    data: data.slice(payload.start,(payload.start + payload.length))
                })
                setVacationCredits(res.data)
            })
            .catch(err => console.log(err))
            .finally(() => setLoading(true))
    }

    useEffect(() => {
        fetchCredits()
    }, [userUUID, reloadVacationPlanTab]);

    useEffect(() => {

        let data: TVacationCredit[]= vacationCredits.slice(payload.start,(payload.start + payload.length))
        let next = payload.start + payload.length < vacationCredits.length ? "next" : undefined
        let previous = payload.start > 0 && payload.start <= vacationCredits.length ? "previous" : undefined

        setRaquest(prevState => ({
            ...prevState,
            recordsFiltered: payload.length,
            data: data,
            next,
            previous,
        }))
    }, [payload]);

    return <div className="flex flex-col gap-4">
        {vacationCredits && (loading || !loading) ? (
            <>
                <DataTable columns={vacationCreditColumns} data={request.data}/>
                <DataTablePagination request={request} payload={payload} setPayload={setPayload}/>
            </>
        ) : (!vacationCredits.length && loading) ? (
            <div className="flex flex-col gap-4">
                <Skeleton className="w-1/2 h-[15px]"/>
                <Skeleton className="w-1/3 h-[15px]"/>
                <Skeleton className="w-1/2 h-[15px]"/>
                <Skeleton className="w-full h-[15px]"/>
                <Skeleton className="w-1/6 h-[15px]"/>
            </div>
        ) : (
            <FormattedMessage id="no_results" defaultMessage="No results"/>
        )}
    </div>
}