import React from "react";
import EmployeesEntry from "./Pages/employees";
import RolesEntry from "./Pages/roles";
import EmploymentTypesEntry from "./Pages/employment-types";
import {useIntl} from "react-intl";
import {PageShape, PageShapeProps} from "../Core/layout/page-shape";
import CompanyGuidelines from "./Pages/company-guidelines";

export const employeeManagementUrl = 'employee-management/'

const EmployeeManagement = () => {
    const intl = useIntl();

    const employeesName = intl.formatMessage({id: "employeeManagement.employees", defaultMessage: "Employees"});
    const rolesName = intl.formatMessage({id: "employeeManagement.roles", defaultMessage: "Roles"});
    const employmentTypesName = intl.formatMessage({id: "employeeManagement.employmentTypes", defaultMessage: "Employment Types"});
    const companyGuidelinesName = intl.formatMessage({id: "employeeManagement.companyGuidelines", defaultMessage: "Company Guidelines"});

    const PageContent: PageShapeProps = {
        header: intl.formatMessage({id: "employeeManagement.header", defaultMessage: "Employee Management"}),
        sections: [
            {name: employeesName},
            {name: rolesName},
            {name: employmentTypesName},
            {name: companyGuidelinesName},
        ],
        pageEntries: [
            props => <EmployeesEntry name={employeesName} pagesState={props.pagesState}/>,
            props => <RolesEntry name={rolesName} pagesState={props.pagesState}/>,
            props => <EmploymentTypesEntry name={employmentTypesName} pagesState={props.pagesState}/>,
            props => <CompanyGuidelines name={companyGuidelinesName} pagesState={props.pagesState}/>,
        ],
        defaultSection: employeesName
    }

    return <PageShape {...PageContent}/>
}

export default EmployeeManagement;