import React from "react";
import DetailViewEmployeeAvatar from "Core/components/detail-view-employee-avatar";
import {Badge} from "components/badge";
import DetailViewCard from "Core/components/detail-view-card";
import statusVariants from "Core/functions/status-variants";
import {nullOrUndefined} from "Core/constants/variables";
import {TimeCorrectionFormatted} from "AttendanceManagement/Controllers/time-corrections-controller";
import {FormattedMessage, useIntl} from "react-intl";
import {ArrowCircleDownLeft, ArrowCircleUpRight} from "@phosphor-icons/react";

type TimeCorrectionsDetailViewProps = {
    timeCorrection: TimeCorrectionFormatted | null
    loading?: boolean
    handleAccept?: any
    handleReject?: any
}

export const TimeCorrectionsDetailView: React.FC<TimeCorrectionsDetailViewProps> = (
    {
        timeCorrection,
        loading
    }) => {
    const intl = useIntl();

    const Content = () => {
        return (
            <>
            {timeCorrection ? (
                <>
                     <DetailViewEmployeeAvatar
                        username={timeCorrection?.userFullName ?? nullOrUndefined}
                        status={<Badge variant={statusVariants(timeCorrection?.status)}>{timeCorrection?.status}</Badge>}
                        isActive={timeCorrection?.userIsActive}
                    />
                    <DetailViewCard
                        header={intl.formatMessage({id: "attendance.timeCorrections.detail.now", defaultMessage: "Now"})}
                        sizeFit={true}
                        rows={[
                        {jsxElement: (
                            <div className="flex flex-col gap-4">
                                <div className="flex flex-row justify-between">
                                    <span className="font-semibold">{timeCorrection.beforeCorrectionCheckInDate ?? nullOrUndefined}</span>
                                    <div className="flex flex-row gap-2 items-center justify-center">
                                        <span>{timeCorrection?.beforeDuration ?? nullOrUndefined}</span>
                                    </div>
                                </div>
                                <div className="flex flex-row justify-between">
                                    <div className="flex flex-row items-center gap-2">
                                        <ArrowCircleDownLeft size={28} color={"gray"}/>
                                        <div className="tewt-sm flex flex-col">
                                            <span className="text-gray-500">Check In</span>
                                            <span className="font-semibold">{timeCorrection?.beforeCorrectionCheckInTime ?? nullOrUndefined}</span>
                                        </div>
                                    </div>
                                    <span>{timeCorrection?.beforeCorrectionCheckInLocation?.name ?? nullOrUndefined}</span>
                                </div>
                                <div className="flex flex-row justify-between">
                                    <div className="flex flex-row items-center gap-2">
                                        <ArrowCircleUpRight size={28} color={"gray"}/>
                                        <div className="tewt-sm flex flex-col">
                                            <span className="text-gray-500">Check Out</span>
                                            <span className="font-semibold">{timeCorrection?.beforeCorrectionCheckOutTime ?? nullOrUndefined}</span>
                                        </div>
                                    </div>
                                    <span>{timeCorrection?.beforeCorrectionCheckOutLocation?.name ?? nullOrUndefined}</span>
                                </div>
                            </div>
                        )}
                    ]}/>
                    <DetailViewCard
                        header={intl.formatMessage({id: "attendance.timeCorrections.detail.corrected", defaultMessage: "Corrected"})}
                        sizeFit={true}
                        rows={[
                        {jsxElement: (
                            <div className="flex flex-col gap-4">
                                <div className="flex flex-row justify-between">
                                    <span className="font-semibold">{timeCorrection.correctionCheckInDate ?? nullOrUndefined}</span>
                                    <div className="flex flex-row gap-2 items-center justify-center">
                                        <span>{timeCorrection?.duration ?? nullOrUndefined}</span>
                                    </div>
                                </div>
                                <div className="flex flex-row justify-between">
                                    <div className="flex flex-row items-center gap-2">
                                        <ArrowCircleDownLeft size={28} color={"gray"}/>
                                        <div className="tewt-sm flex flex-col">
                                            <span className="text-gray-500">Check In</span>
                                            <span className="font-semibold">{timeCorrection?.correctionCheckInTime ?? nullOrUndefined}</span>
                                        </div>
                                    </div>
                                    <span>{timeCorrection?.correctionCheckInLocation?.name ?? nullOrUndefined}</span>
                                </div>
                                <div className="flex flex-row justify-between">
                                    <div className="flex flex-row items-center gap-2">
                                        <ArrowCircleUpRight size={28} color={"gray"}/>
                                        <div className="tewt-sm flex flex-col">
                                            <span className="text-gray-500">Check Out</span>
                                            <span className="font-semibold">{timeCorrection?.correctionCheckOutTime ?? nullOrUndefined}</span>
                                        </div>
                                    </div>
                                    <span>{timeCorrection?.correctionCheckInLocation?.name ?? nullOrUndefined}</span>
                                </div>
                            </div>
                        )}
                    ]}/>
                </>
        ) : (
            <FormattedMessage
                id={"pick_a_record"}
                defaultMessage={"Select a record to view details."}
            />
        )}
        </>
        )
    }

    return <Content/>
}