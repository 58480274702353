import {Input} from "../../components/input";
import React, {Dispatch, SetStateAction, useEffect, useState} from "react";
import {DataTableFacetedFilter} from "./data-table-faceted-filter";
import {DataTableDateFilter} from "./data-table-date-filter";
import {FormattedMessage, useIntl} from "react-intl";
import {Button} from "../../components/button";
import {useAxiosInstance} from "../utilities/AxiosInstance";
import {toast} from "../../components/use-toast";
import {Switch} from "../../components/switch";
import {DataTablePayload} from "./interfaces";

export type Option = {
    label: string
    value: string
    icon?: React.ComponentType<{ className?: string }>
}

export type Filter = {
    type: "simple" | "date" | "dateFromTo" | "switch"
    title?: string
}

export type SimpleFilter = {
    type: "simple"
    filterBy: string
    options: Option[]
} & Filter

export type DateFilter = {
    type: "date"
    date: string,
    defaultValue?: Date
    disabled?: boolean | Date
} & Filter

export type DateFromToFilter = {
    type: "dateFromTo",
    from: string,
    to: string,
    disabled?: boolean | Date
} & Filter

export type SwitchFilter = {
    type: "switch"
    label: string
    value: boolean
    dispatch: Dispatch<SetStateAction<boolean>>
} & Filter

export interface ExportDataTable {
    apiURL: string
    fileName: string
    payload: DataTablePayload | null
}

type DataTableToolsProps = {
    payload: DataTablePayload
    setPayload: React.Dispatch<SetStateAction<DataTablePayload>>
    filters?: (SimpleFilter | SwitchFilter | DateFilter | DateFromToFilter)[]
    exportTable?: ExportDataTable
    children?: React.ReactNode
}

const DataTableTools = ({payload, setPayload, filters, exportTable, children}: DataTableToolsProps) => {
    const intl = useIntl();
    const axiosInstance = useAxiosInstance();

    const handleExport = () => {
        if (!exportTable?.apiURL) return;
        axiosInstance.post(exportTable.apiURL, null,{
            params: exportTable.payload,
            responseType: "blob"
        })
            .then((res) => {
                const blob = new Blob([res.data])
                const blobUrl = URL.createObjectURL(blob);

                const a = document.createElement('a');
                a.href = blobUrl;
                a.download = `${exportTable.fileName}.xlsx`;
                document.body.appendChild(a);
                a.click();

                document.body.removeChild(a);
                URL.revokeObjectURL(blobUrl);

                toast({
                    title: "Exported Successfully !"
                })
            })
            .catch((err) => {
                toast({
                    title: "Export Failed !!",
                    variant: "destructive"
                })
            })
    }

    return(
        <div className="flex items-center justify-between">
            <div className="flex flex-1 items-center space-x-2">
                <Input
                    placeholder={intl.formatMessage({ id: 'table.search', defaultMessage: 'Search...' })}
                    onChange={(event) => {
                        if (setPayload) {
                            setPayload((prevState: any) => ({
                                ...prevState,
                                start: 0,
                                search: event.target.value
                            }))
                        }
                    }}
                    className="h-8 max-w-[200px]"
                    value={payload?.search}
                />
                {filters?.map(filter => {
                    switch (filter.type) {
                        case "simple": {
                            return <DataTableFacetedFilter {...filter} payload={payload} setPayload={setPayload}/>
                        }
                        case "switch": {
                            return <div className="flex gap-1 items-center">
                                <Switch
                                    value={"any"}
                                    className="data-[state=checked]:bg-blue-900"
                                    onCheckedChange={filter.dispatch}
                                />
                                <span className="text-xs text-gray-400">({filter.label})</span>
                            </div>
                        }
                        case "date":
                        case "dateFromTo": {
                            return <DataTableDateFilter
                                filter={filter}
                                payload={payload}
                                setPayload={setPayload}
                            />
                        }
                        default:{
                            return null
                        }
                    }
                })}
                {/*{(filters?.hasOwnProperty("status")) ? (
                    <DataTableFacetedFilter
                        title={intl.formatMessage({id: "data.table.filter.status", defaultMessage: "Status"})}
                        filter={"filter.status"}
                        options={filters?.status || []}
                        payload={payload}
                        setPayload={setPayload}
                    />
                ) : null}

                {(filters?.hasOwnProperty("type")) ? (
                    <DataTableFacetedFilter
                        title={intl.formatMessage({id: "data.table.filter.type", defaultMessage: "Type"})}
                        filter={"filter.type"}
                        options={filters?.type || []}
                        payload={payload}
                        setPayload={setPayload}
                    />
                ) : null}
                {(filters?.hasOwnProperty("roleID")) ? (
                    <DataTableFacetedFilter
                        title={intl.formatMessage({id: "data.table.filter.role", defaultMessage: "Role"})}
                        filter={"filter.roleID"}
                        options={filters?.roleID || []}
                        payload={payload}
                        setPayload={setPayload}
                    />
                ) : null}
                {(filters?.hasOwnProperty("employmentType")) ? (
                    <DataTableFacetedFilter
                        title={intl.formatMessage({id: "data.table.filter.employmentType", defaultMessage: "Emplyment Type"})}
                        filter={"filter.employmentType"}
                        options={filters?.employmentType || []}
                        payload={payload}
                        setPayload={setPayload}
                    />
                ) : null}
                {(filters?.date) ? (
                    <DataTableDateFilter
                        filter={filters?.date}
                        payload={payload}
                        setPayload={setPayload}
                        disabled={filters?.date?.disabled ?? false}
                    />
                ) : null}
                {(filters?.switch) ? (
                    <div className="flex gap-1 items-center">
                        <Switch
                            value={"any"}
                            className="data-[state=checked]:bg-blue-900"
                            onCheckedChange={filters.switch.dispatch}
                        />
                        <span className="text-xs text-gray-400">({filters.switch.label})</span>
                    </div>
                ) : null}*/}
                {exportTable &&
                    <div className={"grow grid justify-items-end"}>
                        <Button variant={"taimDefault2"} onClick={handleExport}>
                            <FormattedMessage
                                id={"data.table.export"}
                                defaultMessage={"Export"}
                            />
                        </Button>
                    </div>
                }
                {children}
            </div>
        </div>
    )
}

export default DataTableTools;