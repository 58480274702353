import DataTable, {Column, DataTableI} from "Core/Table/data-table";
import React, {ReactNode} from "react";
import DataTableTools from "Core/Table/data-table-tools";
import DataTablePagination from "Core/Table/data-table-pagination";
import {Action} from "Core/Table/data-table-actions";
import {Project, ProjectsRequest} from "ProjectManagement/Controller/projects-controller";
import {Service} from "ProjectManagement/Controller/services-controller";
import {Employee} from "ProjectManagement/Controller/employees-controller";
import {useIntl} from "react-intl";

export interface ProjectFormatted extends Project {
    statusBadge: ReactNode
    actions?: Action[]
}
interface ProjectsTable extends DataTableI<Project> {
    request: ProjectsRequest
    reloadData: (value: boolean) => void
    employees: Employee[]
    services: Service[]
    formattedProjects: ProjectFormatted[]
}

const statuses = [
  {
    value: "Done",
    label: "Done",
  },
  {
    value: "Pending",
    label: "Pending",
  },
  {
    value: "Progress",
    label: "Progress",
  },
]


export const ProjectsTable: React.FC<ProjectsTable> = (
    {
        payload,
        setPayload,
        detailData,
        setDetailData,
        loading,
        request,
        formattedProjects
    }
) => {
    const intl = useIntl();
    const projectsColumns: Column[] = [
        {
            backendKey: "name",
            frontendKey: intl.formatMessage({id: "project.projects.projectName", defaultMessage: "Project Name"}),
            sortable: true
        },
        {
            backendKey: "startDate",
            frontendKey: intl.formatMessage({id: "project.projects.startDate", defaultMessage: "Start Date"}),
            sortingValue: 'start_date',
            sortable: true
        },
        {
            backendKey: "endDate",
            frontendKey: intl.formatMessage({id: "project.projects.endDate", defaultMessage: "End Date"}),
            sortingValue: 'end_date',
            sortable: true
        },
        {
            backendKey: "employeesCount",
            frontendKey: intl.formatMessage({id: "project.projects.employees", defaultMessage: "Employees"}),
            sortable: true
        },
        {
            backendKey: "statusBadge",
            frontendKey: intl.formatMessage({id: "project.projects.status", defaultMessage: "Status"}),
            sortingValue: 'status',
            sortable: true
        },
        {backendKey: "actions", frontendKey: "actions"},
    ]



    return (
        <div className="space-y-4 h-full flex flex-col justify-between">
            <div className="flex flex-col gap-4">
                <DataTableTools
                    payload={payload}
                    setPayload={setPayload}
                    filters={[
                        {
                            type: "simple",
                            title: intl.formatMessage({id: "data.table.filter.status", defaultMessage: "Status"}),
                            filterBy: 'filter.status',
                            options: statuses ?? [],
                        },
                        {
                            title: '',
                            type: "dateFromTo",
                            from: "filter.startDate",
                            to: "filter.endDate",
                        }
                    ]}
                />
                <DataTable
                    columns={projectsColumns}
                    data={formattedProjects}
                    payload={payload}
                    setPayload={setPayload}
                    detailData={detailData}
                    setDetailData={setDetailData}
                    loading={loading}
                />
            </div>
            <DataTablePagination request={request} payload={payload} setPayload={setPayload}/>
        </div>
    )
}