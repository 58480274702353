import {Tabs, TabsContent, TabsList, TabsTrigger} from "../../components/tab";
import {
    Timesheet,
    TimesheetEmployeesPayload,
} from "../Controllers/timesheet-controller";
import React, {Dispatch, SetStateAction, useContext, useEffect, useState} from "react";
import {useIntl} from "react-intl";
import {Context} from "../../Core/utilities/Wrapper";
import {months} from "../../Core/constants/date";
import {RadioGroup, RadioGroupItem} from "../../components/radio-group";
import {Label} from "../../components/label";
import {Summarized} from "./MasterSections/summarized";
import {Detailed} from "./MasterSections/detailed";

type TimesheetMasterProps = {
    month: number
    year: number
    day: number | null
    setDay: React.Dispatch<SetStateAction<number | null>>
    monthsLoading: boolean
    fetchMonthTimesheet: (year: number, month: number, signal?: AbortSignal) => Promise<any>
    setTimesheetAllMonthsDetails: Dispatch<SetStateAction<Map<number, Timesheet>>>
}

const initialStateTimesheetEmployeesPayload: TimesheetEmployeesPayload = {
    start: 0,
    length: 10,
    search: "",
    ordering: "-plannedTime,-spentTime,fullName",
}

const initialStateTimesheetEmployeeDetailsPayload: TimesheetEmployeesPayload = {
    start: 0,
    length: 10,
    search: "",
    ordering: "",
}

const initialStateTimesheetDailyTrackPayload: TimesheetEmployeesPayload = {
    start: 0,
    length: 10,
    search: "",
    ordering: "-plannedTime,-spentTime,fullName",
}

const initialStateTimesheetDailyTrackDetailsPayload: TimesheetEmployeesPayload = {
    start: 0,
    length: 10,
    search: "",
    ordering: "",
}

const TimesheetMaster: React.FC<TimesheetMasterProps> = (
    {
        month,
        year,
        day,
        setDay,
        monthsLoading,
        fetchMonthTimesheet,
        setTimesheetAllMonthsDetails
    }
) => {
    const intl = useIntl();
    const MONTHLY_TRACK = intl.formatMessage({id: "timesheetManagement.master.monthlyTrack", defaultMessage: "Month View"});
    const DAILY_TRACK = intl.formatMessage({id: "timesheetManagement.master.dailyTrack", defaultMessage: "Day View"});
    const SUMMARIZED = intl.formatMessage({id: "timesheetManagement.master.summarized", defaultMessage: "Summarized"});
    const DETAILED = intl.formatMessage({id: "timesheetManagement.master.detailed", defaultMessage: "Detailed"});
    const [viewType, setView] = useState(MONTHLY_TRACK);
    const context = useContext(Context);
    const [employeesPayload, setEmployeesPayload] = useState<TimesheetEmployeesPayload>(initialStateTimesheetEmployeesPayload);
    const [employeeDetailsPayload, setEmployeeDetailsPayload] = useState<TimesheetEmployeesPayload>(initialStateTimesheetEmployeeDetailsPayload);
    const [timesheetDailyTracksPayload, setDailyTracksPayload] = useState<TimesheetEmployeesPayload>(initialStateTimesheetDailyTrackPayload)
    const [timesheetDailyTrackDetailsPayload, setDailyTrackDetailsPayload] = useState<TimesheetEmployeesPayload>(initialStateTimesheetDailyTrackDetailsPayload)
    const [typeOfData,setTypeOfData] = useState(SUMMARIZED)

    const onTabChange = async (value: string) => {
        setView(value);
        setEmployeesPayload(initialStateTimesheetEmployeesPayload);
        setDailyTracksPayload(initialStateTimesheetDailyTrackPayload);

        await new Promise(resolve => setTimeout(resolve, 0));
    }

    useEffect(() => {
        setView(MONTHLY_TRACK);
        setTypeOfData(SUMMARIZED);
    }, [context.language]);

    return (
        <div className="flex flex-col gap-4">
            <div className="font-semibold text-xl">{intl.formatMessage({
                id: months[month - 1],
                defaultMessage: months[month - 1]
            }) + " " + year}</div>
            <RadioGroup value={viewType} onValueChange={onTabChange} className="flex flex-row space-x-2">
                <div className="flex items-center space-x-2">
                    <RadioGroupItem value={MONTHLY_TRACK} id="r1" />
                    <Label htmlFor="r1">{MONTHLY_TRACK}</Label>
                </div>
                <div className="flex items-center space-x-2">
                    <RadioGroupItem value={DAILY_TRACK} id="r2" />
                    <Label htmlFor="r2">{DAILY_TRACK}</Label>
                </div>
            </RadioGroup>
            <Tabs value={typeOfData} onValueChange={(value) => setTypeOfData(value)} className="w-full min-h-[40rem]">
                <div className="flex flex-row justify-between">
                    <TabsList>
                        <TabsTrigger value={SUMMARIZED}>{SUMMARIZED}</TabsTrigger>
                        <TabsTrigger value={DETAILED}>{DETAILED}</TabsTrigger>
                    </TabsList>
                </div>
                <TabsContent value={SUMMARIZED} className={"focus-visible:ring-0"}>
                    <Summarized
                        viewType={viewType}
                        year={year}
                        month={month}
                        day={day}
                        setDay={setDay}
                        fetchMonthTimesheet={fetchMonthTimesheet}
                        setTimesheetAllMonthsDetails={setTimesheetAllMonthsDetails}
                        employeesPayload={employeesPayload}
                        setEmployeesPayload={setEmployeesPayload}
                        dailyTracksPayload={timesheetDailyTracksPayload}
                        setDailyTracksPayload={setDailyTracksPayload}
                        monthsLoading={monthsLoading}
                    />
                </TabsContent>
                <TabsContent value={DETAILED}>
                    <Detailed
                        viewType={viewType}
                        year={year}
                        month={month}
                        day={day}
                        setDay={setDay}
                        employeesDetailsPayload={employeeDetailsPayload}
                        setEmployeesDetailsPayload={setEmployeeDetailsPayload}
                        dailyTracksDetailsPayload={timesheetDailyTrackDetailsPayload}
                        setDailyTracksDetailsPayload={setDailyTrackDetailsPayload}
                        monthsLoading={monthsLoading}
                    />
                </TabsContent>
            </Tabs>
        </div>
    )
}

export default TimesheetMaster;