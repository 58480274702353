import React, {useEffect, useRef, useState} from "react";
import {useForm} from "react-hook-form";
import * as z from "zod";
import {zodResolver} from "@hookform/resolvers/zod";
import {SHIFTS_URL, TShiftFormatted} from "../controller/shifts_controller";
import {Form, FormControl, FormField, FormItem, FormLabel, FormMessage} from "../../components/form";
import {DialogClose, DialogFooter} from "../../components/dialog";
import {Button} from "../../components/button";
import {FormattedMessage, useIntl} from "react-intl";
import {Input} from "../../components/input";
import SearchInput from "../../Core/components/search-input";
import {SHIFT_TYPES, THEME_COLORS} from "../../Core/constants/enum";
import {TimePicker} from "../../Core/components/input-date-picker";
import {TShiftActions, TShiftPlan} from "../helper_functions/shift_reducer";
import {ServerErrorsType, useServerErrors} from "../../Core/functions/use-server-errors";
import {toast} from "../../components/use-toast";
import {useAxiosInstance} from "../../Core/utilities/AxiosInstance";
import ErrorMessageList from "../../Core/components/ErrorMessageList";
import {Checkbox} from "../../components/checkbox";
import {Label} from "../../components/label";
import {cn} from "../../lib/utils";
import {Trash} from "@phosphor-icons/react";
import {Table, TableBody, TableCell, TableHead, TableHeader, TableRow} from "../../components/table";
import {nullOrUndefined} from "../../Core/constants/variables";
import moment from "moment";
import {TimeValue} from "react-aria";
import {calculateDuration} from "../../Core/functions/calculate_durations";
import {Select, SelectContent, SelectItem, SelectTrigger, SelectValue} from "../../components/select";
import {employeeManagementUrl} from "../../EmployeeManagement";
import {Role} from "../../EmployeeManagement/Controllers/employee-controller";

type TBreaktime = {
    pk?: number
    start?: {
        string: string
        value: moment.Moment
        hour: number
        minute: number
    }
    end?: {
        string: string
        value: moment.Moment
        hour: number
        minute: number
    }
    duration?: {
        duration: moment.Duration
        durationString: string
    }
    timestamp?: Date | null
}

const formSchema = z.object({
    name: z.any(),
    workingSection: z.any(),
    startDatetime: z.any(),
    endDatetime: z.any(),
    capacity: z.any(),
    shiftBreakTimeRecords: z.any()
});

type TCreateShift = {
    shiftPlan: TShiftPlan
    weekDays: string[]
    sideFunction: () => void
}

export const CreateShift: React.FC<TCreateShift>  = ({shiftPlan, weekDays, sideFunction}) => {
    const axiosInstance = useAxiosInstance();
    const intl = useIntl();
    const form = useForm<z.infer<typeof formSchema>>({
        resolver: zodResolver(formSchema),
        defaultValues: {
            capacity: 0
        },
    });
    const [days, setMultipleDays] = useState<{
        dayName: string
        dayIndex: number
    }[]>([])
    const [colorTheme, setColorTheme] = useState(THEME_COLORS.default);
    const [apiErrors, setApiErrors] = useState<ServerErrorsType>({});
    const errors = useServerErrors(apiErrors, form);
    const closeModal = useRef<HTMLElement>();
    const [breaktimes, setBreaktimes] = useState<TBreaktime[]>([]);
    const [breaktime, setBreaktime] = useState<TBreaktime>();
    const [roles, setRoles] = useState<Role[]>([]);

    const onSubmit = async (values: z.infer<typeof formSchema>) => {
        type PostRequest = {
            link: string
            payload: {
                name: string
                startDatetime: string
                endDatetime: string
                weeklyShift: number
                capacity: number
                webFontColor: string
                webBackgroundColor: string
                workingSection: string | null
                shiftBreakTimeRecords: {
                    pk?: number
                    start_datetime: string
                    end_datetime: string
                }[]
            }
        }
        let postRequests: PostRequest[] = []

        days.forEach((day) => {
            let startDay = day.dayIndex
            let start = moment().set({
                h: values.startDatetime?.hour,
                m: values.startDatetime?.minute
            })
            let end = moment().set({
                h: values.endDatetime?.hour,
                m: values.endDatetime?.minute
            })
            let isNightShift = start.isAfter(end)

            let startDatetime = moment().set({
                year: shiftPlan.year,
                week: shiftPlan.week,
                day: startDay,
                ...values.startDatetime
            })
            let endDatetime = moment().set({
                year: shiftPlan.year,
                week: shiftPlan.week,
                day: isNightShift ? startDay + 1 : startDay,
                ...values.endDatetime
            })

            let request: PostRequest = {
                link: SHIFTS_URL + `${shiftPlan.weeklyShift?.pk}/shifts/`,
                payload: {
                    ...values,
                    ...(values.startDatetime && {startDatetime: startDatetime.format('YYYY-MM-DDTHH:mm')}),
                    ...(values.endDatetime && {endDatetime: endDatetime.format('YYYY-MM-DDTHH:mm')}),
                    ...(shiftPlan.weeklyShift?.pk && {weeklyShift: shiftPlan.weeklyShift.pk}),
                    shiftBreakTimeRecords: breaktimes.map(b => {
                        let startBreaktime = moment().set({
                            year: shiftPlan.year,
                            week: shiftPlan.week,
                            day: startDay,
                            hour: b.start?.hour,
                            minute: b.start?.minute
                        })
                        let endBreaktime = moment().set({
                            year: shiftPlan.year,
                            week: shiftPlan.week,
                            day: startDay,
                            hour: b.end?.hour,
                            minute: b.end?.minute
                        })

                        if (startDatetime.isAfter(startBreaktime)) {
                            startBreaktime.add(1,'day')
                            endBreaktime.add(1,'day')
                        }

                        if (endBreaktime.isBefore(startBreaktime)) {
                            endBreaktime.add(1,"day")
                        }

                        return {
                            ...(b.pk && {pk: b.pk}),
                            start_datetime: startBreaktime.format('YYYY-MM-DDTHH:mm'),
                            end_datetime: endBreaktime.format('YYYY-MM-DDTHH:mm')
                        }
                    }),
                    webBackgroundColor: colorTheme.background,
                    webFontColor: colorTheme.text,
                    workingSection: values.workingSection === 'null' ? null : values.workingSection
                }
            }
            postRequests.push(request)
        })

        if (!postRequests.length) {
            setApiErrors(prevState => ({
                ...prevState,
                non_field_errors: ['You have to pick at least one day.']
            }))
            return;
        }

        await Promise.all(postRequests.map(request => {
            return axiosInstance.post(request.link, request.payload)
                .catch((err) => {
                    throw err
                })
        }))
            .then((res) => {
                setApiErrors({})
                closeModal.current?.click()
                sideFunction();
                toast({
                    title: intl.formatMessage({id: "toast.success", defaultMessage: "Great!"}),
                    description: intl.formatMessage({id: "toast.success.actionCompleted", defaultMessage: "Your action was completed successfully."})
                });
            })
            .catch((err) => {
                setApiErrors(err.response?.data)
            })
    }

    const addBreaktime = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.preventDefault();

        if (!breaktime || !breaktime.start?.string || !breaktime.end?.string) {
            return;
        }

        if (breaktime.start.string && breaktime.end.string) {
            let newBreaktime: TBreaktime = {
                ...breaktime,
                timestamp: new Date()
            }

            setBreaktimes((prevState) => {
                return prevState.concat(newBreaktime);
            });
            setBreaktime(undefined)
        }
    };

    const removeBreaktime = (value: TBreaktime) => {
        setBreaktimes(prevState => prevState.filter(currBreak => currBreak !== value));
    }

    const fetchRoles = () => {
        axiosInstance.get(employeeManagementUrl + 'roles/')
            .then((res) => {
                setRoles(res.data)
            })
            .catch((err) => console.log(err))
    }

    useEffect(() => {

        if (breaktime?.start && breaktime?.end) {
            let duration = calculateDuration(
                {
                    hour: breaktime.start.hour,
                    minute: breaktime.start.minute
                } as TimeValue, {
                    hour: breaktime.end.hour,
                    minute: breaktime.end.minute
                } as TimeValue
            )

            setBreaktime(prevState => ({
                ...prevState,
                duration
            }))
        }
    }, [breaktime]);

    useEffect(() => {
        fetchRoles()
    }, []);

    return (
        <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)} className="flex flex-col gap-4">
                <FormLabel>
                    <FormattedMessage id={"shift_management.form.days"} defaultMessage={"Days"}/>
                </FormLabel>
                <div className="flex flex-row space-x-4 items-center">
                    {weekDays.map((_, index) => {
                        const currentDay = moment().set({
                            weekday: index
                        })
                        const currentDayName = currentDay.format('dddd')
                        const checked = !!days.find(elem => elem.dayName === currentDayName)

                        return (
                            <div className="flex space-x-2 items-center">
                                <Label htmlFor={`${currentDay.format('dd')}_${index}`}>{currentDay.format('dd')}</Label>
                                <Checkbox
                                    id={`${currentDay.format('dd')}_${index}`}
                                    checked={checked}
                                    onCheckedChange={(checked) => {
                                        return checked
                                            ? setMultipleDays(prevState => ([
                                                ...prevState,
                                                {dayName: currentDayName, dayIndex: index + 1}
                                            ]))
                                            : setMultipleDays(prevState => {
                                                let newState = [...prevState]
                                                return newState.filter((day) => day.dayIndex !== index + 1)
                                            })
                                    }}
                                />
                            </div>
                        )
                    })}
                </div>

                <FormField
                    control={form.control}
                    name="name"
                    render={({ field }) => {
                        return (
                            <FormItem>
                                <FormLabel>
                                    <FormattedMessage id={"shift_management.form.name"} defaultMessage={"Name"}/>
                                </FormLabel>
                                    <FormControl>
                                        <SearchInput
                                            title={intl.formatMessage({id: "shift_management.form.pick_a_shift_name", defaultMessage: "Pick a shift name"})}
                                            values={Object.values(SHIFT_TYPES).map(shift => ({
                                            key: intl.formatMessage({id: shift, defaultMessage: shift}),
                                            value: shift
                                        }))}
                                        value={field.value}
                                        onChange={field.onChange}
                                    />
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        );
                    }}
                />

                <FormField
                    control={form.control}
                    name="workingSection"
                    render={({ field }) => {
                        return (
                            <FormItem>
                                <FormLabel>
                                    <FormattedMessage id={"shift_management.form.working_section"} defaultMessage={"Working Section"}/>
                                </FormLabel>
                                <FormControl>
                                    <Select value={field.value} onValueChange={field.onChange}>
                                        <SelectTrigger>
                                            <SelectValue placeholder={intl.formatMessage({id: "shift_management.form.select_a_working_section", defaultMessage: "Select a working section"})}/>
                                        </SelectTrigger>
                                        <SelectContent>
                                            <SelectItem value={"null"}>{nullOrUndefined}</SelectItem>
                                            {roles.length > 0 && roles.map((role) => (
                                                <SelectItem value={role.name}>{role.name}</SelectItem>
                                            ))}
                                        </SelectContent>
                                    </Select>
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        );
                    }}
                />

                <FormField
                    control={form.control}
                    name="startDatetime"
                    render={({ field }) => {
                        return (
                            <FormItem className="flex flex-col space-y-2">
                                <FormLabel>
                                    <FormattedMessage id={"shift_management.form.starts_at"} defaultMessage={"Starts at"}/>
                                </FormLabel>
                                <FormControl>
                                    <TimePicker label={"start"} value={field.value} onChange={field.onChange}/>
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        );
                    }}
                />
                <FormField
                    control={form.control}
                    name="endDatetime"
                    render={({ field }) => {
                        return (
                            <FormItem>
                                <FormLabel>
                                    <FormattedMessage id={"shift_management.form.ends_at"} defaultMessage={"Ends at"}/>
                                </FormLabel>
                                <FormControl>
                                    <TimePicker label={"end"} value={field.value} onChange={field.onChange}/>
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        );
                    }}
                />

                <div className={"flex flex-col gap-4"}>
                    <FormLabel>
                        <FormattedMessage
                            id={"shift_management.form.breaktimes"}
                            defaultMessage={"Breaktimes"}
                        />
                        {" "}
                        <span className="text-gray-500 text-xs">
                            (<FormattedMessage id={"optional"} defaultMessage={"optional"}/>)
                        </span>
                    </FormLabel>
                    <div className="flex flex-row gap-2">
                        <TimePicker
                            value={breaktime?.start?.value ? {
                                hour: breaktime?.start?.value.get('h'),
                                minute: breaktime?.start?.value.get('m')
                            } as TimeValue : null}
                            onChange={(value) => {
                                setBreaktime((prevState) => {
                                    if (!value) return prevState;

                                    let hoursAndMinutesString = value.toString().split(':').slice(0, 2).join(':')
                                    let startValue = moment().set({
                                        hour: value.hour,
                                        minutes: value.minute
                                    })
                                    return {
                                        ...prevState,
                                        start: {
                                            string: hoursAndMinutesString,
                                            value: startValue,
                                            hour: value.hour,
                                            minute: value.minute
                                        }
                                    }
                                })
                            }}
                        />
                        <TimePicker
                            value={breaktime?.end?.value ? {
                                hour: breaktime?.end?.value.get('h'),
                                minute: breaktime?.end?.value.get('m')
                            } as TimeValue : null}
                            onChange={(value) => setBreaktime(prevState => {
                                if (!value) return prevState;

                                let hoursAndMinutesString = value.toString().split(':').slice(0, 2).join(':')
                                let startValue = moment().set({
                                    hour: value.hour,
                                    minutes: value.minute
                                })
                                return {
                                    ...prevState,
                                    end: {
                                        string: hoursAndMinutesString,
                                        value: startValue,
                                        hour: value.hour,
                                        minute: value.minute
                                    }
                                }
                            })}
                        />
                        <Button
                            variant="taimDefault2"
                            onClick={(event) => addBreaktime(event)}
                        ><FormattedMessage id={"add"} defaultMessage={"Add"} /></Button>
                    </div>

                    <FormField
                        control={form.control}
                        name={"shiftBreakTimeRecords"}
                        render={({ field}) => (
                            <div className="flex flex-col gap-2">
                                <Table className={"border rounded-md"}>
                                    <TableHeader>
                                        <TableRow>
                                            <TableHead>
                                                <FormattedMessage id={"attendance.attendances.startTime"} defaultMessage={"Start time"}/>
                                            </TableHead>
                                            <TableHead>
                                                <FormattedMessage id={"attendance.attendances.endTime"} defaultMessage={"End time"}/>
                                            </TableHead>
                                            <TableHead>
                                                <FormattedMessage id={"attendance.attendances.duration"} defaultMessage={"Duration"}/>
                                            </TableHead>
                                            <TableHead>
                                                <FormattedMessage id={"attendance.attendances.action"} defaultMessage={"Action"}/>
                                            </TableHead>
                                        </TableRow>
                                    </TableHeader>
                                    <TableBody>
                                        {breaktimes.length ? (
                                            breaktimes.map((currBreak, index) => {
                                                return (
                                                    <TableRow key="index">
                                                        <TableCell>{currBreak.start?.string ?? nullOrUndefined}</TableCell>
                                                        <TableCell>{currBreak.end?.string ?? nullOrUndefined}</TableCell>
                                                        <TableCell>{currBreak.duration?.durationString ?? nullOrUndefined}</TableCell>
                                                        <TableCell>
                                                            <Trash
                                                                color={"red"}
                                                                size={20}
                                                                className="cursor-pointer"
                                                                onClick={() => removeBreaktime(currBreak)}
                                                            />
                                                        </TableCell>
                                                    </TableRow>
                                                )
                                            })) : (
                                            <TableRow className="text-gray-500 text-xs">
                                                <TableCell colSpan={4} className={"text-center"}>
                                                    <FormattedMessage id={"attendance.attendances.addBreaktime"} defaultMessage={"Add breaktimes here."}/>
                                                </TableCell>
                                            </TableRow>
                                        )}
                                    </TableBody>
                                </Table>
                                <FormMessage className="max-w-[25rem]"/>
                            </div>
                        )}
                    />
                </div>

                <FormField
                    control={form.control}
                    name="capacity"
                    render={({ field }) => {
                        return (
                            <FormItem>
                                <FormLabel>
                                    <FormattedMessage id={"shift_management.form.capacity"} defaultMessage={"Capacity"}/>
                                </FormLabel>
                                    <FormControl>
                                        <Input 
                                            type={"number"} 
                                            placeholder={intl.formatMessage({id: "shift_management.form.capacity_placeholder", defaultMessage: "Set shift capacity"})} 
                                            {...field} 
                                        />
                                    </FormControl>
                                <FormMessage />
                            </FormItem>
                        );
                    }}
                />

                <FormLabel>
                    <FormattedMessage id={"shift_management.form.theme"} defaultMessage={"Theme"}/>
                </FormLabel>
                <div className="flex flex-row space-x-2">
                    {Object.keys(THEME_COLORS).map((color) => (
                        <div className={cn(
                                "rounded-full w-8 h-8 cursor-pointer hover:scale-105",
                                (colorTheme.background === THEME_COLORS[color].background) && "ring-2 ring-offset-2"
                            )}
                            style={{
                                background: THEME_COLORS[color].background,
                            }}
                            onClick={() => {
                                setColorTheme(THEME_COLORS[color])
                            }}
                        ></div>
                    ))}
                </div>

                <ErrorMessageList errors={errors.nonFieldErrors}/>
                <ErrorMessageList errors={errors.detailErrors}/>

                <DialogFooter className="justify-between space-x-2 px-0 pb-0">
                    <DialogClose ref={closeModal as any} asChild>
                        <Button variant="outline">
                            <FormattedMessage id={"button.leave"} defaultMessage={"Leave"}/>
                        </Button>
                    </DialogClose>
                    <Button
                        variant="taimDefault"
                        type="submit"
                    ><FormattedMessage id={"button.submit"} defaultMessage={"Submit"}/></Button>
                </DialogFooter>
            </form>
        </Form>
    )
}

type TEditShift = {
    shift: TShiftFormatted
    shiftPlan: TShiftPlan
    dispatch:  React.Dispatch<TShiftActions>
    setReFetchShifts:  (pk?: number) => void
}

export const EditShift: React.FC<TEditShift>  = ({shift, shiftPlan, dispatch, setReFetchShifts}) => {
    const form = useForm<z.infer<typeof formSchema>>({
        resolver: zodResolver(formSchema),
        defaultValues: {
            name: shift.name,
            capacity: shift.capacity,
            startDatetime: shift.startDatetime.date ? {
                hour: shift.startDatetime.date.get('hour'),
                minute: shift.startDatetime.date.get('minute')
            } : undefined,
            endDatetime: shift.endDatetime.date ? {
                hour: shift.endDatetime.date.get('hour'),
                minute: shift.endDatetime.date.get('minute')
            } : undefined,
            shiftBreakTimeRecords: shift.shiftBreakTimeRecords,
            ...(shift.workingSection && {workingSection: shift.workingSection})
        },
    });
    const intl = useIntl();
    const axiosInstance = useAxiosInstance();
    const [colorTheme, setColorTheme] = useState({background:shift.webBackgroundColor, text: shift.webFontColor} ?? THEME_COLORS.default);
    const [apiErrors, setApiErrors] = useState<ServerErrorsType>({});
    const errors = useServerErrors(apiErrors, form);
    const closeModal = useRef<HTMLElement>();
    const [breaktimes, setBreaktimes] = useState<TBreaktime[]>([]);
    const [breaktime, setBreaktime] = useState<TBreaktime>();
    const [roles, setRoles] = useState<Role[]>([]);

    const onSubmit = (values: z.infer<typeof formSchema>) => {
        let startDay = shift.startDatetime.date.isoWeekday()
        let start = moment().set({
            h: values.startDatetime?.hour,
            m: values.startDatetime?.minute
        })
        let end = moment().set({
            h: values.endDatetime?.hour,
            m: values.endDatetime?.minute
        })
        let isNightShift = start.isAfter(end)

        let startDatetime = moment().set({
            year: shiftPlan.year,
            week: shiftPlan.week,
            day: startDay,
            ...values.startDatetime
        })
        let endDatetime = moment().set({
            year: shiftPlan.year,
            week: shiftPlan.week,
            day: isNightShift ? startDay + 1 : startDay,
            ...values.endDatetime
        })

        const payload = {
            ...values,
            weeklyShift: shift.weeklyShift,
            ...(values.startDatetime && {startDatetime: startDatetime.format('YYYY-MM-DDTHH:mm')}),
            ...(values.endDatetime && {endDatetime: endDatetime.format('YYYY-MM-DDTHH:mm')}),
            shiftBreakTimeRecords: breaktimes.map(b => {
                let startBreaktime = moment().set({
                    year: shiftPlan.year,
                    week: shiftPlan.week,
                    day: startDay,
                    hour: b.start?.hour,
                    minute: b.start?.minute
                })
                let endBreaktime = moment().set({
                    year: shiftPlan.year,
                    week: shiftPlan.week,
                    day: startDay,
                    hour: b.end?.hour,
                    minute: b.end?.minute
                })

                if (startDatetime.isAfter(startBreaktime)) {
                    startBreaktime.add(1,'day')
                    endBreaktime.add(1,'day')
                }

                if (endBreaktime.isBefore(startBreaktime)) {
                    endBreaktime.add(1,"day")
                }

                return {
                    ...(b.pk && {pk: b.pk}),
                    start_datetime: startBreaktime.format('YYYY-MM-DDTHH:mm'),
                    end_datetime: endBreaktime.format('YYYY-MM-DDTHH:mm')
                }
            }),
            webBackgroundColor: colorTheme.background,
            webFontColor: colorTheme.text,
            workingSection: values.workingSection === 'null' ? null : values.workingSection
        }
        axiosInstance.put(SHIFTS_URL + `${payload.weeklyShift}/shifts/${shift.pk}/`, payload)
            .then((res) => {
                setApiErrors({});
                setReFetchShifts();
                closeModal.current?.click();
                toast({
                    title: intl.formatMessage({id: "toast.success", defaultMessage: "Great!"}),
                    description: intl.formatMessage({id: "toast.success.actionCompleted", defaultMessage: "Your action was completed successfully."})
                });
            })
            .catch((err) => {
                setApiErrors(err.response?.data)
            })
    }

    const addBreaktime = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.preventDefault();

        if (!breaktime || !breaktime.start?.string || !breaktime.end?.string) {
            return;
        }

        if (breaktime.start.string && breaktime.end.string) {
            let newBreaktime: TBreaktime = {
                ...breaktime,
                timestamp: new Date()
            }

            setBreaktimes((prevState) => {
                return prevState.concat(newBreaktime);
            });
            setBreaktime(undefined);
        }
    };

    const removeBreaktime = (value: TBreaktime) => {
        setBreaktimes(prevState => prevState.filter(currBreak => currBreak !== value));
    }

    const fetchRoles = () => {
        axiosInstance.get(employeeManagementUrl + 'roles/')
            .then((res) => {
                setRoles(res.data)
            })
            .catch((err) => console.log(err))
    }

    useEffect(() => {
        setBreaktimes(() => {
            return shift.shiftBreakTimeRecords.map(b => {
                let startDatetime = moment(b.start_datetime)
                let endDatetime = moment(b.end_datetime)
                let duration = calculateDuration(
                    {
                        hour: startDatetime.get('hour'),
                        minute: startDatetime.get('minute')
                    } as TimeValue, {
                        hour: endDatetime.get('hour'),
                        minute: endDatetime.get('minute')
                    } as TimeValue
                )

                return {
                    ...(b.pk && {pk: b.pk}),
                    start: {
                        value: startDatetime,
                        string: startDatetime.format('HH:mm'),
                        hour: startDatetime.get('hour'),
                        minute: startDatetime.get('minute')
                    },
                    end: {
                        value: endDatetime,
                        string: endDatetime.format('HH:mm'),
                        hour: endDatetime.get('hour'),
                        minute: endDatetime.get('minute')
                    },
                    duration,
                    timestamp: new Date()
                }
            })
        })
    }, []);

    useEffect(() => {

        if (breaktime?.start && breaktime?.end) {
            let duration = calculateDuration(
                {
                    hour: breaktime.start.hour,
                    minute: breaktime.start.minute
                } as TimeValue, {
                    hour: breaktime.end.hour,
                    minute: breaktime.end.minute
                } as TimeValue
            )

            setBreaktime(prevState => ({
                ...prevState,
                duration
            }))
        }
    }, [breaktime]);

    useEffect(() => {
        fetchRoles();
    }, []);

    return (
        <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)} className="flex flex-col gap-4">

                <FormField
                    control={form.control}
                    name="name"
                    render={({ field }) => {
                        return (
                            <FormItem>
                                <FormLabel>
                                    <FormattedMessage id={"shift_management.form.name"} defaultMessage={"Name"}/>
                                </FormLabel>
                                    <FormControl>
                                        <SearchInput
                                            title={intl.formatMessage({id: "shift_management.form.pick_a_shift_name", defaultMessage: "Pick a shift name"})}
                                            values={Object.values(SHIFT_TYPES).map(shift => ({
                                                key: intl.formatMessage({id: shift, defaultMessage: shift}),
                                                value: shift
                                            }))}
                                            value={field.value}
                                            onChange={field.onChange}
                                        />
                                    </FormControl>
                                <FormMessage />
                            </FormItem>
                        );
                    }}
                />

                <FormField
                    control={form.control}
                    name="workingSection"
                    render={({ field }) => {
                        return (
                            <FormItem>
                                <FormLabel>
                                    <FormattedMessage id={"shift_management.form.working_section"} defaultMessage={"Working Section"}/>
                                </FormLabel>
                                <FormControl>
                                    <Select value={field.value} onValueChange={field.onChange}>
                                        <SelectTrigger>
                                            <SelectValue placeholder={intl.formatMessage({id: "shift_management.form.select_a_working_section", defaultMessage: "Select a working section"})}/>
                                        </SelectTrigger>
                                        <SelectContent>
                                            <SelectItem value={"null"}>{nullOrUndefined}</SelectItem>
                                            {roles.length > 0 && roles.map((role) => (
                                                <SelectItem value={role.name}>{role.name}</SelectItem>
                                            ))}
                                        </SelectContent>
                                    </Select>
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        );
                    }}
                />

                <FormField
                    control={form.control}
                    name="startDatetime"
                    render={({ field }) => {
                        return (
                            <FormItem className="flex flex-col space-y-2">
                                <FormLabel>
                                    <FormattedMessage id={"shift_management.form.start_at"} defaultMessage={"Starts at"}/>
                                </FormLabel>
                                    <FormControl>
                                        <TimePicker label={"starts_at"} value={field.value} onChange={field.onChange}/>
                                    </FormControl>
                                <FormMessage />
                            </FormItem>
                        );
                    }}
                />
                <FormField
                    control={form.control}
                    name="endDatetime"
                    render={({ field }) => {
                        return (
                            <FormItem>
                                <FormLabel>
                                    <FormattedMessage id={"shift_management.form.ends_at"} defaultMessage={"Ends at"}/>
                                </FormLabel>
                                    <FormControl>
                                        <TimePicker label={"ends_at"} value={field.value} onChange={field.onChange}/>
                                    </FormControl>
                                <FormMessage />
                            </FormItem>
                        );
                    }}
                />


                <div className={"flex flex-col gap-4"}>
                    <FormLabel>
                        <FormattedMessage
                            id={"shift_management.form.breaktimes"}
                            defaultMessage={"Breaktimes"}
                        />
                        {" "}
                        <span className="text-gray-500 text-xs">
                            (<FormattedMessage id={"optional"} defaultMessage={"optional"}/>)
                        </span>
                    </FormLabel>
                    <div className="flex flex-row gap-2">
                        <TimePicker
                            value={breaktime?.start?.value ? {
                                hour: breaktime?.start?.value.get('h'),
                                minute: breaktime?.start?.value.get('m')
                            } as TimeValue : null}
                            onChange={(value) => {
                                setBreaktime((prevState) => {
                                    if (!value) return prevState;

                                    let hoursAndMinutesString = value.toString().split(':').slice(0, 2).join(':')
                                    let startValue = moment().set({
                                        hour: value.hour,
                                        minutes: value.minute
                                    })
                                    return {
                                        ...prevState,
                                        start: {
                                            string: hoursAndMinutesString,
                                            value: startValue,
                                            hour: value.hour,
                                            minute: value.minute
                                        }
                                    }
                                })
                            }}
                        />
                        <TimePicker
                            value={breaktime?.end?.value ? {
                                hour: breaktime?.end?.value.get('h'),
                                minute: breaktime?.end?.value.get('m')
                            } as TimeValue : null}
                            onChange={(value) => setBreaktime(prevState => {
                                if (!value) return prevState;

                                let hoursAndMinutesString = value.toString().split(':').slice(0, 2).join(':')
                                let startValue = moment().set({
                                    hour: value.hour,
                                    minutes: value.minute
                                })
                                return {
                                    ...prevState,
                                    end: {
                                        string: hoursAndMinutesString,
                                        value: startValue,
                                        hour: value.hour,
                                        minute: value.minute
                                    }
                                }
                            })}
                        />
                        <Button
                            variant="taimDefault2"
                            onClick={(event) => addBreaktime(event)}
                        ><FormattedMessage id={"add"} defaultMessage={"Add"} /></Button>
                    </div>

                    <FormField
                        control={form.control}
                        name={"shiftBreakTimeRecords"}
                        render={({ field}) => (
                            <div className="flex flex-col gap-2">
                                <Table className={"border rounded-md"}>
                                    <TableHeader>
                                        <TableRow>
                                            <TableHead>
                                                <FormattedMessage id={"attendance.attendances.startTime"} defaultMessage={"Start time"}/>
                                            </TableHead>
                                            <TableHead>
                                                <FormattedMessage id={"attendance.attendances.endTime"} defaultMessage={"End time"}/>
                                            </TableHead>
                                            <TableHead>
                                                <FormattedMessage id={"attendance.attendances.duration"} defaultMessage={"Duration"}/>
                                            </TableHead>
                                            <TableHead>
                                                <FormattedMessage id={"attendance.attendances.action"} defaultMessage={"Action"}/>
                                            </TableHead>
                                        </TableRow>
                                    </TableHeader>
                                    <TableBody>
                                        {breaktimes.length ? (
                                            breaktimes.map((currBreak, index) => {
                                                return (
                                                    <TableRow key="index">
                                                        <TableCell>{currBreak.start?.string ?? nullOrUndefined}</TableCell>
                                                        <TableCell>{currBreak.end?.string ?? nullOrUndefined}</TableCell>
                                                        <TableCell>{currBreak?.duration?.durationString ?? nullOrUndefined}</TableCell>
                                                        <TableCell>
                                                            <Trash
                                                                color={"red"}
                                                                size={20}
                                                                className="cursor-pointer"
                                                                onClick={() => removeBreaktime(currBreak)}
                                                            />
                                                        </TableCell>
                                                    </TableRow>
                                                )
                                            })) : (
                                            <TableRow className="text-gray-500 text-xs">
                                                <TableCell colSpan={4} className={"text-center"}>
                                                    <FormattedMessage id={"attendance.attendances.addBreaktime"} defaultMessage={"Add breaktimes here."}/>
                                                </TableCell>
                                            </TableRow>
                                        )}
                                    </TableBody>
                                </Table>
                                <FormMessage className="max-w-[25rem]"/>
                            </div>
                        )}
                    />
                </div>

                <FormField
                    control={form.control}
                    name="capacity"
                    render={({ field }) => {
                        return (
                            <FormItem>
                                <FormLabel>
                                    <FormattedMessage id={"shift_management.form.capacity"} defaultMessage={"Capacity"}/>
                                </FormLabel>
                                    <FormControl>
                                        <Input 
                                            type={"number"} 
                                            placeholder={intl.formatMessage({id: "shift_management.form.capacity_placeholder", defaultMessage: "Set shift capacity"})} 
                                            {...field} 
                                        />
                                    </FormControl>
                                <FormMessage />
                            </FormItem>
                        );
                    }}
                />


                <FormLabel>
                    <FormattedMessage id={"shift_management.form.theme"} defaultMessage={"Theme"}/>
                </FormLabel>
                <div className="flex flex-row space-x-2">
                    {Object.keys(THEME_COLORS).map((color) => (
                        <div className={cn(
                                "rounded-full w-8 h-8 cursor-pointer hover:scale-105",
                                (colorTheme.background === THEME_COLORS[color].background) && "ring-2 ring-offset-2"
                            )}
                            style={{
                                background: THEME_COLORS[color].background,
                            }}
                            onClick={() => {
                                setColorTheme(THEME_COLORS[color])
                            }}
                        ></div>
                    ))}
                </div>

                <ErrorMessageList errors={errors.nonFieldErrors}/>
                <ErrorMessageList errors={errors.detailErrors}/>

                <DialogFooter className="justify-between space-x-2 px-0 pb-0">
                    <DialogClose ref={closeModal as any} asChild>
                        <Button variant="outline">
                            <FormattedMessage id={"button.leave"} defaultMessage={"Leave"}/>
                        </Button>
                    </DialogClose>
                    <Button
                        variant="taimDefault"
                        type="submit"
                    ><FormattedMessage id={"button.submit"} defaultMessage={"Submit"}/></Button>
                </DialogFooter>
            </form>
        </Form>
    )
}


