import {TPortionType, TVacationType, TValidationStep} from "./interfaces";

export const validationSteps: TValidationStep[] = ["step_1", "step_2", "preview"]

export const vacationTypes: {key: string, value: TVacationType}[] = [
    {
        key: "Vacation",
        value: "vacation"
    },
    {
        key: "Sick Leave",
        value: "sickLeave"
    }
]

export const portionTypes: {key: string, value: TPortionType}[] = [
    {
        key: "Full Day",
        value: "wholeDay"
    },
    {
        key: "First Half",
        value: "firstHalf"
    },
    {
        key: "Second Half",
        value: "secondHalf"
    }
]