import {z} from "zod";
import {useForm} from "react-hook-form";
import {zodResolver} from "@hookform/resolvers/zod";
import {Form, FormControl, FormField, FormItem, FormLabel, FormMessage} from "components/form";
import {Input} from "components/input";
import React, {useEffect, useRef, useState} from "react";
import {useAxiosInstance} from "Core/utilities/AxiosInstance";
import {toast} from "components/use-toast";
import {DialogClose, DialogFooter} from "components/dialog";
import {Button} from "components/button";
import {FormattedMessage, useIntl} from "react-intl";
import {ServerErrorsType, useServerErrors} from "Core/functions/use-server-errors";
import ErrorMessageList from "Core/components/ErrorMessageList";
import {projectsUrl,Project} from "ProjectManagement/Controller/projects-controller";
import {DatePicker} from "components/date-picker";
import moment from "moment";
import {Employee} from "ProjectManagement/Controller/employees-controller";
import {Service} from "ProjectManagement/Controller/services-controller";
import {Trash} from "@phosphor-icons/react";
import DetailViewCard from "Core/components/detail-view-card";
import {nullOrUndefined} from "Core/constants/variables";
import Title from "Core/components/form-title";
import RegularNameAvatar from "Core/components/regular-name-avatar";
import {Tabs, TabsContent, TabsList, TabsTrigger} from "components/tab";
import SearchInput, {SearchInputValue} from "Core/components/search-input";
import formatDate from "../../Core/functions/format-date";
import {MultiSelect} from "../../Core/components/multi-select";
import {SHIFTS_URL} from "../../ShiftManagement/controller/shifts_controller";

const createProjectSchema = z.object({
    name: z.string().optional(),
    startDate: z.date().optional(),
    endDate: z.date().optional(),
})

type CreateProjectFormProps = {
    reloadData: (value: boolean) => void
    employees: Employee[]
    services: Service[]
}

export const CreateProjectForm: React.FC<CreateProjectFormProps> = (
    {
        reloadData,
        employees,
        services
    }
) => {
    const intl = useIntl();
    const axiosInstance = useAxiosInstance();
    const form = useForm<z.infer<typeof createProjectSchema>>({
        resolver: zodResolver(createProjectSchema)
    });
    const [apiErrors, setApiErrors] = useState<ServerErrorsType>({});
    const errors = useServerErrors(apiErrors, form);
    const closeModal = useRef<HTMLButtonElement>(null);
    const [validation, setValidation] = useState<"step_1" | "step_2" | "step_3" | "valid">("step_1");
    const [steps, setSteps] = useState([
        {place: "step_1", state: false},
        {place: "step_2", state: false},
        {place: "step_3", state: false},
    ]);
    const [tab, setTab] = useState<string>("step_1");
    const [validationErrors, setValidationErrors] = useState<{place?: "step_1" | "step_2" | "step_3", statement?: string}[]>([]);
    const [servicesList, setServicesList] = useState<{
        service: Service
        value: string
        label: string
        icon?: React.ComponentType<{ className?: string }>
    }[]>([])
    const [selectedServicesMulti, setSelectedServicesMulti] = useState<string[]>([]);
    const [addedServices, setAddedServices] = useState<Service[]>([])
    const [employeesList, setEmployeesList] = useState<{
        employee: Employee
        value: string
        label: string
        icon?: React.ComponentType<{ className?: string }>
    }[]>([])
    const [selectedEmployeesMulti, setSelectedEmployeesMulti] = useState<string[]>([]);
    const [addedEmployees, setAddedEmployees] = useState<Employee[]>([])
    const [startDate, setStartDate] = useState<Date | undefined>();
    const [endDate, setEndDate] = useState<Date | undefined>();
    
    // service functions
    const handleServicesAdd = (values: string[]) => {
        if (!values.length) {
            setValidationErrors((prevState) => {
                return [
                    ...prevState,
                    {
                        place: "step_2",
                        statement: intl.formatMessage({id: "projectMangement.serviceError", defaultMessage: "* Select a service from dropdown"})
                    }
                ]
            })
            return;
        }

        setValidationErrors((prevState) => {
            return prevState.filter((err) => err.place !== "step_2");
        })

        setAddedServices((prevState) => {
            let isSelected = prevState.filter(service => values.includes(service.pk.toString()));

            if (isSelected.length) {
                toast({
                    title: intl.formatMessage({id: "toast.informational", defaultMessage: "Info!"}),
                    description: intl.formatMessage({id: "toast.informational.input_data_missing", defaultMessage: "Check again the input data you are trying to send."}),
                    variant: "informational"
                })

                return prevState;
            }

            let selectedServices =
                servicesList
                    .filter(service => values.includes(service.service.pk.toString()))
                    .map(elem => elem.service);

            setSelectedServicesMulti([])

            return prevState.concat(selectedServices);
        });
    };

    const onServiceToTrash = (pk: string) => {
        setAddedServices((prevState) => {
            return prevState.filter(service => service.pk.toString() !== pk);
        });
    }
    
    // employee functions
    const handleEmployeesAdd = (values: string[]) => {
        if (!values.length) {
            setValidationErrors((prevState) => {
                return [
                    ...prevState,
                    {
                        place: "step_2",
                        statement: intl.formatMessage({id: "projectMangement.EmployeeError", defaultMessage: "* Select a Employee from dropdown"})
                    }
                ]
            })
            return;
        }

        setValidationErrors((prevState) => {
            return prevState.filter((err) => err.place !== "step_2");
        })

        setAddedEmployees((prevState) => {
            let isSelected = prevState.filter(employee => values.includes(employee.userUUID.toString()));

            if (isSelected.length) {
                toast({
                    title: intl.formatMessage({id: "toast.informational", defaultMessage: "Info!"}),
                    description: intl.formatMessage({id: "toast.informational.input_data_missing", defaultMessage: "Check again the input data you are trying to send."}),
                    variant: "informational"
                })

                return prevState;
            }

            let selectedEmployees =
                employeesList
                    .filter(employee => values.includes(employee.employee.userUUID.toString()))
                    .map(elem => elem.employee);

            setSelectedEmployeesMulti([])

            return prevState.concat(selectedEmployees);
        });
    };

    const onEmployeeToTrash = (pk: string) => {
        setAddedEmployees((prevState) => {
            return prevState.filter(employee => employee.userUUID.toString() !== pk);
        });
    }

    const onValidate = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.preventDefault();

        let values = {...form.getValues()};
        let data = {
            ...values,
            startDate: startDate ? formatDate(startDate) : null,
            endDate: endDate ? formatDate(endDate) : null,
            employees: addedEmployees,
            services: addedServices
        };
        let validationSteps: ("valid" | "step_1" | "step_2" | "step_3")[] = ["step_1", "step_2", "step_3", "valid"];
        let currentStepIndex = validationSteps.findIndex(elem => elem === tab);
        let nextStep = validationSteps[currentStepIndex + 1];
        let nextStepIndex = currentStepIndex + 1;

        setApiErrors({});

        axiosInstance.post(projectsUrl + "validate/", data)
            .then((res) => {
                form.clearErrors();
                setApiErrors({});
                setValidationErrors([]);
                if (nextStep === "valid") {
                    setValidation(nextStep);
                } else {
                    setTab(nextStep);
                    setSteps(prevState => prevState.map(step => {
                        if (step.place === nextStep) {
                            return {
                                ...step,
                                state: true
                            }
                        }
                        return step
                    }))
                }
            })
            .catch((err) => {
                setApiErrors(err.response?.data ?? {});
                setSteps(prevState => prevState.map((step, index) => {
                    if (index >= nextStepIndex) {
                        return {
                            ...step,
                            state: false
                        }
                    }
                    return step
                }))
            })
    }

    const onSubmit = (values: z.infer<typeof createProjectSchema>) => {
        const data = {
            ...values,
            startDate: startDate ? formatDate(startDate) : null,
            endDate: endDate ? formatDate(endDate) : null,
            employees: addedEmployees,
            services: addedServices
        }

        axiosInstance.post(projectsUrl, data)
            .then((res) => {
                setApiErrors({});
                reloadData(true);
                closeModal.current?.click();
                toast({
                    title: intl.formatMessage({id: "toast.success", defaultMessage: "Great!"}),
                    description: intl.formatMessage({id: "toast.success.actionCompleted", defaultMessage: "Your action was completed successfully."})
                });
            })
            .catch((err) => {
                setApiErrors(err.response?.data ?? {});
            })
    }

    const onTabChange = (value: string) => {
        setTab(value);
    }

    const setServiceOptions = () => {
        if (services.length) {
            setServicesList(prevState => {
                return services
                    .filter((service) => {
                        let addedServicesArrayOfPk = addedServices.map(elem => elem.pk.toString())
                        return !addedServicesArrayOfPk.includes(service.pk.toString())
                    })
                    .map((service) => ({
                    service,
                    label: service.name,
                    value: service.pk.toString()
                }))
            })
        }
    }

    const setEmployeeOptions = () => {
        if (employees.length) {
            setEmployeesList(prevState => {
                return employees
                    .filter((employee) => {
                        let addedEmployeesArrayOfUUID = addedEmployees.map(elem => elem.userUUID.toString())
                        return !addedEmployeesArrayOfUUID.includes(employee.userUUID.toString())
                    })
                    .map((employee) => ({
                        employee,
                        label: employee.fullName,
                        value: employee.userUUID.toString()
                    }))
            })
        }
    }

    useEffect(() => {
        setServiceOptions();
    }, [addedServices]);

    useEffect(() => {
        setEmployeeOptions();
    }, [addedEmployees]);

    useEffect(() => {
        setValidationErrors(prevState => {
            return prevState.filter(err => err.place === "step_1");
        });
    }, [validation]);

    return (
        <Form {...form}>
            <form method="post" onSubmit={form.handleSubmit(onSubmit)} className="flex flex-col gap-4">
                {validation !== "valid" ? (
                    <Tabs value={tab} onValueChange={onTabChange}>
                        <TabsList>
                            <TabsTrigger value={"step_1"}>
                                <FormattedMessage id={"step_1"} defaultMessage={"Step 1"}/>
                            </TabsTrigger>
                            <TabsTrigger
                                value={"step_2"}
                                disabled={!steps.find(elem => elem.place === "step_2")?.state}
                            ><FormattedMessage id={"step_2"} defaultMessage={"Step 2"}/></TabsTrigger>
                            <TabsTrigger
                                value={"step_3"}
                                disabled={!steps.find(elem => elem.place === "step_3")?.state}
                            ><FormattedMessage id={"step_3"} defaultMessage={"Step 3"}/></TabsTrigger>
                        </TabsList>
                        <TabsContent value={"step_1"} className="flex flex-col gap-4">
                            <FormField
                                control={form.control}
                                name="name"
                                render={({ field }) => (
                                    <FormItem>
                                        <FormLabel>
                                            <FormattedMessage
                                                id={"project.project"}
                                                defaultMessage={"Project"}
                                            />
                                        </FormLabel>
                                        <FormControl>
                                            <Input placeholder={intl.formatMessage({id: "project.projects.enterProjectName", defaultMessage: "Enter project name"})} {...field}/>
                                        </FormControl>
                                        <FormMessage/>
                                    </FormItem>
                                )}
                            />
                            <div className="flex flex-row gap-2">
                                <div className="grow">
                                    <FormField
                                        control={form.control}
                                        name="startDate"
                                        render={({ field }) => (
                                            <FormItem className="flex flex-col">
                                                <FormLabel>
                                                    <FormattedMessage
                                                        id={"project.projects.startDate"}
                                                        defaultMessage={"Start Date"}
                                                    />
                                                </FormLabel>
                                                <FormControl>
                                                    <DatePicker area-label={field.name} date={startDate} setDate={setStartDate}/>
                                                </FormControl>
                                                <FormMessage/>
                                            </FormItem>
                                        )}
                                    />
                                </div>
                                <div className="grow">
                                    <FormField
                                        control={form.control}
                                        name="endDate"
                                        render={({ field }) => (
                                            <FormItem className="flex flex-col">
                                                <FormLabel>
                                                    <FormattedMessage
                                                        id={"project.projects.endDate"}
                                                        defaultMessage={"End Date"}
                                                    />{" "}<span className="text-xs text-slate-500">(optional)</span>
                                                </FormLabel>
                                                <FormControl>
                                                    <DatePicker area-label={field.name} date={endDate} setDate={setEndDate}/>
                                                </FormControl>
                                                <FormMessage/>
                                            </FormItem>
                                        )}
                                    />
                                </div>
                            </div>

                            <ErrorMessageList errors={errors.nonFieldErrors} />
                            <ErrorMessageList errors={errors.detailErrors} />


                            <DialogFooter className="px-0 pb-0">
                                <DialogClose ref={closeModal as any} asChild>
                                    <Button variant="outline">
                                        <FormattedMessage id={"button.cancel"} defaultMessage={"Cancel"}/>
                                    </Button>
                                </DialogClose>
                                <Button
                                    variant="taimDefault"
                                    type="button"
                                    onClick={onValidate}
                                ><FormattedMessage id={"button.next"} defaultMessage={"Next"}/></Button>
                            </DialogFooter>

                        </TabsContent>

                        <TabsContent value={"step_2"} className="flex flex-col gap-4">

                            <FormLabel>
                                <FormattedMessage
                                    id={"project.projects.services"}
                                    defaultMessage={"Services"}
                                />
                            </FormLabel>

                            <div className="flex flex-row items-center gap-2">
                                <MultiSelect
                                    options={servicesList}
                                    onValueChange={setSelectedServicesMulti}
                                    defaultValue={selectedServicesMulti}
                                    placeholder={intl.formatMessage({id: "project.projects.servicePlaceholder", defaultMessage: "Select a service"})}
                                    variant="inverted"
                                    maxCount={3}
                                    modalPopover={true}
                                    hideOptions={true}
                                />
                                <Button
                                    variant="taimDefault2"
                                    type={"button"}
                                    onClick={(event) => {
                                        if (selectedServicesMulti.length) {
                                            handleServicesAdd(selectedServicesMulti)
                                        } else {
                                            toast({
                                                title: intl.formatMessage({id: "toast.informational", defaultMessage: "Info!"}),
                                                description: intl.formatMessage({id: "toast.informational.input_data_missing", defaultMessage: "Check again the input data you are trying to send."}),
                                                variant: "informational"
                                            })
                                        }
                                    }}
                                ><FormattedMessage id={"add"} defaultMessage={"Add"} /></Button>
                            </div>

                            <div className="flex flex-col gap-4 p-4 border rounded">
                                {addedServices.length ? (
                                    addedServices.map((service, index) => {
                                        return (
                                            <div className="flex flex-row justify-between px-2" key={index}>
                                                <div>{`${index + 1}. ${service.name}`}</div>
                                                <Trash
                                                    color={"red"}
                                                    size={20}
                                                    className="cursor-pointer"
                                                    onClick={() => onServiceToTrash(String(service.pk))}
                                                />
                                            </div>
                                        )
                                    })
                                ) : (
                                    <div className="text-gray-500 text-xs">
                                        <FormattedMessage id={"project.projects.serviceNotice"} defaultMessage={"Pick services from dropdown above"}/>
                                    </div>
                                )}
                            </div>

                            <ErrorMessageList errors={errors.nonFieldErrors} />
                            <ErrorMessageList errors={errors.detailErrors} />
                            <ErrorMessageList errors={
                                (() => {
                                    let uniqueErrors = new Set<string>();
                                    validationErrors.forEach(err => {
                                        if (err.statement && err.place !== "step_3") {
                                            uniqueErrors.add(err.statement);
                                        }
                                    });
                                    return Array.from(uniqueErrors);
                                })()
                            }/>

                            <DialogFooter className="justify-between space-x-2 px-0 pb-0">
                                <Button
                                    variant="outline"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        setTab("step_1");
                                    }}
                                >
                                    <FormattedMessage id={"button.back"} defaultMessage={"Back"}/>
                                </Button>
                                <Button
                                    variant="taimDefault"
                                    type="button"
                                    onClick={onValidate}
                                >
                                    <FormattedMessage id={"button.next"} defaultMessage={"Next"}/>
                                </Button>
                            </DialogFooter>

                        </TabsContent>

                        <TabsContent value={"step_3"} className="flex flex-col gap-4">

                            <FormLabel>
                                <FormattedMessage
                                    id={"project.projects.employees"}
                                    defaultMessage={"Employees"}
                                />
                            </FormLabel>

                            <div className="flex flex-row items-center gap-2">
                                <MultiSelect
                                    options={employeesList}
                                    onValueChange={setSelectedEmployeesMulti}
                                    defaultValue={selectedEmployeesMulti}
                                    placeholder={intl.formatMessage({id: "project.projects.employeesPlaceholder", defaultMessage: "Select an employee"})}
                                    variant="inverted"
                                    maxCount={3}
                                    modalPopover={true}
                                    hideOptions={true}
                                />
                                <Button
                                    variant="taimDefault2"
                                    type={"button"}
                                    onClick={(event) => {
                                        if (selectedEmployeesMulti.length) {
                                            handleEmployeesAdd(selectedEmployeesMulti)
                                        } else {
                                            toast({
                                                title: intl.formatMessage({id: "toast.informational", defaultMessage: "Info!"}),
                                                description: intl.formatMessage({id: "toast.informational.input_data_missing", defaultMessage: "Check again the input data you are trying to send."}),
                                                variant: "informational"
                                            })
                                        }
                                    }}
                                ><FormattedMessage id={"add"} defaultMessage={"Add"} /></Button>
                            </div>

                            <div className="flex flex-col gap-4 p-4 border rounded">
                                {addedEmployees.length ? (
                                    addedEmployees.map((employee, index) => (
                                        <div className="flex justify-between items-center px-2">
                                            <RegularNameAvatar username={employee.fullName}/>
                                            <div className="text-gray-500 capitalize w-1/2 flex justify-between">
                                                <span>{employee.roleName || nullOrUndefined}</span>
                                                <Trash
                                                    color={"red"}
                                                    size={20}
                                                    className="cursor-pointer"
                                                    onClick={() => onEmployeeToTrash(String(employee.userUUID))}
                                                />
                                            </div>
                                        </div>
                                    ))
                                ) : (
                                    <div className="text-gray-500 text-xs">
                                        <FormattedMessage id={"project.projects.employeesNotice"} defaultMessage={"Pick employees from dropdown above"}/>
                                    </div>
                                )}
                            </div>

                            <ErrorMessageList errors={errors.nonFieldErrors} />
                            <ErrorMessageList errors={errors.detailErrors} />
                            <ErrorMessageList errors={
                                (() => {
                                    let uniqueErrors = new Set<string>();
                                    validationErrors.forEach(err => {
                                        if (err.statement && err.place !== "step_2") {
                                            uniqueErrors.add(err.statement);
                                        }
                                    });
                                    return Array.from(uniqueErrors);
                                })()
                            }/>

                            <DialogFooter className="justify-between space-x-2 px-0 pb-0">
                                <Button
                                    variant="outline"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        setTab("step_2");
                                    }}
                                >
                                    <FormattedMessage id={"button.back"} defaultMessage={"Back"}/>
                                </Button>
                                <Button
                                    variant="taimDefault"
                                    type="button"
                                    onClick={onValidate}
                                >
                                    <FormattedMessage id={"button.preview"} defaultMessage={"Preview"}/>
                                </Button>
                            </DialogFooter>

                        </TabsContent>

                    </Tabs>
                ) : (
                    <div className="flex flex-col gap-4">
                        <Title title={intl.formatMessage({id: "project.projects.information", defaultMessage: "Information"})}/>
                        <DetailViewCard rows={[
                            {
                                name: intl.formatMessage({id: "project.projects.projectName", defaultMessage: "Project Name"}),
                                value: form.getValues("name") ?? nullOrUndefined
                            },
                            {
                                name: intl.formatMessage({id: "project.projects.startDate", defaultMessage: "Start Date"}),
                                value: startDate ? moment(formatDate(startDate)).format("DD MMM YYYY") : nullOrUndefined
                            },
                            {
                                name: intl.formatMessage({id: "project.projects.endDate", defaultMessage: "End Date"}),
                                value: endDate ? moment(formatDate(endDate)).format("DD MMM YYYY") : nullOrUndefined
                            },
                        ]}/>
                        <Title title={intl.formatMessage({id: "project.projects.services", defaultMessage: "Services"})}/>
                        <DetailViewCard rows={
                            addedServices.length ? addedServices.map((service, index) => {
                                return {
                                    jsxElement: (
                                        <div className="">{index + 1}. {service.name}</div>
                                    )
                                }
                            }) : [{
                                jsxElement: <FormattedMessage id={"no_results"} defaultMessage="No results"/>
                            }]
                        }/>
                        <Title title={intl.formatMessage({id: "project.projects.employees", defaultMessage: "Employees"})}/>
                        <DetailViewCard rows={
                            addedEmployees.length ? addedEmployees.map(employee => {
                                return {
                                    jsxElement: (
                                        <div className="flex justify-between items-center px-2">
                                            <RegularNameAvatar username={employee.fullName}/>
                                            <div className="text-gray-500 capitalize w-1/2">{employee.roleName || nullOrUndefined}</div>
                                        </div>
                                    )
                                }
                            }) : [{
                                jsxElement: <FormattedMessage id={"no_results"} defaultMessage="No results"/>
                            }]
                        }/>
                        <DialogFooter className="justify-between space-x-2 px-0 pb-0">
                            <DialogClose ref={closeModal as any} asChild>
                                <Button className="hidden">close</Button>
                            </DialogClose>
                            <Button
                                variant="outline"
                                onClick={(e) => {
                                    e.preventDefault();
                                    setValidation("step_3");
                                }}
                            >
                                <FormattedMessage id={"button.back"} defaultMessage={"Back"}/>
                            </Button>
                            <Button variant="taimDefault" type="submit">
                                <FormattedMessage id={"button.submit"} defaultMessage={"Sumbit"}/>
                            </Button>
                        </DialogFooter>
                    </div>
                )}
            </form>
        </Form>
    )
}





const editProjectSchema = z.object({
    name: z.string().optional(),
    startDate: z.date().optional(),
    endDate: z.date().optional(),
})

type EditProjectFormProps = {
    project: Project
    reloadData: (value: boolean) => void
    employees: Employee[]
    services: Service[]
    rePopulateRecord: (pk: string) => void
}

export const EditProjectForm: React.FC<EditProjectFormProps> = (
    {
        project,
        employees,
        services,
        rePopulateRecord
    }
) => {
    const intl = useIntl();
    const axiosInstance = useAxiosInstance();
    const form = useForm<z.infer<typeof editProjectSchema>>({
        resolver: zodResolver(editProjectSchema)
    })
    const [apiErrors, setApiErrors] = useState<ServerErrorsType>({});
    const errors = useServerErrors(apiErrors, form);
    const closeModal = useRef<HTMLElement>();
    const [tab, setTab] = useState<"Information" | "Services" | "Employees">("Information");
    const [tabErrors, setTabErrors] = useState<{place?: "Information" | "Services" | "Employees", statement?: string}[]>([]);
    const [servicesList, setServicesList] = useState<{
        service: Service
        value: string
        label: string
        icon?: React.ComponentType<{ className?: string }>
    }[]>([])
    const [selectedServicesMulti, setSelectedServicesMulti] = useState<string[]>([]);
    const [addedServices, setAddedServices] = useState<Service[]>([])
    const [employeesList, setEmployeesList] = useState<{
        employee: Employee
        value: string
        label: string
        icon?: React.ComponentType<{ className?: string }>
    }[]>([])
    const [selectedEmployeesMulti, setSelectedEmployeesMulti] = useState<string[]>([]);
    const [addedEmployees, setAddedEmployees] = useState<Employee[]>([])

    // service functions
    const handleServicesAdd = (values: string[]) => {

        setAddedServices((prevState) => {
            let isSelected = prevState.filter(service => values.includes(service.pk.toString()));

            if (isSelected.length) {
                toast({
                    title: intl.formatMessage({id: "toast.informational", defaultMessage: "Info!"}),
                    description: intl.formatMessage({id: "toast.informational.input_data_missing", defaultMessage: "Check again the input data you are trying to send."}),
                    variant: "informational"
                })

                return prevState;
            }

            let selectedServices =
                servicesList
                    .filter(service => values.includes(service.service.pk.toString()))
                    .map(elem => elem.service);

            setSelectedServicesMulti([])

            return prevState.concat(selectedServices);
        });
    };

    const onServiceToTrash = (pk: string) => {
        setAddedServices((prevState) => {
            return prevState.filter(service => service.pk.toString() !== pk);
        });
    }

    // employee functions
    const handleEmployeesAdd = (values: string[]) => {
        setAddedEmployees((prevState) => {
            let isSelected = prevState.filter(employee => values.includes(employee.userUUID.toString()));

            if (isSelected.length) {
                toast({
                    title: intl.formatMessage({id: "toast.informational", defaultMessage: "Info!"}),
                    description: intl.formatMessage({id: "toast.informational.input_data_missing", defaultMessage: "Check again the input data you are trying to send."}),
                    variant: "informational"
                })

                return prevState;
            }

            let selectedEmployees =
                employeesList
                    .filter(employee => values.includes(employee.employee.userUUID.toString()))
                    .map(elem => elem.employee);

            setSelectedEmployeesMulti([])

            return prevState.concat(selectedEmployees);
        });
    };

    const onEmployeeToTrash = (pk: string) => {
        setAddedEmployees((prevState) => {
            return prevState.filter(employee => employee.userUUID.toString() !== pk);
        });
    }

    const onSubmit = (values: z.infer<typeof editProjectSchema>) => {
        const data = {
            ...values,
            startDate: values.startDate ? moment(values.startDate).format("YYYY-MM-DD") : null,
            endDate: values.endDate ? moment(values.endDate).format("YYYY-MM-DD") : null,
            employees: addedEmployees,
            services: addedServices
        }

        axiosInstance.put(projectsUrl + `${project.pk}/`, data)
            .then((res) => {
                rePopulateRecord(project.pk);
                setApiErrors({});
                closeModal.current?.click();
                toast({
                    title: intl.formatMessage({id: "toast.success", defaultMessage: "Great!"}),
                    description: intl.formatMessage({id: "toast.success.updatedSuccessfully", defaultMessage: "The update was completed successfully."})
                });
            })
            .catch((err) => {
                setApiErrors(err.response.data);
            })
    }

    const onTabChange = (value: string) => {
        setTab(value as "Information" | "Services" | "Employees");
    }

    const setServiceOptions = () => {
        if (services.length) {
            setServicesList(prevState => {
                return services
                    .filter((service) => {
                        let addedServicesArrayOfPk = addedServices.map(elem => elem.pk.toString())
                        return !addedServicesArrayOfPk.includes(service.pk.toString())
                    })
                    .map((service) => ({
                    service,
                    label: service.name,
                    value: service.pk.toString()
                }))
            })
        }
    }

    const setEmployeeOptions = () => {
        if (employees.length) {
            setEmployeesList(prevState => {
                return employees
                    .filter((employee) => {
                        let addedEmployeesArrayOfUUID = addedEmployees.map(elem => elem.userUUID.toString())
                        return !addedEmployeesArrayOfUUID.includes(employee.userUUID.toString())
                    })
                    .map((employee) => ({
                        employee,
                        label: employee.fullName,
                        value: employee.userUUID.toString()
                    }))
            })
        }
    }

    useEffect(() => {
        setServiceOptions();
    }, [addedServices]);

    useEffect(() => {
        setEmployeeOptions();
    }, [addedEmployees]);

    useEffect(() => {
        if (project) {
            let {employees, services} = project;
            form.reset({
                name: project.name,
                startDate: project.startDate ? new Date(project.startDate) : undefined,
                endDate: project.endDate ? new Date(project.endDate) : undefined,
            });
            if (employees) setAddedEmployees(employees);
            if (services) setAddedServices(services);
        }
    }, [project]);

    useEffect(() => {
        if (errors.fieldErrors) {
            let fieldErrors = Object.keys(errors.fieldErrors);
            Object.keys(form.getValues()).forEach((err) => {
                if (fieldErrors.includes(err)) {
                    setTabErrors(prevState => {
                        return [
                            ...prevState,
                            {
                                place: "Information",
                                statement: "* errors in first tab : Information"
                            }
                        ]
                    })
                }
            })
        }
    }, [errors.fieldErrors]);

    useEffect(() => {
        setTabErrors(prevState => {
            return prevState.filter(err => err.place === "Information");
        });
    }, [tab]);

    return (
        <Form {...form}>
            <form method="post" onSubmit={form.handleSubmit(onSubmit)} className="flex flex-col gap-4">
                <Tabs value={tab} onValueChange={onTabChange}>
                    <TabsList className="mb-4">
                        <TabsTrigger value="Information">
                            <FormattedMessage id={"project.projects.information"} defaultMessage={"Information"}/>
                        </TabsTrigger>
                        <TabsTrigger value="Services">
                            <FormattedMessage id={"project.projects.services"} defaultMessage={"Services"}/>
                        </TabsTrigger>
                        <TabsTrigger value="Employees">
                            <FormattedMessage id={"project.projects.employees"} defaultMessage={"Employees"}/>
                        </TabsTrigger>
                    </TabsList>
                    <TabsContent value="Information" className="flex flex-col gap-4">
                        <FormField
                            control={form.control}
                            name="name"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>
                                        <FormattedMessage
                                            id={"project.project"}
                                            defaultMessage={"Project"}
                                        />
                                    </FormLabel>
                                    <FormControl>
                                        <Input placeholder={intl.formatMessage({id: "project.projects.enterProjectName", defaultMessage: "Enter project name"})} {...field}/>
                                    </FormControl>
                                    <FormMessage/>
                                </FormItem>
                            )}
                        />
                        <div className="flex flex-row gap-2">
                            <div className="grow">
                                <FormField
                                    control={form.control}
                                    name="startDate"
                                    render={({ field }) => (
                                        <FormItem className="flex flex-col">
                                            <FormLabel>
                                                <FormattedMessage
                                                    id={"project.projects.startDate"}
                                                    defaultMessage={"Start Date"}
                                                />
                                            </FormLabel>
                                            <FormControl>
                                                <DatePicker area-label={field.name} date={field.value} setDate={field.onChange}/>
                                            </FormControl>
                                            <FormMessage/>
                                        </FormItem>
                                    )}
                                />
                            </div>
                            <div className="grow">
                                <FormField
                                    control={form.control}
                                    name="endDate"
                                    render={({ field }) => (
                                        <FormItem className="flex flex-col">
                                            <FormLabel>
                                                <FormattedMessage
                                                    id={"project.projects.endDate"}
                                                    defaultMessage={"End Date"}
                                                />{" "}<span className="text-xs text-slate-500">(optional)</span>
                                            </FormLabel>
                                            <FormControl>
                                                <DatePicker area-label={field.name} date={field.value} setDate={field.onChange}/>
                                            </FormControl>
                                            <FormMessage/>
                                        </FormItem>
                                    )}
                                />
                            </div>
                        </div>
                    </TabsContent>

                    <TabsContent value="Services" className="flex flex-col gap-4">
                        <FormLabel>
                            <FormattedMessage
                                id={"project.projects.services"}
                                defaultMessage={"Services"}
                            />
                        </FormLabel>

                        <div className="flex flex-row items-center gap-2">
                            <MultiSelect
                                options={servicesList}
                                onValueChange={setSelectedServicesMulti}
                                defaultValue={selectedServicesMulti}
                                placeholder={intl.formatMessage({id: "project.projects.servicePlaceholder", defaultMessage: "Select a service"})}
                                variant="inverted"
                                maxCount={3}
                                modalPopover={true}
                                hideOptions={true}
                            />
                            <Button
                                variant="taimDefault2"
                                type={"button"}
                                onClick={(event) => {
                                    if (selectedServicesMulti.length) {
                                        handleServicesAdd(selectedServicesMulti)
                                    } else {
                                        toast({
                                            title: intl.formatMessage({id: "toast.informational", defaultMessage: "Info!"}),
                                            description: intl.formatMessage({id: "toast.informational.input_data_missing", defaultMessage: "Check again the input data you are trying to send."}),
                                            variant: "informational"
                                        })
                                    }
                                }}
                            ><FormattedMessage id={"add"} defaultMessage={"Add"} /></Button>
                        </div>

                        <div className="flex flex-col gap-4 p-4 border rounded">
                            {addedServices.length ? (
                                addedServices.map((service, index) => (
                                    <div className="flex flex-row justify-between px-2" key={index}>
                                        <div>{`${index + 1}. ${service.name}`}</div>
                                        <Trash
                                            color={"red"}
                                            size={20}
                                            className="cursor-pointer"
                                            onClick={() => onServiceToTrash(String(service.pk))}
                                        />
                                    </div>
                                ))
                            ) : (
                                <FormattedMessage id={"project.projects.serviceNotice"} defaultMessage={"Pick services from dropdown above"}/>
                            )}
                        </div>

                        <ErrorMessageList errors={
                            (() => {
                                let uniqueErrors = new Set<string>();
                                tabErrors.forEach(err => {
                                    if (err.statement && err.place !== "Employees") {
                                        uniqueErrors.add(err.statement);
                                    }
                                });
                                return Array.from(uniqueErrors);
                            })()
                        }/>
                    </TabsContent>

                    <TabsContent value="Employees" className="flex flex-col gap-4">
                        <FormLabel>
                            <FormattedMessage
                                id={"project.projects.employees"}
                                defaultMessage={"Employees"}
                            />
                        </FormLabel>
                        <div className="flex flex-row items-center gap-2">
                            <MultiSelect
                                options={employeesList}
                                onValueChange={setSelectedEmployeesMulti}
                                defaultValue={selectedEmployeesMulti}
                                placeholder={intl.formatMessage({id: "project.projects.employeesPlaceholder", defaultMessage: "Select an employee"})}
                                variant="inverted"
                                maxCount={3}
                                modalPopover={true}
                                hideOptions={true}
                            />
                            <Button
                                variant="taimDefault2"
                                type={"button"}
                                onClick={(event) => {
                                    if (selectedEmployeesMulti.length) {
                                        handleEmployeesAdd(selectedEmployeesMulti)
                                    } else {
                                        toast({
                                            title: intl.formatMessage({id: "toast.informational", defaultMessage: "Info!"}),
                                            description: intl.formatMessage({id: "toast.informational.input_data_missing", defaultMessage: "Check again the input data you are trying to send."}),
                                            variant: "informational"
                                        })
                                    }
                                }}
                            ><FormattedMessage id={"add"} defaultMessage={"Add"} /></Button>
                        </div>

                        <div className="flex flex-col gap-4 p-4 border rounded">
                            {addedEmployees.length ? (
                                addedEmployees.map((employee, index) => (
                                    <div className="flex justify-between items-center px-2">
                                        <RegularNameAvatar username={employee.fullName}/>
                                        <div className="text-gray-500 capitalize w-1/2 flex justify-between">
                                            <span>{employee.roleName || nullOrUndefined}</span>
                                            <Trash
                                                color={"red"}
                                                size={20}
                                                className="cursor-pointer"
                                                onClick={() => onEmployeeToTrash(String(employee.userUUID))}
                                            />
                                        </div>
                                    </div>
                                ))
                            ) : (
                                <FormattedMessage id={"project.projects.employeesNotice"} defaultMessage={"Pick employees from dropdown above"}/>
                            )}
                        </div>

                        <ErrorMessageList errors={
                            (() => {
                                let uniqueErrors = new Set<string>();
                                tabErrors.forEach(err => {
                                    if (err.statement && err.place !== "Services") {
                                        uniqueErrors.add(err.statement);
                                    }
                                });
                                return Array.from(uniqueErrors);
                            })()
                        }/>

                    </TabsContent>
                </Tabs>

                <ErrorMessageList errors={errors.nonFieldErrors} />
                <ErrorMessageList errors={errors.detailErrors} />

                <DialogFooter className="justify-between space-x-2 px-0 pb-0">
                    <DialogClose ref={closeModal as any} asChild>
                        <Button variant="outline">
                            <FormattedMessage id={"button.cancel"} defaultMessage={"Cancel"}/>
                        </Button>
                    </DialogClose>
                    <Button
                        variant="taimDefault"
                        type="submit"
                    ><FormattedMessage id={"button.submit"} defaultMessage={"Submit"}/></Button>
                </DialogFooter>
            </form>
        </Form>
    )
}