import React, {useContext, useEffect, useState} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import {ProjectsPayload, projectsUrl, useFetchProjects} from "ProjectManagement/Controller/projects-controller";
import {ProjectFormatted, ProjectsTable} from "ProjectManagement/DataTables/projects-table";
import {ProjectDetail} from "ProjectManagement/DetailViews/Project";
import {Button} from "components/button";
import {CreateProjectForm, EditProjectForm} from "ProjectManagement/Forms/project-forms";
import {Employee} from "ProjectManagement/Controller/employees-controller";
import {Service} from "ProjectManagement/Controller/services-controller";
import {useAxiosInstance} from "Core/utilities/AxiosInstance";
import {projectManagementUrl} from "ProjectManagement";
import {MainTab, PageActions, PageState} from "Core/layout/page-shape";
import {useLocation} from "react-router-dom";
import {Badge} from "components/badge";
import statusVariants from "Core/functions/status-variants";
import moment from "moment";
import {toast} from "components/use-toast";
import {PermissionContext} from "../../Core/utilities/PermissionProvider";


const projectsPayloadInitialState: ProjectsPayload = {
    length: 10,
    start: 0,
    ordering: "",
    "filter.startDate": "",
    "filter.endDate": ""
};

type ProjectsType = {
    name: string
    pagesState?: PageState[]
}


export const Projects: React.FC<ProjectsType> = ({name, pagesState}
) => {
    const intl = useIntl();
    const location = useLocation();
    const [
        projectsPayload,
        setProjectsPayload
    ] = useState<ProjectsPayload>(projectsPayloadInitialState);
    const {
        projectsRequest,
        loadingProjects,
        shouldRefetchProjects
    } = useFetchProjects(projectsPayload);
    const [
        projectDetail,
        setProjectDetail
    ] = useState<ProjectFormatted | null>(null);
    const [
        formattedData,
        setFormattedData
    ] = useState<ProjectFormatted[]>([]);
    const [employees, setEmployees] = useState<Employee[]>([]);
    const [services, setServices] = useState<Service[]>([]);
    const axiosInstance = useAxiosInstance();
    const permissionContext = useContext(PermissionContext);

    const fetchEmployees = () => {
        axiosInstance.get(projectManagementUrl + 'employees/')
            .then((res) => setEmployees(res.data))
            .catch((err) => console.log(err))
    }
    const fetchServices = () => {
        axiosInstance.get(projectManagementUrl + 'services/')
            .then((res) => setServices(res.data))
            .catch((err) => console.log(err))
    }

    useEffect(() => {
        let currPage = pagesState?.find(page => page.pageName === name);
        if (currPage && currPage.reFetch) {
            shouldRefetchProjects(true);
        }

        fetchEmployees();
        fetchServices();
    }, [pagesState, permissionContext.isAdmin]);

    useEffect(() => {
        setProjectsPayload((prevState) => {
            return {
                ...prevState,
                search: location.state?.projectName ?? ""
            }
        });
    }, [location]);

    useEffect(() => {
        fetchEmployees();
        fetchServices();
    }, []);

    const rePopulateRecord = (pk: string) => {
        axiosInstance.get(projectsUrl + `${pk}/`)
            .then((res) => {
                setFormattedData((prevState) => {
                    return prevState.map((project) => {
                        if (project.pk === pk) {
                            let updatedRecord = {
                                ...project,
                                ...res.data,
                                statusBadge: <Badge variant={statusVariants(res.data.status)}>{res.data.status}</Badge>,
                                startDate: res.data.startDate ? moment(res.data.startDate).format("ddd DD MMM YYYY") : null,
                                endDate: res.data.endDate ? moment(res.data.endDate).format("ddd DD MMM YYYY") : null,
                                actions: [
                                    {
                                        type: "edit",
                                        content: (
                                            <EditProjectForm
                                                project={res.data}
                                                reloadData={shouldRefetchProjects}
                                                employees={employees}
                                                services={services}
                                                rePopulateRecord={rePopulateRecord}
                                            />
                                        ),
                                        dialogContentClassName: "min-w-[525px]"
                                    },
                                    {
                                        type: "delete",
                                        handleAction: () => handleDelete(project.pk)
                                    },
                                ]
                            }
                            setProjectDetail(updatedRecord);
                            return updatedRecord;
                        }
                        return project
                    })
                })
            })
    }

    useEffect(() => {
        const formatData: ProjectFormatted[] = projectsRequest?.data.map((project) => {
            return {
                ...project,
                rowID: `${project.pk}`,
                statusBadge: <Badge variant={statusVariants(project.status)}>{project.status}</Badge>,
                startDate: project.startDate ? moment(project.startDate).format("ddd DD MMM YYYY") : null,
                endDate: project.endDate ? moment(project.endDate).format("ddd DD MMM YYYY") : null,
                actions: [
                    {
                        type: "edit",
                        content: (
                            <EditProjectForm
                                project={project}
                                reloadData={shouldRefetchProjects}
                                employees={employees}
                                services={services}
                                rePopulateRecord={rePopulateRecord}
                            />
                        ),
                        dialogContentClassName: "min-w-[525px]"
                    },
                    {
                        type: "delete",
                        handleAction: () => handleDelete(project.pk)
                    },
                ]
            }
        });

        let selectedProject = formatData.find(elem => elem.name === location.state?.projectName);

        if (location.state?.projectName && selectedProject) {
            setProjectDetail(selectedProject)
        }

        setFormattedData(formatData);
    }, [projectsRequest.data]);

    const handleDelete = (pk: string) => {
        axiosInstance.delete(projectsUrl + `${pk}/`)
            .then((res) => {
                toast({
                    title: intl.formatMessage({id: "toast.success", defaultMessage: "Great!"}),
                    description: intl.formatMessage({id: "toast.success.deletedSuccessfully", defaultMessage: "The item has been deleted successfully."})
                });
                setProjectDetail(null);
                shouldRefetchProjects(true);
            })
            .catch((err) => {
                toast({
                    variant: "destructive",
                    title: intl.formatMessage({id: "toast.error", defaultMessage: "Error!"}),
                    description: err.response.data?.detail ?? intl.formatMessage({id: "toast.error.unableToDelete", defaultMessage: "There was an error deleting the item. Please try again."})
                });
            })
    }

    return (
        <MainTab
            type={"mainAndDetail"}
            name={name}
            main={{
                title: intl.formatMessage({id: "table.list", defaultMessage: "List"}),
                content: (
                    <ProjectsTable
                        payload={projectsPayload}
                        setPayload={setProjectsPayload}
                        detailData={projectDetail}
                        setDetailData={setProjectDetail}
                        loading={loadingProjects}
                        request={projectsRequest}
                        reloadData={shouldRefetchProjects}
                        employees={employees}
                        services={services}
                        formattedProjects={formattedData}
                    />
                )
            }}
            detail={{
                title: intl.formatMessage({id: "project.projects.detail", defaultMessage: "Project Detail"}),
                content: (
                    <ProjectDetail
                        project={projectDetail}
                        loading={loadingProjects}
                    />
                )
            }}
            actions={[
                {
                    type: "modal",
                    button: (
                        <Button variant={"taimDefault"}>
                            <FormattedMessage id={"project.projects.createProject"} defaultMessage={"Create Project"}/>
                        </Button>
                    ),
                    header: intl.formatMessage({id: "project.projects.createProject", defaultMessage: "Create Project"}),
                    children: (
                        <CreateProjectForm
                            reloadData={shouldRefetchProjects}
                            employees={employees}
                            services={services}
                        />
                    )
                }
            ].filter((elem) => {
                if (permissionContext.isAdmin) return elem
            }) as PageActions[]}
        />
    )
}