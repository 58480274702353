import React, {useLayoutEffect} from "react";
import {Employee} from "Dashboard/Controllers/Admin/daily-track-controller";
import {Badge} from "components/badge";
import {cn} from "lib/utils";
import {Circle} from "@phosphor-icons/react";
import {transformActivities} from "./transform-activities";
import {timelineHours} from "./constants";
import {Usertrack} from "./usertrack";
import moment from "moment";

type CalendarProps = {
    employees: Employee[]
}


const Calendar: React.FC<CalendarProps> = ({employees}) => {
    const handleScroll = () => {
        let currentHour = moment().get("hour");
        const scrollElement = document.querySelector(".scrollable");

        if (scrollElement) {
            const totalScrollWidth = scrollElement.scrollWidth;
            const intervalWidth = Math.floor(totalScrollWidth / 24);

            scrollElement.scrollLeft = intervalWidth * (currentHour > 0 ? currentHour - 1 : currentHour);
        }
    };


    useLayoutEffect(() => {
        if (document.querySelector(".scrollable")) handleScroll();
    }, [employees]);

    return (
        <div className="grid grid-cols-11 gap-3 m-4 overflow-y-hidden">
            <div className="col-span-2">
                <div className="h-[30px]"></div>
                {employees?.length && employees.map((employee,index) =>
                    <div className="flex flex-col justify-center items-start h-24 relative overflow-auto no-scrollbar" key={index}>
                        <div className="text-black font-bold leading-6 capitalize">{employee?.fullName}</div>
                        {employee.activities.find(_ => _.type === "service") ? (
                            <div className="flex flex-col h-fit max-h-14 w-full overflow-y-auto">
                                {employee?.activities.map((activity) => {
                                    if (activity.type !== "service") return;
                                    return (
                                        <div className="flex flex-row gap-1 items-start text-xs text-gray-500 capitalize">
                                            <div className="h-4 flex items-center">
                                                <Circle color="#9CA3AF" weight={"fill"} size={6}/>
                                            </div>
                                            <span>{activity.name}</span>
                                        </div>
                                    )
                                })}
                            </div>
                        ) : null}
                    </div>
                )}
            </div>
            <div className="col-span-8 overflow-x-auto scroll-smooth scrollable no-scrollbar">
                <div className="h-[30px] flex flex-row">
                    {timelineHours.map((timelineHour, index) => (
                        <div key={index} className="flex justify-center items-center min-w-[60px]">
                            <div className="text-xs text-gray-400">{timelineHour}</div>
                        </div>
                    ))}
                </div>
                {employees?.length && employees.map((employee,index) =>
                    <Usertrack key={index} activities={transformActivities(employee.activities)}/>
                )}
            </div>
            <div className="col-span-1">
                <div className="h-[30px]"></div>
                {employees.length && employees.map((employee,index) =>
                    <div className="h-24 flex items-center" key={index}>
                        <Badge
                            className={cn(
                                "w-full py-3 justify-center uppercase text-center",
                                (employee?.status !== "Check In" && employee?.status !== "On Break") && "border-gray-100 bg-gray-50 text-gray-500"
                            )}
                            variant={"secondary2"}
                        >{employee?.status}</Badge>
                    </div>
                )}
            </div>
        </div>
    );
}

export default Calendar;