import React, {useEffect, useState} from 'react';
import {Routes, Route, BrowserRouter, useLocation, Outlet} from "react-router-dom";
import AppLayout from "./Core/layout/app-layout";
import Dashboard from "./Dashboard";
import Attendance from "./AttendanceManagement";
import Timesheet from "./TimesheetManagement";
import EmployeeManagement from "./EmployeeManagement";
import {ProjectManagement} from "./ProjectManagement";
import {FallbackPage} from "./Core/AppSections/fallback-page";
import {ComingSoonPage} from "./Core/AppSections/coming-soon-page";
import AuthenticationPage from "./Authentication";
import {Dialog, DialogContent, DialogDescription, DialogHeader, DialogTitle} from "./components/dialog";
import {WifiHigh, WifiSlash} from "@phosphor-icons/react";
import {AuthMiddleware} from "./Core/utilities/Authenticator";
import {SystemsManagement} from "./SystemsManagement";
import ImportManagement from "./ImportManagement";
import ShiftManagement from "./ShiftManagement";
import {TaimDatePicker} from "./Core/components/DatePickers/taim-date-picker";
import {MultiSelect} from "./Core/components/multi-select";
import { Cat, Dog, Fish, Rabbit, Turtle } from "lucide-react";

const NetworkNotice = () => {
    const location = useLocation()
    const [online, setOnline] = useState<boolean>(window.navigator.onLine)
    const [open, setOpen] = useState<boolean>(false)

    const handleOnline = () => {
        setOnline(true)
        handleOpen()
        setTimeout(() => {
            setOpen(false)
        }, 3000)
    };
    const handleOffline = () => {
        setOnline(false)
        handleOpen()
    };

    const handleOpen = () => setOpen(!open)

    useEffect(() => {
        window.addEventListener('online', handleOnline);
        window.addEventListener('offline', handleOffline);

        return () => {
            window.removeEventListener('online', handleOnline);
            window.removeEventListener('offline', handleOffline);
        };
    }, []);

    useEffect(() => {
        if (!online) handleOffline()
    }, [location]);

    return (
        <Dialog open={open} onOpenChange={handleOpen}>
          <DialogContent onInteractOutside={(e) => e.preventDefault()}>
            <DialogHeader>
              <DialogTitle>{online ? "Network Restored" : "Network Disconnected"}</DialogTitle>
              <DialogDescription className="flex flex-row items-center p-6">
                <div className="mr-4">
                  {online ? <WifiHigh size={72}/> : <WifiSlash size={72}/>}
                </div>
                <div className="">
                  {online ?
                    <p className="text-lg">Your connection is back.</p> :
                    <p className="text-lg">Oops! Looks like you're offline.</p>
                  }
                  {!online &&
                    <p className="text-sm text-gray-600 mt-2">Please check your network settings and try again.</p>
                  }
                </div>
              </DialogDescription>
            </DialogHeader>
          </DialogContent>
        </Dialog>
    )
}


const frameworksList = [
  { value: "react", label: "React" },
  { value: "angular", label: "Angular", icon: Cat },
  { value: "vue", label: "Vue", icon: Dog },
  { value: "svelte", label: "Svelte", icon: Rabbit },
  { value: "ember", label: "Ember", icon: Fish },
];

function App() {
    const [selectedFrameworks, setSelectedFrameworks] = useState<string[]>(["react", "angular"]);

    return (
        <>
            <BrowserRouter>
                <Routes>
                    <Route element={
                        <>
                            <NetworkNotice/>
                            <Outlet/>
                        </>
                    }>
                        <Route path="/account-management/login" element={<AuthenticationPage/>}/>
                        <Route element={<AuthMiddleware><AppLayout/></AuthMiddleware>}>
                            <Route path="/" element={<Dashboard/>}/>
                            <Route path="/employee-management" element={<EmployeeManagement/>}/>
                            <Route path="/project-management" element={<ProjectManagement/>}/>
                            <Route path="/attendance-management" element={<Attendance/>}/>
                            <Route path="/shift-management" element={<ShiftManagement/>}/>
                            <Route path="/systems-management" element={<SystemsManagement/>}/>
                            <Route path="/import-management" element={<ImportManagement/>}/>
                            <Route path="/timesheet-management" element={<Timesheet/>}/>
                            <Route path="/help-center" element={<ComingSoonPage/>}/>
                            <Route path="/contact-us" element={<ComingSoonPage/>}/>
                            <Route path="/testing-page" element={
                                <div className="flex flex-col gap-10">
                                    <TaimDatePicker/>
                                    <div className="p-4 max-w-xl">
                                      <h1 className="text-2xl font-bold mb-4">Multi-Select Component</h1>
                                      <MultiSelect
                                        options={frameworksList}
                                        onValueChange={setSelectedFrameworks}
                                        defaultValue={selectedFrameworks}
                                        placeholder="Select frameworks"
                                        variant="inverted"
                                        animation={2}
                                        maxCount={3}
                                      />
                                      <div className="mt-4">
                                        <h2 className="text-xl font-semibold">Selected Frameworks:</h2>
                                        <ul className="list-disc list-inside">
                                          {selectedFrameworks.map((framework) => (
                                            <li key={framework}>{framework}</li>
                                          ))}
                                        </ul>
                                      </div>
                                    </div>
                                </div>
                            }/>
                        </Route>
                        <Route path="*" element={<FallbackPage/>} />
                    </Route>
                </Routes>
            </BrowserRouter>
        </>
    );
}

export default App;
