import {Dispatch, SetStateAction, useContext, useEffect, useState} from "react";
import {useAxiosInstance} from "Core/utilities/AxiosInstance";
import {status} from "Core/functions/status-variants";
import {PermissionContext} from "../../../Core/utilities/PermissionProvider";

const dailyTrackUrl = "dashboard/employees/";

export type Activity = BreaktimeActivity | WorkingtimeActivity | ServiceActivity | EmptyActivity

interface ActivityCredentials {
    pk?: string | number
    endDatetime: string | null
    startDatetime: string
}

export interface BreaktimeActivity extends ActivityCredentials {
    type: "breakTime"
}

export interface WorkingtimeActivity extends ActivityCredentials {
    type: "workingTime"
}

export interface ServiceActivity extends ActivityCredentials {
    type: "service"
    name: string
    duration: string
    durationNet: string
    attendanceID: string | number
}

export interface EmptyActivity extends ActivityCredentials {
    type: "empty"
}

export type Employee = {
    fullName: string,
    status: status,
    activities: Activity[],
}

export type DailyTrackPayload = {
    start: number
    length: number
    search?: string
    ordering?: string
    "filter.status"?: string
}

export type DailyTrackRequest = {
    recordsTotal: number
    recordsFiltered: number
    next: string
    previous: string
    data: Employee[]
}

export const useFetchDashboardDailyTrack = (
    payload: DailyTrackPayload,
    reloadDashboard: boolean
) => {
    const [loadingDailyTrack, setLoading] = useState<boolean>(false);
    const [shouldReFetch, reFetchDailyTrack] = useState(false)
    const [dailyTrack, setData] = useState<DailyTrackRequest>({
        recordsTotal: 0,
        recordsFiltered: 0,
        next: "",
        previous: "",
        data: []
    });
    const [error, setError] = useState(null);
    const axiosInstance = useAxiosInstance();
    const permissionContext = useContext(PermissionContext);

    const fetchData = () => {
        setLoading(true);
        axiosInstance.get(dailyTrackUrl,{
            params: payload
        })
            .then((res) => {
                setData(res.data);
            })
            .catch((err) => {
                setError(err);
            })
            .finally(() => {
                setLoading(false)
                reFetchDailyTrack(false)
            })
    }


    useEffect(() => {
        fetchData();
    }, [payload, shouldReFetch, permissionContext.isAdmin, reloadDashboard]);

    return { dailyTrack, loadingDailyTrack, error, reFetchDailyTrack };
}