import { zodResolver } from "@hookform/resolvers/zod"
import { useForm } from "react-hook-form"
import * as z from "zod"

import {
    Form, FormControl,
    FormField,
    FormItem,
    FormLabel,
    FormMessage,
} from "../../components/form"
import React, {Dispatch, SetStateAction, useContext, useEffect, useRef, useState} from "react";
import {useAxiosInstance} from "../../Core/utilities/AxiosInstance";
import {Button} from "../../components/button";
import {DialogClose, DialogFooter} from "../../components/dialog";
import {
    Employee,
    vacation,
    VacationRecord,
    vacationSubstitute,
    vacationsUrl
} from "../Controllers/vactions-controller";
import {attendanceManagementUrl} from "../index";
import {toast} from "../../components/use-toast";
import {FormattedMessage, useIntl} from "react-intl";
import SearchInput from "../../Core/components/search-input";
import {ServerErrorsType, useServerErrors} from "../../Core/functions/use-server-errors";
import ErrorMessageList from "../../Core/components/ErrorMessageList";
import moment from "moment/moment";
import {Tabs, TabsContent, TabsList, TabsTrigger} from "../../components/tab";
import {useEditVacations} from "./absences/hooks";
import {
    TDayRecord,
    TPortionType,
    TVacationType,
} from "./absences/interfaces";
import {
    VacationsFirstStep,
    VacationsPreview,
    VacationsSecondStep
} from "./absences/components";
import {portionTypes, vacationTypes} from "./absences/constants";
import {calculateDuration} from "../../Core/functions/calculate_durations";
import {useSteps} from "../../Core/functions/use-steps";
import {nullOrUndefined} from "../../Core/constants/variables";
import DetailViewCard from "../../Core/components/detail-view-card";
import {Table, TableBody, TableCell, TableHead, TableHeader, TableRow} from "../../components/table";
import {cn} from "../../lib/utils";
import {Select, SelectContent, SelectItem, SelectTrigger, SelectValue} from "../../components/select";
import {TimePicker} from "../../components/date-time-picker/time-picker";
import {TimeValue} from "react-aria";
import {Trash} from "@phosphor-icons/react";
import {RadioGroup, RadioGroupItem} from "../../components/radio-group";
import {Label} from "../../components/label";
import {Skeleton} from "../../components/skeleton";
import {getRandomInt} from "../../Core/functions/random";
import {PermissionContext} from "../../Core/utilities/PermissionProvider";

export const createVacationFormSchema = z.object({
    userUUID: z.string(),
    dateFrom: z.date().optional(),
    dateTo: z.date().optional(),
    start: z.any(),
    end: z.any(),
    ignoreWeekends: z.boolean().default(true).optional(),
    substituteUUID: z.any(),
    requestType: z.any(),
    records: z.array(
        z.object({
            type: z.string().optional(),
            portion: z.string().optional(),
            substitute: z.object({
                userUUID: z.string().optional()
            }).optional(),
            startTime: z.any(),
            endTime: z.any(),
            placeholder: z.any(),
        })
    ).optional(),
    sickNoteSubmitted: z.any()
})


type CreateVacationProps = {
    reFetch: Dispatch<SetStateAction<boolean>>
    setModalWidth: React.Dispatch<React.SetStateAction<'s' | 'l' | 'xl'>>
}

export const CreateVacation: React.FC<CreateVacationProps> = ({reFetch, setModalWidth}) => {
    const intl = useIntl();
    const axiosInstance = useAxiosInstance();
    const [employeeName, setEmployeeName] = useState<string>()
    const [employees, setEmployees] = useState<Employee[]>([]);
    const [substitutes, setSubstitutes] = useState<vacationSubstitute[]>([]);
    const form = useForm<z.infer<typeof createVacationFormSchema>>({
        resolver: zodResolver(createVacationFormSchema),
        defaultValues: {
            ignoreWeekends: true,
            sickNoteSubmitted: false,
        }
    })
    const [apiErrors, setApiErrors] = useState<ServerErrorsType>({});
    const errors = useServerErrors(apiErrors, form);
    const closeModal = useRef<HTMLElement>()
    const stepper = useSteps(["step_1", "step_2", "preview"], "step_1")
    const [dataToManipulate, setDataToManipulate] = useState<
        {dateFrom: string, dateTo: string, records: TDayRecord[]}
    >()
    const [dataToSubmit, setDataToSubmit] = useState<
        {dateFrom: string, dateTo: string, records: TDayRecord[]}
    >()
    const [duration, setDuration] = useState<string>("0")
    const [dayDuration, setDayDuration] = useState<{duration: moment.Duration, durationString: string}>({
        duration: moment.duration(0),
        durationString: "00h 00m"
    })

    function onSubmit() {
        const data = form.getValues()
        axiosInstance.post(attendanceManagementUrl + `employees/${data.userUUID}/vacations/`, dataToSubmit)
            .then((res) => {
                setApiErrors({})
                reFetch(true);
                closeModal.current?.click();
                toast({
                    title: intl.formatMessage({id: "toast.success", defaultMessage: "Great!"}),
                    description: intl.formatMessage({id: "toast.success.actionCompleted", defaultMessage: "Your action was completed successfully."})
                });
            })
            .catch((err) => {
                setApiErrors(err.response?.data)
            })
    }

    const handleFirstStep = () => {
        setApiErrors({})
        let formData = form.getValues()
        let data = {
            ...formData,
            ...(formData.dateFrom && {dateFrom: moment(formData.dateFrom).format('YYYY-MM-DD')}),
            ...(formData.dateTo && {dateTo: moment(formData.dateTo).format('YYYY-MM-DD')}),
            ...(formData.start && {startTime: moment(formData.start).format('HH:mm')}),
            ...(formData.end && {endTime: moment(formData.end).format('HH:mm')}),
        }

        axiosInstance.post(attendanceManagementUrl + `employees/${data.userUUID}/vacations/step-one/`, data)
            .then((res) => {
                let responseData: {dateFrom: string, dateTo: string, records: TDayRecord[]} = res.data
                setApiErrors({})
                stepper.goToNextStep()
                setDataToManipulate(() => {
                    return {
                        ...responseData,
                        records: responseData?.records.map((record: TDayRecord) => ({
                            ...record,
                            type: formData.requestType ? formData.requestType : null
                        }))
                    };
                })
                handleEmployeeName()
            })
            .catch((err) => {
                setApiErrors(err.response?.data)
            })
    }

    const handleSecondStep = () => {
        setApiErrors({})
        let formData = form.getValues()
        let data = {...dataToManipulate}
        data = {
            ...data,
            records: dataToManipulate?.records.filter(record => !record.placeholder).map((record, index) => {
                let substitute: vacationSubstitute | undefined = substitutes.find(elem => elem.userUUID === formData.substituteUUID)
                let noSubsitute = !record.substitute?.userUUID
                let recordFromForm = formData.records?.find((nestedIndex) => index === nestedIndex)

                return {
                    ...record,
                    ...(noSubsitute && substitute && {
                        substitute: {
                            userUUID: substitute.userUUID,
                            fullName: substitute.fullName
                        }
                    }),
                    type: record.type ? record.type : formData.requestType ? formData.requestType as TVacationType : null,
                    ...(recordFromForm?.startTime && {startTime: recordFromForm.startTime}),
                    ...(recordFromForm?.endTime && {endTime: recordFromForm.endTime}),
                }
            })
        }

        axiosInstance.post(attendanceManagementUrl + `employees/${form.getValues('userUUID')}/vacations/step-two/`, data)
            .then((res) => {
                setApiErrors({})
                stepper.goToNextStep()
                setDataToSubmit(res.data)
                handleEmployeeName()
            })
            .catch((err) => {
                let errors = err.response?.data
                if (errors.hasOwnProperty('records')) {
                    errors.records.forEach((recError: {type: string}, index: number) => {
                        if (recError.type) {
                            form.setError(`records.${index}.type`, {message: "Missing"})
                        }
                    })
                } else {
                    setApiErrors(err.response?.data)
                }
            })
    }

    const fetchEmployees = () => {
        axiosInstance.get(attendanceManagementUrl + 'employees/')
            .then((res) => setEmployees(res.data))
            .catch((err) => console.log(err))
    }

    const fetchSubstitutes = (userUUID?: string) => {
        if (!userUUID) {
            setSubstitutes([])
        } else {
            axiosInstance.get(attendanceManagementUrl+ `vacations/substitutes`)
            .then((res) => {
                setSubstitutes(res.data)
            })
            .catch((err) => console.log(err))
        }
    }

    const calculateWorkingDays = (userUUID: string, dateFrom: Date, dateTo: Date) => {
        let formattedDateFrom = moment(dateFrom).format('YYYY-MM-DD')
        let formattedDateTo = moment(dateTo).format('YYYY-MM-DD')

        axiosInstance.get(
            attendanceManagementUrl +
            `employees/${userUUID}/working-days/${formattedDateFrom}/${formattedDateTo}/`
        )
            .then((res) => {
                let workingDays: string = res.data.workingDays
                setDuration(workingDays)
            })
            .catch((err) => {
                console.log(err)
            })
    }


    const toggleRecord = (recordIndex: number) => {
        setDataToManipulate((prevState) => {
            if (prevState) {
                return {
                    ...prevState,
                    records: prevState.records.map((record, index) => {
                        if (index === recordIndex) {
                            return {
                                ...record,
                                placeholder: !record.placeholder
                            }
                        }
                        return record
                    })
                }
            }

            return prevState
        })
    }

    const changeSubstitute = (recordIndex: number, substituteUUID: string) => {
        setDataToManipulate((prevState) => {
            if (prevState) {
                return {
                    ...prevState,
                    records: prevState.records.map((record, index) => {
                        if (index === recordIndex) {
                            return {
                                ...record,
                                substitute: {
                                    userUUID: substituteUUID,
                                    fullName: substitutes.find(elem => elem.userUUID === substituteUUID)?.fullName ?? nullOrUndefined
                                }
                            }
                        }
                        return record
                    })
                }
            }

            return prevState
        })
    }

    const changeType = (recordIndex: number, type: TVacationType | undefined) => {
        setDataToManipulate((prevState) => {
            if (prevState) {
                return {
                    ...prevState,
                    records: prevState.records.map((record, index) => {
                        if (index === recordIndex) {
                            return {
                                ...record,
                                type
                            }
                        }
                        return record
                    })
                }
            }

            return prevState
        })
    }

    const changePortion = (recordIndex: number, portion: TPortionType | undefined) => {
        setDataToManipulate((prevState) => {
            if (prevState) {
                return {
                    ...prevState,
                    records: prevState.records.map((record, index) => {
                        if (index === recordIndex) {
                            return {
                                ...record,
                                portion
                            }
                        }
                        return record
                    })
                }
            }

            return prevState
        })
    }

    const changeTime = (recordIndex: number, field: string, value: string | undefined) => {
        setDataToManipulate((prevState) => {
            if (prevState) {
                return {
                    ...prevState,
                    records: prevState.records.map((record, index) => {
                        if (index === recordIndex) {
                            return {
                                ...record,
                                [field]: value
                            }
                        }
                        return record
                    })
                }
            }

            return prevState
        })
    }

    const handleEmployeeName = () => {
        let username = employees.find(elem => String(elem.userUUID) === String(form.getValues('userUUID')))?.fullName
        setEmployeeName(username)
    }

    useEffect(() => {
        const subscription = form.watch((value, { name, type }) => {
            if (value.userUUID) {
                handleEmployeeName()
                fetchSubstitutes(value?.userUUID)
                if (value.dateFrom && value.dateTo && (name === 'userUUID' || name === 'dateFrom' || name === 'dateTo')) {
                    calculateWorkingDays(value.userUUID, value.dateFrom, value.dateTo)
                }
            } else {
                setEmployeeName(undefined)
            }
            if (value.start && value.end) {
                let duration = calculateDuration(value.start, value.end)
                setDayDuration(duration)
            }
        })
        return () => subscription.unsubscribe()
    }, [form.watch()]);

    useEffect(() => {
        switch (stepper.currentStep) {
            case 'step_1':
                setModalWidth('s')
                break
            case 'step_2':
                setModalWidth('xl')
                break
            case 'preview':
                setModalWidth('l')
                break
            default:
                setModalWidth('s')
                break
        }
    }, [stepper.currentStep]);

    useEffect(() => {
        fetchEmployees();
    }, []);

    return (
        <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
            <Tabs
                value={stepper.currentStep}
                onValueChange={() => null}
            >
                <TabsList className="mb-2">
                    {stepper.steps.map(step => (
                        <TabsTrigger className="cursor-default" value={step.name} disabled={!step.isOpen}>{step.title}</TabsTrigger>
                    ))}
                </TabsList>

                <TabsContent value={"step_1"}>
                    <div className="flex flex-col gap-4 w-[500px]">

                        <VacationsFirstStep
                            form={form}
                            employees={employees}
                            substitutes={substitutes}
                            duration={duration}
                            dayDuration={dayDuration}
                            employeeName={employeeName}
                        />

                        <ErrorMessageList errors={errors?.detailErrors} />
                        <ErrorMessageList errors={errors?.nonFieldErrors} />

                        <DialogFooter className="justify-between space-x-2 px-0 pb-0">
                            <DialogClose ref={closeModal as any} asChild>
                                <Button variant="outline">
                                    <FormattedMessage id={"button.cancel"} defaultMessage={"Cancel"}/>
                                </Button>
                            </DialogClose>
                            <Button
                                variant="taimDefault"
                                onClick={handleFirstStep}
                                type="button"
                            ><FormattedMessage id={"button.next"} defaultMessage={"Next"}/></Button>
                        </DialogFooter>
                    </div>
                </TabsContent>

                <TabsContent value={"step_2"}>
                    <div className="flex flex-col gap-4">
                        <VacationsSecondStep
                            form={form}
                            data={dataToManipulate}
                            substitutes={substitutes}
                            changeType={changeType}
                            changePortion={changePortion}
                            changeSubstitute={changeSubstitute}
                            changeTime={changeTime}
                            toggleRecord={toggleRecord}
                            employeeName={employeeName}
                        />

                        <ErrorMessageList errors={errors?.detailErrors}/>
                        <ErrorMessageList errors={errors?.nonFieldErrors}/>

                        <DialogFooter className="justify-between space-x-2 px-0 pb-0">
                            <Button variant="outline" onClick={stepper.goToPreviousStep}>
                                <FormattedMessage id={"button.back"} defaultMessage={"Back"}/>
                            </Button>
                            <Button
                                variant="taimDefault"
                                type="button"
                                onClick={handleSecondStep}
                            ><FormattedMessage id={"button.preview"} defaultMessage={"Preview"}/></Button>
                        </DialogFooter>
                    </div>
                </TabsContent>

                <TabsContent value={"preview"}>
                    <div className="flex flex-col gap-4">
                        <VacationsPreview
                            form={form}
                            data={dataToSubmit}
                            employeeName={employeeName}
                        />

                        <ErrorMessageList errors={errors?.detailErrors} />
                        <ErrorMessageList errors={errors?.nonFieldErrors} />

                        <DialogFooter className="justify-between space-x-2 px-0 pb-0">
                            <DialogClose ref={closeModal as any} asChild>
                                <Button variant="outline" className="hidden">
                                    <FormattedMessage id={"button.cancel"} defaultMessage={"Cancel"}/>
                                </Button>
                            </DialogClose>
                            <Button variant="outline" onClick={stepper.goToPreviousStep}>
                                <FormattedMessage id={"button.back"} defaultMessage={"Back"}/>
                            </Button>
                            <Button
                                variant="taimDefault"
                                type="submit"
                            ><FormattedMessage id={"button.submit"} defaultMessage={"Submit"}/></Button>
                        </DialogFooter>
                    </div>
                </TabsContent>
            </Tabs>

            </form>
        </Form>
    )
}



export const editVacationFormSchema = z.object({
    userUUID: z.string({
        required_error: "Select an employee",
    }),
    dateFrom: z.date().optional(),
    dateTo: z.date().optional(),
    substituteUUID: z.string().optional(),
    records: z.array(
        z.object({
            type: z.string().optional(),
            portion: z.string().optional(),
            substitute: z.object({
                userUUID: z.string().optional()
            }).optional(),
            startTime: z.any(),
            endTime: z.any(),
            placeholder: z.any(),
        })
    ).optional(),
    sickNoteSubmitted: z.any(),
})

type EditVacationProps = {
    vacationID: string
    userUUID: string
    reFetch: Dispatch<SetStateAction<boolean>>
}

export const EditVacation: React.FC<EditVacationProps> = (
    {
        vacationID,
        userUUID,
        reFetch
    }
) => {
    const intl = useIntl()
    const axiosInstance = useAxiosInstance()
    const permissionContext = useContext(PermissionContext)
    const form = useForm<z.infer<typeof editVacationFormSchema>>({
        resolver: zodResolver(editVacationFormSchema),
        defaultValues: {
            sickNoteSubmitted: "no"
        }
    })
    const [apiErrors, setApiErrors] = useState<ServerErrorsType>({})
    const errors = useServerErrors(apiErrors, form)
    const closeModal = useRef<HTMLElement>()
    const [substitutes, setSubstitutes] = useState<vacationSubstitute[]>([])
    const [vacation, setVacation] = useState<vacation>()
    const [loading, setLoading] = useState(false)
    const editVacations = useEditVacations({
        setApiErrors,
        reFetch,
        closeModal,
        form,
        substitutes,
        vacation,
        setVacation
    })

    function onSubmit() {
        editVacations.updateVacations()
    }

    const fetchSubstitutes = async () => {
        return axiosInstance.get(attendanceManagementUrl+ `vacations/substitutes`)
            .then((res) => {
                setSubstitutes(res.data)
            })
            .catch((err) => console.log(err))
    }

    const fetchVacation = async () => {
        return axiosInstance.post(attendanceManagementUrl + `employees/${userUUID}/vacations/${vacationID}/step-one/`)
            .then((res) => {
                setVacation({
                   ...res.data,
                    sickNoteSubmitted: res.data.sickNoteSubmitted ? "yes" : "no",
                    records: res.data?.records.map((record: VacationRecord) => {
                        return {
                            ...record,
                            placeholder: !record?.pk
                        }
                    })
                })
                if (res.data.hasOwnProperty('sickNoteSubmitted')) form.setValue('sickNoteSubmitted', res.data.sickNoteSubmitted ? "yes" : "no")
            })
            .catch((err) => console.log(err))
    }

    useEffect(() => {
        setLoading(true)
        fetchVacation()
            .then(() => fetchSubstitutes())
            .finally(() => setLoading(false))
    }, []);

    return (
        <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
                <DetailViewCard rows={[
                    {
                        name: intl.formatMessage({id: "attendance.vacations.employeeName", defaultMessage: "Employee Name"}),
                        value: vacation?.userFullName ?? nullOrUndefined
                    },
                    {
                        name: intl.formatMessage({id: "attendance.vacations.dateFrom", defaultMessage: "Date From"}),
                        value: vacation?.dateFrom ? moment(vacation.dateFrom).format('ddd Do MMM YYYY') : nullOrUndefined
                    },
                    {
                        name: intl.formatMessage({id: "attendance.vacations.dateTo", defaultMessage: "Date To"}),
                        value: vacation?.dateTo ? moment(vacation.dateTo).format('ddd Do MMM YYYY') : nullOrUndefined
                    },
                    ((permissionContext.isAdmin && vacation?.records.find(elem => elem.type === "sickLeave")?.pk) ? {
                        name: intl.formatMessage({id: "attendance.absences.accept.sick_notes_label", defaultMessage: "Have the Sick Leave documents been provided ?"}),
                        value: (
                            <FormField
                                control={form.control}
                                name="sickNoteSubmitted"
                                render={({ field }) => (
                                    <FormItem className="space-y-3">
                                        <FormControl>
                                            <RadioGroup
                                                onValueChange={field.onChange}
                                                defaultValue={field.value}
                                                className="flex flex-row space-x-2"
                                            >
                                                <FormItem className="flex items-center space-x-3 space-y-0">
                                                    <FormControl>
                                                        <RadioGroupItem value="yes" />
                                                    </FormControl>
                                                    <FormLabel className="font-normal">
                                                        <FormattedMessage id={"yes"} defaultMessage={"Yes"}/>
                                                    </FormLabel>
                                                </FormItem>
                                                <FormItem className="flex items-center space-x-3 space-y-0">
                                                    <FormControl>
                                                        <RadioGroupItem value="no" />
                                                    </FormControl>
                                                    <FormLabel className="font-normal">
                                                        <FormattedMessage id={"no"} defaultMessage={"No"}/>
                                                    </FormLabel>
                                                </FormItem>
                                            </RadioGroup>
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />
                        )
                    } : {})
                ]}/>

                <div className="border rounded-md max-h-[37vh] overflow-y-scroll">
                    <Table className="border-none">
                        <TableHeader>
                            <TableRow>
                                <TableHead>
                                    <FormattedMessage
                                        id={"attendance.vacations.day"}
                                        defaultMessage={"Day"}
                                    />
                                </TableHead>
                                <TableHead>
                                    <FormattedMessage
                                        id={"attendance.vacations.substitute"}
                                        defaultMessage={"Substitute"}
                                    />
                                </TableHead>
                                <TableHead>
                                    <FormattedMessage
                                        id={"attendance.vacations.type"}
                                        defaultMessage={"Type"}
                                    />
                                </TableHead>
                                <TableHead>
                                    <FormattedMessage
                                        id={"attendance.vacations.portion"}
                                        defaultMessage={"Portion"}
                                    />
                                </TableHead>
                                <TableHead>
                                    <FormattedMessage
                                        id={"attendance.vacations.start"}
                                        defaultMessage={"Start"}
                                    />
                                </TableHead>
                                <TableHead>
                                    <FormattedMessage
                                        id={"attendance.vacations.end"}
                                        defaultMessage={"end"}
                                    />
                                </TableHead>
                                <TableHead>
                                    <FormattedMessage
                                        id={"attendance.vacations.action"}
                                        defaultMessage={"Action"}
                                    />
                                </TableHead>
                            </TableRow>
                        </TableHeader>
                        <TableBody className="max-h-[20vh] overflow-y-scroll">
                            {loading ? (
                                <>
                                    {Array.from({length: 3}).map((_) => (
                                        <TableRow>
                                            {Array.from({length: 7}).map((_) => (
                                                <TableCell>
                                                    <Skeleton className={`h-4 w-1/${getRandomInt(1,3)}`}/>
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    ))}
                                </>
                            ) : vacation?.records.length ? (
                                vacation.records?.map((record,index) => {
                                    return <TableRow className={cn(
                                        record?.placeholder && "strikeout"
                                    )}>
                                        <TableCell className="bg-white min-w-[10rem] vacation-row-child">
                                            <span>{moment(record.date).format("ddd Do MMM YYYY")}</span>
                                        </TableCell>
                                        <TableCell className="bg-white vacation-row-child">
                                            <Select
                                                defaultValue={record?.substitute?.userUUID}
                                                onValueChange={(value) => {
                                                    editVacations.changeSubstitute(index, value)
                                                }}
                                            >
                                                <SelectTrigger>
                                                    <SelectValue placeholder={
                                                            intl.formatMessage({
                                                                id: "attendance.vacations.substitutePlaceholder",
                                                                defaultMessage: "Select a substitute"
                                                            })
                                                        }
                                                    />
                                                </SelectTrigger>
                                                <SelectContent className="h-64">
                                                    {substitutes.map((substitute) => (
                                                        <SelectItem value={substitute.userUUID}>{substitute.fullName}</SelectItem>
                                                    ))}
                                                </SelectContent>
                                            </Select>
                                        </TableCell>
                                        <TableCell className="bg-white vacation-row-child">
                                            <FormField control={form.control} name={`records.${index}.type`} render={({ field, fieldState, formState, }) => (
                                                <FormItem>
                                                    <Select defaultValue={record?.type ?? undefined} onValueChange={(value: TVacationType) => {
                                                        editVacations.changeType(index, value)
                                                    }}>
                                                        <SelectTrigger className={cn(fieldState.error?.message ? "ring-1 ring-offset-2 ring-red-500" : "")}>
                                                            <SelectValue placeholder={
                                                                    intl.formatMessage({
                                                                        id: "attendance.vacations.typePlaceholder",
                                                                        defaultMessage: "Select a type"
                                                                    })
                                                                }
                                                            />
                                                        </SelectTrigger>
                                                        <SelectContent>
                                                            {vacationTypes.map(({key, value}) => (
                                                                <SelectItem value={value}>{intl.formatMessage({id: `attendance.vacations.${value}`, defaultMessage: key})}</SelectItem>
                                                            ))}
                                                        </SelectContent>
                                                    </Select>
                                                </FormItem>
                                            )}/>
                                        </TableCell>
                                        <TableCell className="bg-white vacation-row-child">
                                            <Select defaultValue={record?.portion ?? undefined} onValueChange={(value: TPortionType) => {
                                                editVacations.changePortion(index, value)
                                            }}>
                                                <SelectTrigger>
                                                    <SelectValue placeholder={
                                                            intl.formatMessage({
                                                                id: "attendance.vacations.portionPlaceholder",
                                                                defaultMessage: "Select a portion"
                                                            })
                                                        }
                                                    />
                                                </SelectTrigger>
                                                <SelectContent>
                                                    <SelectItem value={"wholeDay"}>
                                                        <FormattedMessage id={"attendance.vacations.wholeDay"} defaultMessage={"Whole Day"}/>
                                                    </SelectItem>
                                                    <SelectItem value={"firstHalf"}>
                                                        <FormattedMessage id={"attendance.vacations.firstHalf"} defaultMessage={"First Half"}/>
                                                    </SelectItem>
                                                    <SelectItem value={"secondHalf"}>
                                                        <FormattedMessage id={"attendance.vacations.secondHalf"} defaultMessage={"Second Half"}/>
                                                    </SelectItem>
                                                </SelectContent>
                                            </Select>
                                        </TableCell>
                                        <TableCell className="bg-white vacation-row-child">
                                            <FormField
                                                control={form.control}
                                                name={`records.${index}.startTime`}
                                                render={({ field }) => (
                                                    <FormItem>
                                                        <FormControl>
                                                            <TimePicker
                                                                label={field.name}
                                                                value={(() => {
                                                                    let value = field.value ? moment(field.value, 'HH:mm:ss') : record.startTime ? moment(record.startTime, 'HH:mm:ss') : undefined
                                                                    return value ? {
                                                                        hour: value.get('hour'),
                                                                        minute: value.get('minute')
                                                                    } as TimeValue : undefined
                                                                })()}
                                                                onChange={(value) => {
                                                                    editVacations.changeTime(index, 'startTime', value ? moment(value).format('HH:mm') : undefined)
                                                                    field.onChange(value)
                                                                }}
                                                                hideTimeZone
                                                            />
                                                        </FormControl>
                                                        <FormMessage/>
                                                    </FormItem>
                                                )}
                                            />
                                        </TableCell>
                                        <TableCell className="bg-white vacation-row-child">
                                            <FormField
                                                control={form.control}
                                                name={`records.${index}.endTime`}
                                                render={({ field }) => (
                                                    <FormItem>
                                                        <FormControl>
                                                            <TimePicker
                                                                label={field.name}
                                                                value={(() => {
                                                                    let value = field.value ? moment(field.value, 'HH:mm:ss') : record.endTime ? moment(record.endTime, 'HH:mm:ss') : undefined
                                                                    return value ? {
                                                                        hour: value.get('hour'),
                                                                        minute: value.get('minute')
                                                                    } as TimeValue : undefined
                                                                })()}
                                                                onChange={(value) => {
                                                                    editVacations.changeTime(index, 'endTime', value ? moment(value).format('HH:mm') : undefined)
                                                                    field.onChange(value)
                                                                }}
                                                                hideTimeZone
                                                            />
                                                        </FormControl>
                                                        <FormMessage/>
                                                    </FormItem>
                                                )}
                                            />
                                        </TableCell>
                                        <TableCell className="bg-white vacation-row-child">
                                            <Trash
                                                color={cn(record?.placeholder ? "gray" : "red")}
                                                size={20}
                                                className="cursor-pointer"
                                                onClick={() => {
                                                    editVacations.toggleRecord(index)
                                                }}
                                            />
                                        </TableCell>
                                    </TableRow>
                                })
                            ) : (
                                <TableRow>
                                    <TableCell colSpan={5}>
                                        <FormattedMessage id={"no_results"} defaultMessage={"No results found."}/>
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>

                </div>

                <ErrorMessageList errors={errors?.detailErrors} />
                <ErrorMessageList errors={errors?.nonFieldErrors} />

                <DialogFooter className="justify-between items-center space-x-2 px-0 pb-0">
                    <div className="w-full text-right text-xs text-gray-500/50">
                        <span>Working Days ( {vacation?.records.filter(elem => !elem.placeholder).length} / {vacation?.records.length} )</span>
                    </div>
                    <DialogClose ref={closeModal as any} asChild>
                        <Button variant="outline">
                            <FormattedMessage id={"button.cancel"} defaultMessage={"Cancel"}/>
                        </Button>
                    </DialogClose>
                    <Button
                        variant="taimDefault"
                        type="submit"
                        onClick={editVacations.updateVacations}
                    ><FormattedMessage id={"button.save"} defaultMessage={"Save"}/></Button>
                </DialogFooter>

            </form>
        </Form>
    )
}



const editVacationRecordSchema = z.object({
    substitute: z.object({
        userUUID: z.any().nullable()
    }),
    portion: z.any(),
    type: z.any()
})

type EditVacationRecordProps = {
    vacationID: string
    recordID: string
    record: VacationRecord
    reFetchVacationRecords: Dispatch<SetStateAction<boolean>>
    shouldReloadVacations: Dispatch<SetStateAction<boolean>>
}

export const EditVacationRecord: React.FC<EditVacationRecordProps> = (
    {
        vacationID,
        recordID,
        record,
        reFetchVacationRecords,
        shouldReloadVacations
    }
) => {
    const intl = useIntl()
    const axiosInstance = useAxiosInstance()
    const form = useForm<z.infer<typeof editVacationRecordSchema>>({
        resolver: zodResolver(editVacationRecordSchema),
        defaultValues: {
            substitute: {
                userUUID: record.substitute?.userUUID
            },
            ...(record.portion && {portion: record.portion}),
            ...(record.type && {type: record.type}),
        }
    })
    const [users, setUsers] = useState<Employee[]>([])
    const [apiErrors, setApiErrors] = useState<ServerErrorsType>({})
    const errors = useServerErrors(apiErrors, form)
    const closeModal = useRef<HTMLElement>()

    const onSubmit = (values: z.infer<typeof editVacationRecordSchema>) => {
        const data = {
            ...record,
            substitute: values.substitute.userUUID ? {userUUID: values.substitute.userUUID} : null,
            portion: values.portion ?? null,
            type: values.type ?? null,
        }

        axiosInstance.patch(vacationsUrl + `${vacationID}/records/${recordID}/`, data)
            .then((res) => {
                toast({
                    title: intl.formatMessage({id: "toast.success", defaultMessage: "Great!"}),
                    description: intl.formatMessage({id: "toast.success.changesSaved", defaultMessage: "Your changes have been saved successfully."})
                })
                reFetchVacationRecords(true)
                shouldReloadVacations(true)
            })
            .catch((err) => {
                setApiErrors(err.response.data)
                toast({
                    variant: "destructive",
                    title: intl.formatMessage({id: "toast.error", defaultMessage: "Error!"}),
                    description: err.response.data?.detail ?? intl.formatMessage({id: "toast.error.operationFailed", defaultMessage: "The operation failed. Please check your input and try again."})
                })
            })
    }

    const fetchUsers = () => {
        axiosInstance.get(attendanceManagementUrl + 'employees/')
            .then((res) => setUsers(res.data))
            .catch((err) => console.log(err))
    }

    useEffect(() => {
        fetchUsers()
    }, [vacationID]);

    return (
        <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
                <FormField
                    control={form.control}
                    name="substitute.userUUID"
                    render={({ field }) => (
                        <FormItem>
                            <FormLabel>
                                <FormattedMessage
                                    id={"attendance.vacations.substitute"}
                                    defaultMessage={"Substitute"}
                                />
                            </FormLabel>
                            <SearchInput
                                title={intl.formatMessage({
                                    id: "attendance.vacations.substitutePlaceholder",
                                    defaultMessage: "Select a substitute"
                                })}
                                value={field.value}
                                values={users?.map((user) => {
                                    return {
                                        key: user.fullName,
                                        value: user.userUUID
                                    }
                                })}
                                onChange={field.onChange}
                            />
                            <FormMessage />
                        </FormItem>
                    )}
                />
                <FormField
                    control={form.control}
                    name="type"
                    render={({ field }) => (
                        <FormItem>
                            <FormLabel>
                                <FormattedMessage
                                    id={"attendance.vacations.type"}
                                    defaultMessage={"Type"}
                                />
                            </FormLabel>
                            <SearchInput
                                title={intl.formatMessage({
                                    id: "attendance.vacations.typePlaceHolder",
                                    defaultMessage: "Select a type"
                                })}
                                value={field.value}
                                values={vacationTypes.map(({key, value}) => {
                                    return {key: intl.formatMessage({id: `attendance.vacations.${value}`, defaultMessage: key}),value}
                                })}
                                onChange={field.onChange}
                            />
                            <FormMessage />
                        </FormItem>
                    )}
                />
                <FormField
                    control={form.control}
                    name="portion"
                    render={({ field }) => (
                        <FormItem>
                            <FormLabel>
                                <FormattedMessage
                                    id={"attendance.vacations.portion"}
                                    defaultMessage={"Portion"}
                                />
                            </FormLabel>
                            <SearchInput
                                title={intl.formatMessage({
                                    id: "attendance.vacations.portion",
                                    defaultMessage: "Select a portion"
                                })}
                                value={field.value}
                                values={portionTypes.map(({key, value}) => {
                                    return {key: intl.formatMessage({id: `attendance.vacations.${value}`, defaultMessage: key}),value}
                                })}
                                onChange={field.onChange}
                            />
                            <FormMessage />
                        </FormItem>
                    )}
                />

                <ErrorMessageList errors={errors?.detailErrors} />
                <ErrorMessageList errors={errors?.nonFieldErrors} />

                <DialogFooter className="justify-between space-x-2 px-0 pb-0">
                    <DialogClose ref={closeModal as any} asChild>
                        <Button variant="outline">
                            <FormattedMessage id={"button.cancel"} defaultMessage={"Cancel"}/>
                        </Button>
                    </DialogClose>
                    <Button
                        variant="taimDefault"
                        type="submit"
                    ><FormattedMessage id={"button.submit"} defaultMessage={"Submit"}/></Button>
                </DialogFooter>
            </form>
        </Form>
    )
}